import { Button, Empty, Layout } from 'antd';
import * as React from 'react';
import { useMutation } from 'react-apollo';
import { useHistory, useParams } from 'react-router-dom';

import { CheckCircleFilled, HomeOutlined } from '@ant-design/icons';

import { ANSWER_ROOM_INVITE, RoomParticipantStatuses } from '../../../api/acceptInvite';
import { useAuth } from '../../../auth0';
import Loader from '../../../shared/Components/Loader';

const HomeButton = ({ history }) => (
  <Button
    type='primary'
    className="forbidden-videoconf-action"
    onClick={() => history.push('/')}
  >
    <HomeOutlined /> Go to Homepage
  </Button>
);

const AcceptInvite = () => {
  const { isAuthenticated, isAuthenticating, user, login } = useAuth();
  let { id } = useParams<{id: string}>();
  const history = useHistory();
  const [answerRoomInvite, { data }] = useMutation(ANSWER_ROOM_INVITE);

  React.useEffect(() => {
    document.title = "Accept invite";
  })

  React.useEffect(() => {
    if (!isAuthenticated && !isAuthenticating && login) {
      login();
    }

    if (isAuthenticated && user && id && !data) {
      answerRoomInvite({ variables: { input: { id: id, status: RoomParticipantStatuses.ACCEPTED } } });
    }
  }, [answerRoomInvite, id, data, isAuthenticated, isAuthenticating, user, login]);


  if (isAuthenticating || !user) {
    return <Loader isLoading={true}>Authenticating user...</Loader>
  }

  return (
    <Layout className="forbidden-videoconf-container">
      <Empty
        image={<CheckCircleFilled style={{color: 'green', fontSize: '56px'}} />}
        className="forbidden-videoconf"
        description="Thanks for accepting the invite."
      />
      <a href={`/rooms/${id}`} onClick={() => history.push(`/rooms/${id}`)}>{`https://e-fop.org/rooms/${id}`}</a>
      <HomeButton history={history} />
    </Layout>
  );
};

export default AcceptInvite;
