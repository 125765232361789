import { StringMap, TOptions } from 'i18next';
import React, { ReactChild } from 'react';
import { useTranslation } from 'react-i18next';

export type MultilineProps = {
  tkey?: string;
  text?: string;
  options?: string | TOptions<StringMap> | undefined;
};

const Multiline: React.FC<MultilineProps> = (props) => {
  const { tkey, text, options } = props;
  const { t } = useTranslation();

  if (!tkey && !text) {
    return null;
  }

  const originalText = text as string || t(tkey as string, options);
  const nodes: ReactChild[] = [];

  originalText.replace(/(<br>|<br\/>|<br \/>|\\n)/g, '\n').split('\n').map((x, index) => {
    nodes.push(<span key={`text-${index}`}>{x}</span>);
    nodes.push(<br key={`space-${index}`} />);
  });

  return (
    <p>{nodes}</p>
  );
};

export default Multiline;