import React from 'react';
import Loadable from 'react-loadable';

import Loader from '../../shared/Components/Loader';

const LoginContainer = Loadable({
  loader: () => import('./Login'),
  loading: () => <Loader isLoading={true} />,
});

export default LoginContainer;
