import { Route } from '../routes.types';
import AcceptInvite from './AcceptInvite';
import DeclineInvite from './DeclineInvite';

export const acceptInviteRoutePaths = {
  acceptInvite: '/accept-invite/:id',
  declineInvite: '/decline-invite/:id',
};

export const routes: Route[] = [
  {
    path: acceptInviteRoutePaths.acceptInvite,
    component: AcceptInvite,
  },
  {
    path: acceptInviteRoutePaths.declineInvite,
    component: DeclineInvite,
  }
];
