import './PostCard.css';

import { Skeleton, Space } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

import { PaperClipOutlined } from '@ant-design/icons';

import { Post, POST_PROPS } from '../../../../api/post';
import DateTime from '../../../../shared/DateTime';
import { truncateString } from '../../../../utils/strings';

export interface PostCardProps extends RouteComponentProps {
  id?: string | number;
  className?: string;
  post?: Post;
  imagePosition?: 'before' | 'after';
  direction?: 'horizontal' | 'vertical';
  small?: boolean;
  bordered?: boolean;
  isLoading?: boolean;
}

const PostCard: React.FC<PostCardProps> = (props) => {
  const { history, isLoading, post, id } = props;

  const { t } = useTranslation();

  const handleClick = React.useCallback(() => {
    if (!post) {
      return;
    }

    history.push(`/posts/${post.id}`);
  }, [history, post]);

  const bgImage = post && post.featuredImage?.file.url ? `url(${post.featuredImage?.file.url})` : null;

  const image = bgImage && (
    <div
      id={`post-card-image-${id}`}
      className='post-card-image'
      style={(!isLoading && !!post) ? { backgroundImage: bgImage } : { background: "#EEE" }}
    />
  );

  const imagePosition = props.imagePosition || 'before';
  const direction = props.direction ? ` ${props.direction}` : ' horizontal';
  const small = props.small ? ' small' : '';
  const active = (!props.isLoading && !!post) ? ' active' : '';
  const bordered = props.bordered ? ' bordered' : '';
  const className = props.className ? ` ${props.className}` : '';

  const renderPost = (post: Post) => (
    <>
      <div className='post-card-title' id={`post-card-title-${id}`}>
        <h2 aria-multiline='true'>
          {post.title && truncateString(post.title, POST_PROPS.TITLE_MAX_COUNT)}</h2>
      </div>
      <div className='post-card-description' id={`post-card-description-${id}`}>
        <p>{truncateString(post.description, POST_PROPS.DESCRIPTION_MAX_COUNT)}</p>
      </div>
      <div className='post-card-meta'>
        <div className='post-meta-last-update' id={`post-card-author-${id}`}>
          {post.author && post.author.name}{' - '}<DateTime datetime={post.updatedAt} />
        </div>
        <div className='post-meta-files'>
          <Space>
            {(post.files && post.files.length) ? <div id={`post-card-files-${id}`}><PaperClipOutlined />{t('posts.filesCount', { count: post.files.length })}</div> : t('posts.noFiles')}
            {(post.comments && post.comments.length) ? t('posts.commentsCount', { count: post.comments.length }) : t('posts.noComments')}
          </Space>
        </div>
      </div>
    </>
  );

  return (
    <div
      id={`post-card-${id}`}
      className={`post-card${active}${direction}${small}${bordered}${className}`}
      onClick={handleClick}
    >
      { imagePosition === 'before' && image}
      <div className='post-card-details'>
        <Skeleton loading={isLoading || !post} active>
          {post && renderPost(post)}
        </Skeleton>
      </div>
      { imagePosition === 'after' && image}
    </div>
  )
};

export default withRouter(PostCard);
