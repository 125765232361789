import { Checkbox, DatePicker, Form, Input, Radio, Select } from 'antd';
import { TFunction } from 'i18next';
import debounce from 'lodash/debounce';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Questionnaire, QuestionnaireAnswer } from '../../api/questionnaire';
import { QuestionnaireModel, QuestionnaireModelItem, QuestionType } from '../../api/questionnaireModel';
import './Questionnaire.css';


const { Option } = Select;
const { TextArea } = Input;

interface UserQuestionnaireContainerProps {
  editable: boolean;
  questionnaireModel: QuestionnaireModel;
  questionnaire?: Questionnaire;
  onChange?: (answer: string, questionId: string, ansers: QuestionnaireAnswer[]) => void;
}

type RendererArgs = {
  t: TFunction;
  item: QuestionnaireModelItem;
  value?: string;
  other?: string;
  update: (value: string, id: string) => void;
};

type Renderer = (args: RendererArgs) => JSX.Element;

const renderFormLabel = (item: QuestionnaireModelItem) => item.required ? `* ${item.question}` : item.question;

const comps: { [x in QuestionType]: Renderer } = {
  [QuestionType.TEXT]: ({ item, value, update }) => (
    <Form.Item label={renderFormLabel(item)} key={`q-${item.id}`}>
      <Input
        name={item.question}
        defaultValue={value}
        placeholder={item.question}
        onChange={(e) => update(e.target.value, item.id)}
      />
    </Form.Item>
  ),
  [QuestionType.TEXT_AREA]: ({ item, value, update }) => (
    <Form.Item label={renderFormLabel(item)} key={`q-${item.id}`}>
      <TextArea
        rows={2}
        name='description'
        placeholder='Description'
        defaultValue={value}
        onChange={(e) => update(e.target.value, item.id)}
      />
    </Form.Item>
  ),
  [QuestionType.TRUE_FALSE]: ({ item, value, update, t }) => (
    <Form.Item label={renderFormLabel(item)} key={`q-${item.id}`}>
      <Radio.Group
        onChange={(e) => update(e ? 'true' : 'false', item.id)}
        defaultValue={value}
      >
        <Radio key='true' value='true'>{t('questionnaire.trueLabel')}</Radio>
        <Radio key='false' value='false'>{t('questionnaire.falseLabel')}</Radio>
      </Radio.Group>
    </Form.Item>
  ),
  [QuestionType.YES_NO]: ({ item, value, update, t }) => (
    <Form.Item label={renderFormLabel(item)} key={`q-${item.id}`}>
      <Radio.Group
        onChange={(e) => update(e ? 'yes' : 'no', item.id)}
        defaultValue={value}
      >
        <Radio key='yes' value='yes'>{t('questionnaire.yesLabel')}</Radio>
        <Radio key='no' value='no'>{t('questionnaire.noLabel')}</Radio>
      </Radio.Group>
    </Form.Item>
  ),
  [QuestionType.CHECKBOX]: ({ item, value, other, update }) => (
    <Form.Item label={renderFormLabel(item)} key={`q-${item.id}`}>
      <Checkbox.Group
        style={{ width: '100%' }}
        defaultValue={value?.split(',')}
        onChange={(e) => update(`${e}`, item.id)}
      >
        {item.options && item.options.map((option, index) => (
          <div key={`q-${item.id}-a${index}`}>
            <Checkbox
              key={`${item.type}-${index}`}
              value={option.label}
            >
              {option.label}
            </Checkbox>
          </div>
        ))}
      </Checkbox.Group>
      {item.other &&
        (<div className="other-answer-container">
          <label>{item.other}:</label>
          <Input
            defaultValue={other}
            onChange={(e) => update(e.target.value, item.id)}
          />
        </div>)}
    </Form.Item>
  ),
  [QuestionType.RADIO]: ({ item, value, other, update }) => (
    <Form.Item label={renderFormLabel(item)} key={`q-${item.id}`}>
      <Radio.Group
        name={item.question}
        defaultValue={value}
        onChange={(e) => update(e.target.value, item.id)}
      >
        {item.options && item.options.map((option, index) => (
          <div key={`q-${item.id}-a${index}`}>
            <Radio
              key={`${item.type}-${index}`}
              value={option.label}
            >
              {option.label}
            </Radio>
          </div>
        ))}
      </Radio.Group>
      {item.other &&
        (<div className="other-answer-container">
          <label>{item.other}:</label>
          <Input
            defaultValue={other}
            onChange={(e) => update(e.target.value, item.id)}
          />
        </div>)}
    </Form.Item>
  ),
  [QuestionType.SELECT]: ({ item, value, other, update }) => (
    <Form.Item label={renderFormLabel(item)} key={`q-${item.id}`}>
      <Select onChange={(e) => update(e, item.id)} defaultValue={value}>
        {item.options && item.options.map((option, index) => (
          <Option
            key={`${item.type}-${index}`}
            value={option.label}
          >
            {option.label}
          </Option>
        ))}
      </Select>
      {item.other &&
        (<div className="other-answer-container">
          <label>{item.other}:</label>
          <Input
            defaultValue={other}
            onChange={(e) => update(e.target.value, item.id)}
          />
        </div>)}
    </Form.Item>
  ),
  [QuestionType.DATE]: ({ item, value, update }) => (
    <Form.Item label={renderFormLabel(item)} key={`q-${item.id}`}>
      <DatePicker
        defaultValue={value ? moment(value) : undefined}
        onChange={(e, dateString) => update(dateString, item.id)}
      />
    </Form.Item>
  )
}

const UserQuestionnaire: React.FC<UserQuestionnaireContainerProps> = (props) => {
  const { editable, questionnaireModel, questionnaire, onChange } = props;

  const { t } = useTranslation();

  const [answers, setAnswers] = React.useState<QuestionnaireAnswer[]>(questionnaire?.answers || []);

  React.useEffect(() => {
    if (questionnaire && questionnaire.answers && questionnaire.answers.length) {
      const answer = questionnaire.answers[0];

      if (typeof onChange === 'function') {
        onChange(answer.answer, answer.questionId, questionnaire.answers);
      }
    }
  }, [questionnaire, onChange]);

  const updateQuestionnaire = React.useCallback((answer: string, questionId: string) => {
    if (!editable) {
      return;
    }

    const newAnswers: QuestionnaireAnswer[] = [];
    let found = false;
    answers.map(x => {
      if (x.questionId === questionId) {
        found = true;
        newAnswers.push({
          questionId,
          answer
        });
        return x;
      } else {
        newAnswers.push({ ...x });
        return x;
      }
    });

    if (!found) {
      newAnswers.push({
        questionId,
        answer
      });
    }

    setAnswers(newAnswers);

    if (typeof onChange === 'function') {
      onChange(answer, questionId, newAnswers);
    }
  }, [answers, onChange, editable]);

  const renderItem = (item: QuestionnaireModelItem) => {
    const renderer = comps[item.type];
    if (!renderer) {
      return;
    }

    const qAnswer = questionnaire ? questionnaire.answers.find(x => x.questionId === item.id) : null;

    let other: string | undefined = undefined;
    let value = qAnswer ? qAnswer.answer : undefined;
    if (qAnswer && item.other && item.options) {
      const elements = qAnswer.answer.split(',');

      other = elements.find(givenAnswer => !item.options.find(option => option.label === givenAnswer));

      if (other) {
        value = elements.filter(x => x !== other).join(',');
      }
    }

    return renderer({
      t,
      item,
      value,
      other,
      update: debounce(updateQuestionnaire, 300, { trailing: true, maxWait: 1000 }),
    });
  }

  if (!questionnaireModel) {
    return null;
  }

  return (
    <Form
      name='Questionnaire_form'
      className='userquestionnaire-form'
      layout='vertical'
    >
      <h2>{questionnaireModel.title}</h2>
      <p className="userquestionnaire-description">{questionnaireModel.description}</p>
      {questionnaireModel && questionnaireModel.items && questionnaireModel.items.map(renderItem)}
    </Form>
  );
}

export default UserQuestionnaire;
