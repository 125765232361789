import { Paginated } from '../types';

export enum QuestionType {
  TEXT = 'text', // text field
  TEXT_AREA = 'textarea', // Textarea
  YES_NO = 'yesno', // radio yes/no
  TRUE_FALSE = 'truefalse', // radio true/false
  RADIO = 'radio', // Radio buttons
  CHECKBOX = 'checkbox', // Checkboxes (kinda multiselection)
  SELECT = 'select', // Dropdown select
  DATE = 'date', // Date
}

export type QuestionOptions = {
  label: string;
};

export type QuestionnaireModelItem = {
  id: string;
  required?: boolean;
  other?: string;
  question: string;
  type: QuestionType;
  options: QuestionOptions[];
};

export type QuestionnaireModelItemInput = Omit<QuestionnaireModelItem, 'id'> & {
  id?: string;
};

export type QuestionnaireModel = {
  id: string;
  title: string;
  description?: string;
  items: QuestionnaireModelItem[];
  createdAt: string;
  updatedAt: string;
};

export type PaginatedQuestionnaireModels = {
  questionnaireModels: Paginated<QuestionnaireModel>;
};

export type SingleQuestionnaireModel = {
  questionnaireModel: QuestionnaireModel;
};
