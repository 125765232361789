import { Button, Result } from 'antd';
import { ApolloError } from 'apollo-boost';
import * as React from 'react';
import { useApolloErrors } from '../useApolloErrors';

export type ResultErrorProps = {
  onOk: () => void;
  okText: string;
  errorkey: string;
  opError?: ApolloError;
}

const ResultError: React.FC<ResultErrorProps> = ({
  okText,
  onOk,
  opError,
  errorkey
}) => {
  const { format } = useApolloErrors();

  const renderableErrors = format(opError, errorkey);
  const renderableError = renderableErrors ?
    renderableErrors[0] :
    { title: 'Error', message: '' };

  return (
    <Result
      key='error'
      status="error"
      title={renderableError.title}
      subTitle={renderableError.message}
      extra={[
        <Button
          type="primary"
          onClick={() => onOk()}
        >{okText}</Button>
      ]}
    />
  );
};

export default ResultError;
