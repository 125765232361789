import { MenuConfig, Route } from '../routes.types';
import Login from './Login';

export const loginRoutePaths = {
  root: '/login',
};

export const routes: Route[] = [
  {
    path: loginRoutePaths.root,
    component: Login,
    exact: true,
  }
];

export const menus: MenuConfig = [];
