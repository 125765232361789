import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as postActions from './post.action';

export type PostState = {
    posts: any | null
};

const INITIAL_STATE: PostState = {
    posts: null,
};

export const postReducer = reducerWithInitialState(INITIAL_STATE)
    .case(postActions.newPost.done, (state, { result }): PostState => ({ ...state, posts: result, }));
