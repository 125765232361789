import './UserProfileModal.css';

import { Modal } from 'antd';
import * as React from 'react';

import UserForm from '../../routes/Admin/User/UserForm';
import { useUser } from '../useUser';

interface UserProfileModalProps {
  isVisible: boolean;
  closeModal: () => void;
}

const UserProfileModal: React.FC<UserProfileModalProps> = ({isVisible, closeModal }) => {
  const { user } = useUser();

  const closeModalCB = React.useCallback(() => {
    closeModal();
  }, []);

  if(!user) {
    return  null;
  }

  return (
    <Modal
      title={`${user.firstName} ${user.lastName}`}
      visible={isVisible}
      centered={true}
      closable={false}
      width={780}
      footer={null}
    >
      <div className="user-profile-modal-content">
      <UserForm
        disableSelfEditWarning
        initialValues={user}
        done={() => console.log('done')}
        close={closeModalCB}/>
      </div>
    </Modal>
  );
}

export default UserProfileModal;
