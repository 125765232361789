import './AppLayout.css';

import { Layout } from 'antd';
import * as React from 'react';
import HeaderAlert from '../HeaderAlert';
import TopNavigationMenu from '../Menu/TopNavigationMenu';

const AppLayout: React.FC = ({ children }) => {
  return (
    <Layout className='app-container'>
      <TopNavigationMenu />
      <HeaderAlert />
      {children}
    </Layout>
  );
};

export default AppLayout;
