import React from 'react';
import Loadable from 'react-loadable';

import Loader from '../../shared/Components/Loader';

const VideoConferenceContainer = Loadable({
  loader: () => import('./VideoConference'),
  loading: () => <Loader isLoading={true} />,
});

export default VideoConferenceContainer;
