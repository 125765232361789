import { TeamOutlined } from '@ant-design/icons';
import { Actions, Resources } from '../../../rbac';

import { MenuConfig, Route } from '../../routes.types';
import Groups from './GroupList';

export const groupRoutePaths = {
  root: '/groups',
};

export const routes: Route[] = [
  {
    path: groupRoutePaths.root,
    component: Groups,
    permissions: { [Resources.USER_GROUP]: [Actions.READ] }
  }
];

export const menus: MenuConfig = [
  {
    route: routes[0],
    label: 'group.menuTitle',
    icon: TeamOutlined,
    order: 2,
  }
];
