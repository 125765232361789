import * as React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../../shared/Components/Loader';

const AdminPostListContainer = Loadable({
  loader: () => import('./AdminPostList'),
  loading: () => <Loader isLoading={true} />,
});

export default AdminPostListContainer;
