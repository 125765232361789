import "./TagPosts.css";

import { Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { lazy, useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useParams } from 'react-router-dom';

import { GET_POSTS, PaginatedPosts } from '../../../api/post';
import PostCard from '../PostList/PostCard';
import BreadCrumb from '../../../shared/Menu/BreadCrumb';
import { useUser } from "../../../shared/useUser";
import NoPosts from "./NoPosts";

const TagPosts: React.FC = () => {
  const { name } = useParams<{ name?: string }>();
  const { user } = useUser();
  const { t } = useTranslation();

  const [isRedoing, setIsRedoing] = useState(false);
  const [getTaggedPosts, lazyQuery] = useLazyQuery<PaginatedPosts>(GET_POSTS);

  React.useEffect(() => {
    if (!name) {
      return;
    }

    if (!lazyQuery.data) {
      getTaggedPosts({
        variables: {
          filters: {
            tags: [name],
            published: true,
          }
        }
      });
    } else {
      setIsRedoing(true);

      lazyQuery.refetch().then(() => {
        setIsRedoing(false);
      })
    }
  }, [name, user]);

  const handleLoadMore = React.useCallback(() => {
    if (!lazyQuery.called || !lazyQuery.data) {
      return;
    }

    const pageInfo = lazyQuery.data['posts'].pageInfo;

    if (pageInfo?.hasNextPage) {
      lazyQuery.fetchMore({
        variables: {
          cursor: pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return;
          }

          const newEdges = fetchMoreResult.posts.edges;
          const pageInfo = fetchMoreResult.posts.pageInfo;

          return (newEdges && newEdges.length)
            ? {
              posts: {
                __typename: previousResult.posts.__typename,
                edges: [...previousResult.posts.edges, ...newEdges],
                pageInfo
              }
            }
            : previousResult;
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyQuery]);

  const renderSkeleton = () => {
    return [0,1,2,3,4,5,6,7,8,9,10].map(i => (
      <PostCard key={i} imagePosition='after' className="post-list-item" />
    ));
  };

  return (
    <div className="tag-posts-page">
      <BreadCrumb exclude={['tag']} />
      <Title level={2} className="tag-posts-title">{t('posts.tags.postsWithTag', { tag: name })}</Title>
      { (lazyQuery.data && !isRedoing && lazyQuery.data.posts.edges.length == 0) &&
        <NoPosts />
      }
      { lazyQuery.data && 
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={handleLoadMore}
        hasMore={lazyQuery.data ? lazyQuery.data.posts.pageInfo.hasNextPage : false}
        useWindow={false}
      >
        { lazyQuery.data && lazyQuery.data.posts.edges.map(post => (
          <PostCard key={`post-${post.node.id}`} post={post.node} bordered imagePosition='after' className="post-list-item" />
        )) }
        { lazyQuery.loading && renderSkeleton() }
      </InfiniteScroll>
      }
    </div>
  );
};

export default TagPosts;