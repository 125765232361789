import './SmartSelect.css';

import { Button, Popover } from "antd";
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DownOutlined, UpOutlined } from '@ant-design/icons';

import SelectionPanel, { SelectionPanelWithQueryProps } from '../SelectionPanel';

export type SmartSelectProps = Omit<SelectionPanelWithQueryProps, 'onConfirm' | 'disabled'> & {
  className?: string;
  buttonLabel?: string;
  renderSelectedItem?: (item: any) =>  React.ReactNode;
  block?: boolean;
  disabled?: boolean | any[];
};

const SmartSelect: React.FC<SmartSelectProps> = (props) => {
  const {
    className,
    buttonLabel,
    title,
    query,
    queryVariables,
    queryRoot,
    initialItemsCount,
    renderSelectedItem,
    renderItem,
    multi,
    block,
    onSelect: externalSetSelected,
    selected: externalSelected,
    disabled,
  } = props;

  const { t } = useTranslation();

  const [panelSelected, panelSetSelected] = useState<any>();
  const [internalSelected, internalSetSelected] = useState<any>();
  const [opened, setOpened] = useState<boolean>(false);
  
  const setSelected = externalSetSelected || internalSetSelected;
  const selected = externalSelected || internalSelected;

  React.useEffect(() => {
    if (selected) {
      panelSetSelected(selected);
    }
  }, [selected]);

  const selectedCount = selected ? Object.keys(selected).length : 0;

  const handleSelection = useCallback((items: any) => {
    setOpened(false);
    setSelected(items);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = useCallback(() => {
    setOpened(false);

    if (selectedCount === 0) {
      panelSetSelected({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCount]);

  const handleChangeSelection = useCallback((item: any) => {
    setOpened(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearSelection = useCallback((item: any) => {
    setSelected({});
    panelSetSelected({});
    setOpened(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderWrappedItem = () => {
    const keys = Object.keys(selected);

    return (
      <div className={`smart-select-item${block ? ' smart-select-item-block' : ''}`}>
        { multi 
          ? t(`common.${selectedCount === 1 ? 'oneItemSelected' : 'itemsSelectedCount'}`, { count: selectedCount })
          : (renderSelectedItem ? renderSelectedItem(selected[keys[0]]) : renderItem(selected[keys[0]], 0)) 
        }
        <div className="smart-select-item-action">
          <Button type="link" size="small" style={{ fontSize: 12 }} onClick={handleChangeSelection}>{t('common.change').toLocaleLowerCase()}</Button> | 
          <Button type="link" size="small" style={{ fontSize: 12 }} onClick={handleClearSelection}>{t('common.clear').toLocaleLowerCase()}</Button>
        </div>
      </div>
    );
  };

  const renderPopover = () => (
    <SelectionPanel
      query={query}
      queryRoot={queryRoot}
      queryVariables={queryVariables}
      initialItemsCount={initialItemsCount}
      renderItem={renderItem}
      confirmLabel={t('common.select')}
      onCancel={handleCancel}
      onConfirm={handleSelection}
      onSelect={panelSetSelected}
      selected={panelSelected}
      multi={multi}
      disabled={!!disabled ? (typeof disabled !== 'boolean' ? disabled : undefined) : undefined}
    />
  );

  return (
    <div className={`smart-select${block ? ' smart-select-block' : ''}${className ? ` ${className}` : ''}`}>
      <Popover
        content={renderPopover()}
        title={title}
        trigger="click"
        visible={opened}
      >
        { selectedCount === 0 
          ? (<Button type="link" onClick={handleChangeSelection} disabled={disabled === false}>
              { buttonLabel || title }{opened ? <UpOutlined/> : <DownOutlined/>}
            </Button>)
          : renderWrappedItem()
        }
      </Popover>
    </div>
  );
};

export default SmartSelect;