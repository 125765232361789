import { TeamOutlined } from '@ant-design/icons';
import { Actions, Resources } from '../../../rbac';

import { MenuConfig, Route } from '../../routes.types';
import TeamList from './TeamList';

export const teamRoutePaths = {
  root: '/teams',
};

export const routes: Route[] = [
  {
    path: teamRoutePaths.root,
    component: TeamList,
    permissions: { [Resources.TEAM]: [Actions.READ] }
  }
];

export const menus: MenuConfig = [
  {
    route: routes[0],
    label: 'team.menuTitle',
    icon: TeamOutlined,
    order: 2,
  }
];
