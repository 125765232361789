import { TestSession } from '../testSession';
import { Paginated } from '../types';
import { User } from '../user/types';

export enum RoomTypes {
  CONFERENCE = 'conference',
  CHATROOM = 'chatroom',
}

export enum RoomStatus {
  SCHEDULED = 'scheduled',
  PREPARING = 'preparing',
  OPEN = 'open',
  CLOSED = 'closed',
};
export enum ParticipantStatuses {
  INVITED = 'invited',
  ACCEPTED = 'accepted',
  REFUSED = 'refused',
};
export enum ParticipantRoles {
  HOST = 'host',
  PANELIST = 'panelist',
  AUDIENCE = 'audience',
};

export type ParticipantMetadata = {
  clientId: string;
  joined: boolean;
  online: boolean;
  handRaised: boolean;
  audioMuted: boolean;
  videoMuted: boolean;
};

export type Participant = {
  user: User;
  role: ParticipantRoles;
  status: ParticipantStatuses;
  metadata?: ParticipantMetadata;
};

export type ParticipantUpdateInput = {
  userId: string;
  role: ParticipantRoles;
};

export type Room = {
  id: string;
  name: string;
  type: RoomTypes;
  participants: Participant[];
  openingAt: string;
  closingAt?: string;
  status: string;
  nonce?: string;
  createdAt: string;
  updatedAt: string;
};

export type RoomsQueryInput = {
  cursor?: string;
  status: RoomStatus[];
};

export type PaginatedRooms = {
  rooms: Paginated<Room>;
};

export type GetRoomResponse = {
  room: Room;
};

export type RoomTestSessions = {
  room: {
    testSessions: [{
      testSession: TestSession;
    }]
  }
}