import { all, takeEvery } from 'redux-saga/effects';
import { newPost } from './post.action';

function* newPostSaga() {

}

function* watchPostSaga() {
  yield all([
    yield takeEvery(newPost.started,  newPostSaga),
  ])
}

export { watchPostSaga };
