import "./MainMenu.css";

import Menu, { MenuMode } from 'antd/lib/menu';
import { History } from 'history';
import { TFunction } from 'i18next';
import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { User } from '../../../../api/user';
import { hasPermissions } from '../../../../rbac';
import { appMenus } from '../../../../routes';
import { MenuItem } from '../../../../routes/routes.types';

export type mainMenuProps = {
  authUser: User,
  isMobile: boolean;
}

const renderItem = (item: MenuItem, index: number, authUser: any, history: History, t: TFunction) => {
  if (item.route.permissions) {
    if (!hasPermissions(authUser, item.route.permissions)) {
      return false;
    }
  }

  // if (item.children) {
  //   return (
  //     <SubMenu key={item.route.path} title={item.label}>
  //       {item.children.map((e, i) => renderItem(e, i, authUser, history, t))}
  //     </SubMenu>
  //   );
  // }

  const changePage = (e: any) => {
    console.log("Change page to", item.route.path);
    history.push(item.route.path);

    document.title = t(item.label);

    if (e.preventDefault)
      e.preventDefault()

    if (e.stopPropagation)
      e.stopPropagation();

    return false;
  };

  return (
    <Menu.Item
      key={item.route.path}
      id={'menu' + item.route.path.replace(/\//g, '-')}
      // onClick={changePage}
    >
      <a href={item.route.path} onClick={changePage} target="_self">
        {t(item.label)}
      </a>
    </Menu.Item>
  );
};

const MainMenu: React.FC<mainMenuProps> = ({
  authUser,
  isMobile,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const menuItems = appMenus.sort((a, b) => a.order < b.order ? -1 : 0);

  const pageMatch = useRouteMatch(menuItems.map(x => `${x.route.path}`));
  const matchedPage = pageMatch ? pageMatch.path : '/';

  return (
    <Menu className={`main-menu ${isMobile ? 'main-menu-mobile' : 'main-menu-desktop'}`} theme='light' mode={isMobile ? 'vertical' : 'horizontal'} selectedKeys={[matchedPage]}>
      {menuItems.map((e, i) => renderItem(e, i, authUser, history, t))}
    </Menu>
  );
};

export default MainMenu;