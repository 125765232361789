import { ProcessEnvs } from "../config";
import { rootReducer } from './reducers';
import initStore, { StoreConfiguration } from "./store";

export { history } from './config.history';

let createConfig: () => StoreConfiguration;
if (process.env.ENVIRONMENT === ProcessEnvs.PRODUCTION) {
  createConfig = require('./config.prod').default;
} else {
  createConfig = require('./config.dev').default;
}

export default initStore(
  createConfig(),
  rootReducer,
  {},
);