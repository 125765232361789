import gql from 'graphql-tag';

export const CREATE_POST = gql`
  mutation CreatePost($input: PostCreateInput!) {
    createPost(input: $input) {
      id
    }
  }
  `;


export const UPDATE_POST = gql`
  mutation UpdatePost($input: PostUpdateInput!) {
    updatePost(input: $input) {
      id
      title
      description
      audienceRoles
      featuredImage {
        file {
          id
          name
          type
          size
          url
        }
      }
      tags
      files {
        file {
          id
          name
          type
          size
          url
        }
      }
      content
      comments {
        id
        content
        createdAt
        updatedAt
      }
      author {
        id
        type
        name
      }
      published
      createdAt
      updatedAt
    }
  }
  `;

export const CREATE_POST_FILE = gql`
  mutation PostCreateFile($input: PostCreateFileInput!) {
    postCreateFile(input: $input) {
      id
    }
  }
`;

export const DELETE_POST_FILE = gql`
  mutation PostRemoveFile($input: PostRemoveFileInput!) {
    postRemoveFile(input: $input) {
      id
    }
  }
`;

export const CREATE_FEATURED_IMAGE = gql`
  mutation PostCreateFeaturedImage($input: PostCreateFileInput!) {
    postCreateFeaturedImage(input: $input) {
      id
    }
  }
`;

export const DELETE_FEATURED_IMAGE = gql`
  mutation PostRemoveFeaturedImage($input: ObjectId!) {
    postRemoveFeaturedImage(id: $input) {
      id
    }
  }
`;

export const DELETE_POST = gql`
  mutation DeletePost($id: ObjectId) {
    deletePost(id: $id) {
      id
    }
  }
`;

export const POST_ADD_TO_HEADER = gql`
  mutation AddPostToHeader($input: PostAddToHeaderInput!) {
    postAddToHeader(input: $input) {
      id
    }
  }
`;

export const POST_REMOVE_FROM_HEADER = gql`
  mutation RemovePostFromHeader($input: PostRemoveFromHeaderInput!) {
    postRemoveFromHeader(input: $input)
  }
`;