import { useContext } from 'react';

import { Auth0Context } from '../components/Auth0Context';

export const useAuth = () => {
  const ctx = useContext(Auth0Context);

  return {
    login: ctx?.login,
    isAuthenticating: ctx ? ctx.isAuthenticating : false,
    isAuthenticated: ctx ? ctx.isAuthenticated : false,
    logout: (returnTo?: string) => ctx.client?.logout({
      returnTo
    }),
    user: ctx.user,
  };
};