import "./TestSessionItem.css";

import { Button, Collapse, List, Modal, Popconfirm, Progress } from 'antd';
import React from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { CheckOutlined, ExclamationCircleOutlined, StopOutlined } from '@ant-design/icons';

import { CLOSE_TEST_SESSION, GET_TEST_SESSION_RESULTS, SingleTestSessionResults, TestSession, TestSessionWithResults } from '../../../../../api/testSession';
import UsersPanel from '../../../../../shared/UsersPanel';

const { Panel } = Collapse;

export type TestSessionItemProps = {
  testSession: TestSession;
  onClose?: () => void;
  isDemo?: boolean;
}

const TestSessionItem: React.FC<TestSessionItemProps> = (props) => {
  const { testSession, onClose, isDemo } = props;

  const { t } = useTranslation();

  const [getResults, results] = useLazyQuery<SingleTestSessionResults>(GET_TEST_SESSION_RESULTS, {
    pollInterval: 3000,
  });
  const [closeTestSession, closing] = useMutation(CLOSE_TEST_SESSION);

  React.useEffect(() => {
    if (results.called || !testSession || testSession.completed || isDemo) {
      return;
    }

    getResults({
      variables: {
        id: testSession.id,
      }
    });

    return () => {
      if (results.stopPolling) {
        results.stopPolling();
      }
    }
  }, [testSession]);

  React.useEffect(() => {
    if (!results.data) {
      return;
    }

    if (results.data.getTestSessionResults.completed && results.stopPolling) {
      results.stopPolling();
    }
  }, [results.data]);

  const handleCloseTestSessionConfirm = React.useCallback(async () => {
    if (!results.data || !results.called) {
      return;
    }

    if (results.data.getTestSessionResults.completed) {
      return;
    }

    await closeTestSession({
      variables: {
        id: results.data.getTestSessionResults.id,
      }
    });

    if (typeof onClose === 'function') {
      onClose();
    }
  }, [results.data]);

  const handleCloseTestSession = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: t('testsessions.completeManuallyHint'),
      content: t('testsessions.completeManuallyConfirm'),
      okText: t('testsessions.completeManually'),
      cancelText: t('common.cancel'),
      okButtonProps: { 
        danger: true, 
        loading: closing.loading,
        disabled: closing.called,
      },
      onOk: handleCloseTestSessionConfirm,
    });
  };

  const renderCompleted = () => (
    <div className="test-session-item-progress-container">
      <span className="test-session-item-status completed"><CheckOutlined /> {t('testsessions.completed')}</span>
    </div>
  );

  const renderProgress = () => {
    const percent = !isDemo ? results.data?.getTestSessionResults.completion : (testSession as TestSessionWithResults).completion;

    return (
      <div className="test-session-item-progress-container">
        <span className="test-session-item-status progress">{t('testsessions.ongoing')}</span>
        <Progress percent={percent || 0} />
      </div>
    );
  };

  const testSessionResults = results.data?.getTestSessionResults;

  return (
    <List.Item key={testSession.id} actions={[]}>
      <div className="test-session-item">
        <div className="test-session-item-title">
          <strong>{testSession.name}</strong>

          {!(testSession.completed || testSessionResults?.completed) &&
            <Button
              size="small"
              icon={<StopOutlined />}
              loading={closing.loading}
              disabled={closing.called || isDemo}
              onClick={handleCloseTestSession}
            >
              {t('testsessions.completeManually')}
            </Button>
          }
        </div>
        <span className="test-session-item-questionnaire">{testSession.questionnaireModel && testSession.questionnaireModel.title}</span>
        { (testSession.completed || testSessionResults?.completed) && !isDemo
          ? renderCompleted()
          : renderProgress()
        }

        { (testSessionResults && !testSessionResults.completed) &&
        <Collapse className="test-session-expander">
          <Panel header={<div className="test-session-expander-button">{t('testsessions.pariticpantsStatus')}</div>} key="1">
            <UsersPanel
              isVisible={true}
              users={testSessionResults?.participants || []}
              slim
            />
          </Panel>
        </Collapse>
        }
      </div>      
    </List.Item>
  );
};

export default TestSessionItem;