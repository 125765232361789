import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';

import { clientEnv } from '../config';

let token;

export const httpLink = createHttpLink({
  uri: clientEnv.GRAPHQL_API,
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false
  }),
  uri: clientEnv.GRAPHQL_API,
  request: (operation) => {
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    })
  },
});

export const setToken = (newToken: string) => {
  token = newToken;
};

export default client;
