import './ParticipantsPage.css';

import { Avatar, Badge, Button, List } from "antd";
import Search from 'antd/lib/input/Search';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AudioFilled, AudioOutlined, DeleteOutlined, TeamOutlined, UserOutlined, VideoCameraFilled, VideoCameraOutlined } from '@ant-design/icons';

import { Participant, ParticipantRoles, ParticipantUpdateInput } from '../../../../api/room';
import { User } from '../../../../api/user';
import { useUser } from '../../../../shared/useUser';
import { useVideoConference } from '../../../../videoconf';
import { isPanelist } from '../../../../videoconf/domain';
import ParticipantList from './ParticipantList';

export type ParticpantsPageProps = {
  isEditMode?: boolean;
}

const ParticipantsPage: React.FC<ParticpantsPageProps> = (props) => {
  const {
    isEditMode: externalIsEditMode
  } = props;

  const { user } = useUser();

  const conference = useVideoConference();
  const { t } = useTranslation();

  const [internalEditMode, setEditMode] = React.useState<boolean>(false);
  const [isSlim, setSlim] = React.useState<boolean>(false);

  const [searchTerm, setSearchTerm] = React.useState<string>();
  const [panelists, setPanelists] = React.useState<any>();
  const [audience, setAudience] = React.useState<any>();
  const [panelistsParticipants, setPanelistsParticipants] = React.useState<Participant[]>();
  const [audienceParticipants, setAudienceParticipants] = React.useState<Participant[]>();

  const isEditMode = typeof externalIsEditMode !== 'undefined' ? externalIsEditMode : internalEditMode;

  React.useEffect(() => {
    if (!conference || !conference.room) {
      return;
    }

    const oPanelists: any = {};
    const aPanelists: Participant[] = [];
    const oAudience: any = {};
    const aAudience: Participant[] = [];

    conference.room.participants.forEach(x => {
      if (isPanelist(x)) {
        oPanelists[x.user.id] = x.user;
        aPanelists.push(x);
      } else {
        oAudience[x.user.id] = x.user;
        aAudience.push(x);
      }
    });

    if (conference.room.participants.length > 8) {
      setSlim(true);
    }

    // for (let i=0; i < 10; i++) {
    //   aPanelists.push(aPanelists[0]);
    // }
    // for (let i=0; i < 50; i++) {
    //   aAudience.push(aAudience[0]);
    // }
    // setSlim(true);

    setPanelists(oPanelists);
    setPanelistsParticipants(aPanelists);
    setAudience(oAudience);
    setAudienceParticipants(aAudience);
  }, [conference, conference?.room.participants]);

  const handleToggleEditMode = React.useCallback(() => {
    // Reset search if switching to/from edit mode
    setSearchTerm(undefined);

    setEditMode(!isEditMode);
  }, [isEditMode]);

  const searchFn = React.useCallback((item: Participant) => {
    if (!item) {
      return false;
    }

    const reg = new RegExp((searchTerm || '').trim().toLowerCase(), 'gmi');

    return reg.test(item.user.firstName) || reg.test(item.user.lastName);
  }, [searchTerm]);

  const handleDeleteParticipant = React.useCallback((userId: string) => {
    if (!conference) {
      return;
    }

    const newParticipants: ParticipantUpdateInput[] = [];
    conference.room.participants.forEach(x => {
      if (x.user.id !== userId) {
        newParticipants.push({
          userId: x.user.id,
          role: x.role,
        });
      }
    });

    conference.changeParticipants(newParticipants);
  }, [conference]);

  const handleEditParticipants = React.useCallback((items: { [id: string]: User }, role: ParticipantRoles) => {
    if (!conference) {
      return;
    }

    const newParticipants: ParticipantUpdateInput[] = [];
    conference.room.participants.forEach(x => {
      if (!items[x.user.id] && x.role !== role) {
        newParticipants.push({
          userId: x.user.id,
          role: x.role,
        });
      }
    });    
    Object.keys(items).map(userId => {
      newParticipants.push({
        userId,
        role
      });
    });

    conference.changeParticipants(newParticipants);

    if (role === ParticipantRoles.AUDIENCE) {
      setAudience(items);
    } else {
      setPanelists(items);
    }
  }, [conference]);

  if (!conference) {
    return null;
  }
  
  const renderUser = (participant: Participant, actions?: React.ReactNode[]) => {
    const { metadata } = participant;
    const isOnline = metadata?.online || false;

    return (
      <List.Item
        className="videconference-user-container"
        actions={isEditMode && participant.user.id !== user.id
          ? [
            <Button
              type="link"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteParticipant(participant.user.id)}
            >
              {t('common.delete')}
            </Button>,
          ]
          : actions
        }
      >
        <div className="videconference-user">
          <Badge dot={metadata && metadata.handRaised} className="videconference-user-avatar">
            <Avatar
              size="small"
              shape="square"
              className={`videconference-user-avatar${isOnline ? ' participant-online' : ''}`}
              icon={<UserOutlined />}
            />
          </Badge>
          <span className="videconference-user-name">{participant.user.firstName} {participant.user.lastName}</span>
        </div>
      </List.Item>
    );
  };
  
  const renderPanelist = (participant: Participant) => renderUser(participant);
  
  const renderAudience = (participant: Participant) => {
    const user = participant.user;
    const { metadata } = participant;
    
    const actions = [
      <Button
        type="link"
        size="small"
        style={{ padding: 2 }}
        disabled={!metadata}
        onClick={metadata ? () => conference.setUserAudio(user.id, metadata.audioMuted) : undefined}
      >{(metadata && !metadata.audioMuted) ? <AudioFilled /> : <AudioOutlined />}</Button>,
      <Button
        type="link"
        size="small"
        style={{ padding: 2 }}
        disabled={!metadata}
        onClick={metadata ? () => conference.setUserVideo(user.id, metadata.videoMuted) : undefined}
      >{(metadata && !metadata.videoMuted) ? <VideoCameraFilled /> : <VideoCameraOutlined />}</Button>
    ];

    return renderUser(participant, actions);
  };

  return (
    <div className="videoconference-participants">
      <div className="videoconference-participants-header">
        <Search
          placeholder={t('videoconf.searchParticipant')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onSearch={setSearchTerm}
          allowClear
        />
        <Button
          className="videoconference-participants-edit"
          type={isEditMode ? 'primary' : 'link'}
          icon={<TeamOutlined />}
          onClick={handleToggleEditMode}
        >
          {isEditMode ? t('common.done') : t('common.edit')}
        </Button>
      </div>

      <div className="videoconference-participants-content">
        {panelistsParticipants && panelistsParticipants.length &&
          <ParticipantList
            title={t('videoconf.panelists')}
            participants={panelistsParticipants.filter(searchFn)}
            changeButtonLabel="change panelists"
            render={renderPanelist}
            selected={panelists}
            onSelect={(x) => handleEditParticipants(x, ParticipantRoles.PANELIST)}
            disabled={audience}
            isEditMode={isEditMode}
            slim={isSlim}
          />
        }
        <ParticipantList
          title={t('videoconf.audience')}
          participants={audienceParticipants?.filter(searchFn) || []}
          changeButtonLabel="change audience"
          render={renderAudience}
          selected={audience}
          onSelect={(x) => handleEditParticipants(x, ParticipantRoles.AUDIENCE)}
          disabled={panelists}
          isEditMode={isEditMode}
          slim={isSlim}
        />
      </div>
    </div>
  );
};

export default ParticipantsPage;