import group from './group.json';
import questionnaire from './questionnaire.json';
import room from './room.json';
import user from './user.json';

export default {
  user,
  room,
  group,
  questionnaire,
};