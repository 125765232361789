import "./UserMenu.css";

import { Avatar, Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { FormOutlined, IdcardOutlined, LoginOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

import { User } from '../../../../api/user';
import { Actions, Resources, hasPermission } from '../../../../rbac';
import { postRoutePaths } from '../../../../routes/Post';
import Loader from '../../../Components/Loader';
import { useModal } from '../../../../shared/useModal';
import UserProfileModal from '../../../UserProfileModal';

export type UserMenuProps = {
  userName: string,
  authUser: User,
  isLoading?: boolean;
  isMobile: boolean;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  login?: () => void;
  logout: () => void;
}
const UserMenu: React.FC<UserMenuProps> = ({
  authUser,
  isLoading,
  isAuthenticated,
  isAuthenticating,
  login,
  logout,
  userName,
  isMobile,
}) => {
  const history = useHistory();
  const { openModal, modalState: { isVisible }, closeModal } = useModal();

  if (isAuthenticating || isLoading) {
    return <Loader isLoading={true} />
  }

  const canPost = authUser && hasPermission(authUser, Resources.POST, Actions.CREATE);

  const MenuList = () => (
    <Menu>
      {!isMobile && <Menu.Item key={'user.profile'}
        onClick={() => openModal()}><IdcardOutlined /> Profile</Menu.Item>}
      <Menu.Divider />
      {canPost && (
        <Menu.Item key={'post.userPosts'}
          onClick={() => history.push(postRoutePaths.userPosts)}>
          <FormOutlined /> My Post</Menu.Item>)}
      {canPost && <Menu.Divider />}
      {canPost && (<Menu.Item key={'post.new'}
        onClick={() => history.push(postRoutePaths.postDetails)}>
        <FormOutlined /> New Post</Menu.Item>)}
      {canPost && (<Menu.Divider />)}
      <Menu.Item
        key='logout'
        onClick={() => logout && logout()}>
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu >
  );

  return (
    <>
      <UserProfileModal isVisible={isVisible} closeModal={closeModal} />
      {!isMobile && isAuthenticated && !isAuthenticating &&
        (
          <Dropdown overlay={<MenuList />} placement='bottomCenter' className='user-menu-desktop'>
            <div className="header-user-button">
              <Avatar icon={<UserOutlined />} className="header-user-icon" />{userName}
            </div>
          </Dropdown>
        )
      }
      {isAuthenticated && !isAuthenticating && isMobile && (
        <Menu theme='light' mode='horizontal' className={'user-menu-mobile'}>
          <MenuList />
        </Menu>)}
      {!isAuthenticated && <Menu theme='light' mode='horizontal' className={'user-menu-desktop'}>
        <Menu.Item key='login' onClick={login}>
          <Button icon={<LoginOutlined />} type='primary'>Login</Button>
        </Menu.Item>
      </Menu>}
    </>
  );
};

export default UserMenu;