import './GuidedTour.css';

import { Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Tour, { ReactourProps } from 'reactour';

import { BulbFilled, BulbOutlined, BulbTwoTone } from '@ant-design/icons';

import { clientEnv } from '../../config';
import Multiline from '../Multiline';

export type GuidedTourProps = Omit<ReactourProps, 'isOpen' | 'onRequestClose'> & {
  id: string;
  title?: string;
  message: string;
  buttonText?: string;
};

const LS_GUIDED_TOUR = `${clientEnv.TENANT_ID}.guidedTours`;
const LS_TOUR_FINISHED = 'finished';

const GuidedTour: React.FC<GuidedTourProps> = (props) => {
  const {
    id,
    title,
    message,
    buttonText,
    ...tourProps
  } = props;

  const { t } = useTranslation();

  const [showTour, setTourIsShown] = React.useState<boolean>(false);
  const [showTourProposal, setShowTourProposal] = React.useState<boolean>(true); 
  const [proposalIsOpen, setProposalIsOpen] = React.useState<boolean>(false); 

  const lsTourKey = `${LS_GUIDED_TOUR}.${id}`;

  React.useEffect(() => {
    if (clientEnv.FORCE_TOURS) {
      setTourIsShown(true);
      return;
    }

    const lsTourState = window.localStorage.getItem(lsTourKey);
    if (!lsTourState || lsTourState !== LS_TOUR_FINISHED) {
      setTourIsShown(true);
    }
  }, [lsTourKey]);

  React.useEffect(() => {
    if (showTour && showTourProposal && !proposalIsOpen) {
      window.setTimeout(() => {
        setProposalIsOpen(true);
      }, 2000);
    }
  }, [showTour, showTourProposal, proposalIsOpen]);

  const handleClose = React.useCallback(() => {
    // Store that we've shown the tour
    window.localStorage.setItem(lsTourKey, LS_TOUR_FINISHED);
    
    if (proposalIsOpen) {
      setProposalIsOpen(false);

      window.setTimeout(() => {
        setTourIsShown(false);
      }, 2000);
    } else {
      setTourIsShown(false);
    }
  }, [lsTourKey, proposalIsOpen]);

  if (!showTour) {
    return null;
  }

  if (showTourProposal) {
    return (
      <div className={`tour-proposal${proposalIsOpen ? ' open' : ' closed'}`}>
        <div className="tour-proposal-content">
          <BulbFilled className="tour-proposal-icon" />
          <div>
            <Title level={4}>{title || t('common.takeTour')}</Title>
            <Multiline text={message} />
          </div>
        </div>
        <div className="tour-proposal-actions">
          <Button type="default" onClick={handleClose}>{t('common.notNow')}</Button>
          <Button type="primary" onClick={() => setShowTourProposal(false)}>{buttonText || t('common.startTour')}</Button>
        </div>
      </div>
    );
  }
  
  return (
    <Tour
      accentColor="#1890ff"
      isOpen={!showTourProposal}
      onRequestClose={handleClose}
      {...tourProps}
    />
  );
};

export default GuidedTour;