import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase';
import { FirebaseContext } from './FirebaseContext';
import { FirebaseConfig } from '../types';

export type FirebaseProviderProps<T = {}> = {
  firebaseConfig: FirebaseConfig;
};

export const FirebaseProvider: React.FC<FirebaseProviderProps> = ({ children, firebaseConfig }) => {
  const [storage, setStorage] = useState<firebase.storage.Storage>();
  const [storageRef, setStorageRef] = useState<firebase.storage.Reference>();

  useEffect(() => {
    if (!firebase.apps[0]) {
      firebase.initializeApp(firebaseConfig);
    }
    setStorage(firebase.storage());
    if (storage) {
      setStorageRef(firebase.storage().ref())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStorage, setStorageRef, storage]);

  return (
    <FirebaseContext.Provider value={{
      storage,
      storageRef,
    }}>
      {children}
    </FirebaseContext.Provider>
  )
};