import gql from 'graphql-tag';

export const ANSWER_ROOM_INVITE = gql`
  mutation AnswerRoomInvite($input: RoomInviteAnswerInput!) {
    answerRoomInvite(input: $input) {
      id
      createdAt
      updatedAt
    }
  }
  `;