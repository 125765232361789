import './PostSidebar.css';

import React from 'react';

import TagsBrowser from './TagsBrowser';

const PostSidebar: React.FC = () => {

  return (
    <div id='post-sidebar'>
      <TagsBrowser />
    </div>
  );
};

export default PostSidebar;