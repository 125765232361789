import { createContext } from 'react';
import * as firebase from 'firebase';

export type FirebaseContextProps = {
  storage: firebase.storage.Storage | undefined;
  storageRef: firebase.storage.Reference | undefined;
}

export const defaultContextValue: FirebaseContextProps = {
  storage: undefined,
  storageRef: undefined,
};

export const FirebaseContext = createContext(defaultContextValue);