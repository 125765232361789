import { Button, Result } from 'antd';
import { StringMap, TOptions } from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Multiline from '../Multiline';

export type ResultSuccessProp = {
  title: string;
  tkey: string;
  options?: string | TOptions<StringMap> | undefined;
  initialized: boolean;
  onOk: (value: boolean) => void;
}

const ResultSuccess: React.FC<ResultSuccessProp> = ({
  title,
  tkey,
  options,
  initialized,
  onOk
}) => {
  const { t } = useTranslation();

  const extra = initialized ? [
    <Button
      type="primary"
      key="user-aknowledge"
      onClick={() => onOk(true)}
    >{t('common.ok')}</Button>
  ]
    : [
      <Button
        type='link'
        key="popover-action-createAnother"
        onClick={() => onOk(false)}
      >{t('common.createAnother')}</Button>,
      <Button
        type="primary"
        key="popover-action-aknowledge"
        onClick={() => onOk(true)}
      >{t('common.ok')}</Button>
    ];

  return (
    <Result
      key='success'
      status="success"
      title={title}
      subTitle={<Multiline tkey={tkey} options={options} />}
      extra={extra}
    />
  );
};

export default ResultSuccess;
