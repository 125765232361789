import { compose } from 'redux';
import { StoreConfiguration } from "./store";

const createStoreConfig = (): StoreConfiguration => {
  return {
    additionalMiddlewares: [],
    compose,
  };
};

export default createStoreConfig;