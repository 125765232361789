import group from './group.json';
import post from './post.json';
import questionnaireModel from './questionnaireModel.json';
import report from './reports.json';
import room from './room.json';
import team from './team.json';
import testsession from './testsession.json';
import user from './user.json';
import usageReports from './usageReports.json';

export default {
  group,
  post,
  questionnaireModel,
  room,
  report,
  team,
  testsession,
  user,
  usageReports,
};