import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

// Create History with redux-first-history
export const history = createBrowserHistory();

export const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({
  history,
  oldLocationChangePayload: true,
});