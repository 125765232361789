import { ParticipantRoles } from '../../api/room';
import { ClientSettings, RoomTypeConfig } from '../domain/types';
import { basicJitsiSettings } from './baseClientSettings';

export const panelistConferenceClientConfig: ClientSettings = {
  interfaceConfigOverwrite: {
    ...basicJitsiSettings,

    TOOLBAR_BUTTONS: [
      'microphone',
      'camera',
      // 'closedcaptions',
      'desktop',
      'fullscreen',
      'fodeviceselection',
      // 'hangup',
      // 'profile',
      // 'info',
      'chat',
      // 'recording',
      // 'livestreaming',
      // 'etherpad',
      // 'sharedvideo',
      'settings',
      // 'raisehand',
      'videoquality',
      // 'filmstrip',
      // 'invite',
      // 'feedback',
      // 'stats',
      'shortcuts',
      'tileview',
      'videobackgroundblur',
      // 'download',
      'help',
      // 'mute-everyone',
      // 'e2ee'
    ],

    FILM_STRIP_MAX_HEIGHT: 70,
    VERTICAL_FILMSTRIP: false,
  },
  configOverwrite: {
    openBridgeChannel: true,
    disableInviteFunctions: true,
    doNotStoreRoom: true,
    enableWelcomePage: false,
    remoteVideoMenu: {
      disableKick: true
    },
    disableRemoteMute: true,
    requireDisplayName: false,
    prejoinPageEnabled: true,
    enableNoAudioDetection: true,
    enableNoisyMicDetection: true,
    // focusUserJid: 'focus@auth.jitsi-meet.example.com',
  }
};

export const audienceConferenceClientConfig: ClientSettings = {
  interfaceConfigOverwrite: {
    ...basicJitsiSettings,

    TOOLBAR_BUTTONS: [
      'microphone',
      'camera',
      // 'closedcaptions',
      'desktop',
      'fullscreen',
      'fodeviceselection',
      // 'hangup',
      // 'profile',
      // 'info',
      'chat',
      // 'recording',
      // 'livestreaming',
      // 'etherpad',
      // 'sharedvideo',
      'settings',
      // 'raisehand',
      'videoquality',
      // 'filmstrip',
      // 'invite',
      // 'feedback',
      // 'stats',
      'shortcuts',
      'tileview',
      'videobackgroundblur',
      // 'download',
      'help',
      // 'mute-everyone',
      // 'e2ee'
    ],

    FILM_STRIP_MAX_HEIGHT: 70,
    VERTICAL_FILMSTRIP: true,
  },
  configOverwrite: {
    openBridgeChannel: true,
    startWithAudioMuted: true,
    startWithVideoMuted: true,
    disableInviteFunctions: true,
    doNotStoreRoom: true,
    enableWelcomePage: false,
    remoteVideoMenu: {
      disableKick: true
    },
    disableRemoteMute: true,
    requireDisplayName: false,
    prejoinPageEnabled: false,
    enableNoAudioDetection: true,
    enableNoisyMicDetection: true,
    // focusUserJid: 'focus@auth.jitsi-meet.example.com',
  }
};

export const conferenceConfig: RoomTypeConfig = {
  [ParticipantRoles.HOST]: {
    clientSettings: panelistConferenceClientConfig,
    hasControlPanel: true
  },
  [ParticipantRoles.PANELIST]: {
    clientSettings: panelistConferenceClientConfig,
    hasControlPanel: true,
  },
  [ParticipantRoles.AUDIENCE]: {
    clientSettings: audienceConferenceClientConfig,
    hasControlPanel: false,
  },
};
