import gql from 'graphql-tag';
import React from 'react'

import { clientEnv } from '../../config';

export const GET_HEADER_POSTS = gql`
  query getHeaderPosts {
    posts(tenantId: "${clientEnv.TENANT_ID}", first: 5, filters: { header: true, published: true }) {
      edges {
        node {
          id
          title
          description
          comments {
            id
            content
          }
          files {
            file {
              id
            }
          }
          featuredImage {
            file {
              id
              name
              type
              size
              url
            }
          }
          metadata {
            headerPosition
          }
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        count
      }
    }
  }
`;

export const GET_POSTS = gql`
  query getPosts($cursor: String, $filters: PostFilters, $search: String) {
    posts(tenantId: "${clientEnv.TENANT_ID}", first: 10, after: $cursor, filters: $filters, search: $search) {
      edges {
        node {
          id
          title
          description
          comments {
            id
            content
          }
          files {
            file {
              id
            }
          }
          featuredImage {
            file {
              id
              name
              type
              size
              url
            }
          }
          published
          author {
            id
            type
            name
          }
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        count
      }
    }
  }
`;

export const GET_ADMIN_POSTS = gql`
  query getPosts($cursor: String, $filters: PostFilters, $search: String) {
    posts(tenantId: "${clientEnv.TENANT_ID}", first: 10, after: $cursor, filters: $filters, search: $search) {
      edges {
        node {
          id
          title
          description
          comments {
            id
            content
          }
          files {
            file {
              id
            }
          }
          featuredImage {
            file {
              id
              name
              type
              size
              url
            }
          }
          audienceRoles
          published
          author {
            id
            type
            name
          }
          tags
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        count
      }
    }
  }
`;

export const GET_POST = gql`
query getPost($id: ObjectId!) {
   post(id: $id, tenantId: "${clientEnv.TENANT_ID}") {
    id
    title
    description
    audienceRoles
    featuredImage {
      file {
        id
        name
        type
        size
        url
      }
    }
    tags
    files {
      file {
        id
        name
        type
        size
        url
      }
    }
    content
    comments {
      id
      content
      createdAt
      updatedAt
    }
    author {
      id
      type
      name
    }
    published
    createdAt
    updatedAt
  }
}
`;

export const GET_FILES = gql`
query getPost($id: ObjectId!) {
   post(id: $id, tenantId: "${clientEnv.TENANT_ID}") {
    id
    files {
      file {
        id
        name
        type
        size
        url
      }
    }
  }
}
`;

export const USER_POSTS = gql`
  query UserPosts($cursor: String, $filters: UserPostFilters, $search: String) {
    userPosts(first: 10, after: $cursor, filters: $filters, search: $search) {
      edges {
        node {
          id
          title
          description
          comments {
            id
            content
          }
          files {
            file {
              id
            }
          }
          featuredImage {
            file {
              id
              name
              type
              size
              url
            }
          }
          published
          author { 
            id
            name
            type
          }
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        count
      }
    }
  }
`;

export const GET_POSTS_REPORT = gql`
  query getPostsReport($createdFrom: String!, $createdTo: String!) {
    postsReport(createdFrom: $createdFrom, createdTo: $createdTo) {
      total
      newTotal
      published
      draft
    }
  }
`;
