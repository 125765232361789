import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../shared/Components/Loader';

const PostContainer = Loadable({
  loader: () => import('./Post'),
  loading: () => <Loader isLoading={true} />,
});

export default PostContainer;
