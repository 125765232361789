import moment from 'moment';

import { User } from '../api/user';

export const userFormattedDate = (date: Date | string, user: User, format: string = 'LLLL') => {
  const locale = user.locale || 'en';
  const timezone = user.timezone || 'Europe/Berlin';

  const localMoment = moment(date);
  localMoment.locale(locale);

  return localMoment.tz(timezone).format(format);
};


export const userFormNowDate = (date: Date | string, user: User) => {
  const locale = user.locale || 'en';
  const timezone = user.timezone || 'Europe/Berlin';

  const localMoment = moment(date);
  localMoment.locale(locale);

  return localMoment.tz(timezone).fromNow();
};

export const getYear = (): Number => {
  const currentDate = new Date()
  return  currentDate.getFullYear()
};
