import { Auth0ContextProps } from '../components/Auth0Context';
import { Auth0ProviderProps } from '../components/Auth0Provider';
import { LoginType } from '../types';
import { createClient } from './client';

type StateFunc<T> = (oldState: (old: T) => T) => void;
type useStateReturn<T> = {
  state: T;
  setState: StateFunc<T>;
};

type AppState = {
  originURL: string;
};

type Auth0ProviderWrappedProps<T = {}> = Auth0ProviderProps<T> & {
  onBeforeRedirect: () => Promise<T>;
  onRedirectCompleted: (data: T) => void;
};

export const debug = console.log; // (...a: any[]) => {}; // console.log;

export const getUrlWithCode = () => window.location.search.includes('code=') ? window.location.href : undefined;

export const createSession = async (props: Auth0ProviderWrappedProps, useState: useStateReturn<Auth0ContextProps>, urlWithCode?: string) => {
  debug('[AUTH]: createSession()');
  const { setState } = useState;

  debug('[AUTH]: urlWithCode', urlWithCode);

  // Instance Auth0 client
  const client = await createClient(props.clientOptions);
  debug('[AUTH]: createClient', client);
  setState((prev) => ({ ...prev, client, error: undefined, isAuthenticating: true }));

  // If we have a redirect callback, handle it
  if (urlWithCode) {
    debug('[AUTH]: Handle redirect code...');
    try {
      const { appState } = await client.handleRedirectCallback(urlWithCode);
      setState((prev) => ({ ...prev, isAuthenticated: true }));

      // If set before, pass back the appState
      props.onRedirectCompleted(appState);
    } catch (error) {
      setState((prev) => ({ ...prev, error, isAuthenticating: false, isAuthenticated: false }));
    }
  }

  // Pre-fill the token cache
  await client.checkSession();

  // Check if we're authenticated
  const isAuthenticated = await client.isAuthenticated();
  debug('[AUTH]: isAuthenticated', isAuthenticated);
  setState((prev) => ({ ...prev, isAuthenticated, isAuthenticating: !isAuthenticated }));

  // Create the current instance login function
  const login = async () => {
    if (!props.loginType || props.loginType === LoginType.REDIRECT) {
      // Let client prepare appState to be inject in redirect call
      const appState = await props.onBeforeRedirect();

      await client.loginWithRedirect({
        ...props.loginOptions,
        appState,
      });
    } else {
      await client.loginWithPopup(props.loginOptions);
    }
  };

  // If we have to do it, login right now
  if (!isAuthenticated) {
    if (props.forceLogin) {
      await login();
    } else {
      setState((prev) => ({ ...prev, isAuthenticating: false, login }));
    }
  }

  if (!isAuthenticated) {
    return;
  }

  let user = await client.getUser();
  debug('[AUTH]: Auth0 user', user);
  if (typeof props.tranformUser === 'function') {
    user = props.tranformUser(user);
    debug('[AUTH]: Auth0 tranformed user', user);
  }

  const accessToken = await client.getTokenSilently();
  debug('[AUTH]: Token received', accessToken);
  if (typeof props.onSuccessfulLogin === 'function') {
    props.onSuccessfulLogin({ user, accessToken });
    debug('[AUTH]: Called onSuccessfulLogin');
  }

  setState((prev) => ({ ...prev, user }));
};