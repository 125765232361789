import { userFormattedDate, userFormNowDate } from '../../utils/dates';
import { useUser } from '../useUser/useUser';

export type UseUserDateReturn = { 
  formatDate:  (date: Date | string, format?: string) => string;
  fromNow:  (date: Date | string) => string;
};

export const useUserDate = (): UseUserDateReturn => {
  const { user, isLoading } = useUser();

  return {
    formatDate: (date: Date | string, format: string = 'LLLL') => isLoading ? '...' : userFormattedDate(date, user, format),
    fromNow: (date: Date | string) => isLoading ? '...' : userFormNowDate(date, user),
  };
};
