export enum LoginType {
  POPUP = 'popup',
  REDIRECT = 'redirect',
}

export type Auth0User = {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture?: string;
  sub: string;
  updated_at: string;
};