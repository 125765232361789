import {  } from '@ant-design/icons';

import './SelectionPanel.css';

import { Button, Checkbox, Layout, List, Radio, Skeleton, Typography } from "antd";
import Search from 'antd/lib/input/Search';
import { DocumentNode } from 'graphql';
import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { Paginated } from '../../api';

const { Title } = Typography;
const { Header, Content, Footer } = Layout;

enum FilterTypes {
  ALL = 'all',
  SELECTED = 'selected',
}

export type SelectionPanelBaseProps = {
  className?: string;
  title?: string;
  searchPlaceholder?: string;
  multi?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  renderItem: (item: unknown, index: number) => React.ReactNode;
  onCancel?: () => void;
  onConfirm?: (selectedItemsMap: any) => void;
  onSelect?: (selectedItemsMap: any) => void;
  selected?: any[];
  disabled?: any[];
  hideSummary?: boolean;
  extra?: React.ReactNode;
};

export type SelectionPanelWithQueryProps = SelectionPanelBaseProps & {
  query: DocumentNode;
  queryRoot: string;
  initialItemsCount?: number;
  queryVariables?: Record<string, any>;
};

export type SelectionPanelWithDataProps = SelectionPanelBaseProps & {
  data: any;
  searchFn?: (item: any, value: string) => boolean;
};

export type SelectionPanelProps = SelectionPanelWithQueryProps | SelectionPanelWithDataProps;

const extractNodes = <T extends any>(e: Paginated<T>, propName: string): T[] | undefined => {
  if (!e || !e[propName] || !e[propName].edges) {
    return undefined;
  }

  return e[propName].edges.map(x => x.node);
};

const SelectionPanel: React.FC<SelectionPanelProps> = (props) => {
  const {
    className,
    title,
    searchPlaceholder,
    renderItem,
    onCancel,
    onConfirm,
    cancelLabel,
    confirmLabel,
    multi,
    onSelect: externalSetSelected,
    selected: externalSelected,
    disabled,
    hideSummary,
    extra,
  } = props;

  const {
    query,
    queryVariables,
    initialItemsCount,
    queryRoot,
  } = props as SelectionPanelWithQueryProps;

  const {
    data,
    searchFn,
  } = props as SelectionPanelWithDataProps;

  const { t } = useTranslation();

  const [runQuery, lazyQuery] = useLazyQuery(query, { fetchPolicy: 'network-only', errorPolicy: 'all' });
  const [internalSelected, internalSetSelected] = useState<any>([]);
  const [filter, setFilter] = useState<string>(FilterTypes.ALL);
  const [results, setResults] = useState<any[] | null>(null);

  const setSelected = externalSetSelected || internalSetSelected;
  const selected = externalSelected || internalSelected;

  useEffect(() => {
    if (query && !lazyQuery.called && !lazyQuery.loading) {
      runQuery({
        variables: {
          ...queryVariables,
          first: initialItemsCount || 20,
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, runQuery, lazyQuery]);

  const handleLoadMore = useCallback(() => {
    if (!query || !lazyQuery.called || !lazyQuery.data) {
      return;
    }

    const pageInfo = (lazyQuery.data[queryRoot] as Paginated<any>).pageInfo;

    if (pageInfo?.hasNextPage) {
      lazyQuery.fetchMore({
        variables: {
          ...queryVariables,
          cursor: pageInfo.endCursor,
          first: initialItemsCount || 20,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return;
          }

          const newEdges = fetchMoreResult[queryRoot].edges;
          const pageInfo = fetchMoreResult[queryRoot].pageInfo;

          return (newEdges && newEdges.length)
            ? {
              [queryRoot]: {
                __typename: previousResult[queryRoot].__typename,
                edges: [...previousResult[queryRoot].edges, ...newEdges],
                pageInfo
              }
            }
            : previousResult;
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyQuery]);

  const handleSearch = useCallback((term: string) => {
    if (data && searchFn) {
      if (term && term.trim() !== '') {
        setResults(
          Object
            .values(data)
            .filter(item => searchFn(item, term))
        );
      } else {
        setResults(null);
      }

      return;
    }

    if (!query || !lazyQuery.called) {
      return;
    }

    runQuery({
      variables: {
        first: initialItemsCount || 20,
        search: term
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyQuery, initialItemsCount, data, searchFn]);

  const toggleSelection = useCallback((item) => {
    if (!item.id) {
      alert('We need an item with id to handle selection');
      return;
    }

    if (!multi) {
      setSelected({ [item.id]: item });
      return;
    }

    if (selected[item.id]) {
      const { [item.id]: oldItem, ...rest } = selected;
      setSelected({ ...rest });

      if (Object.keys(rest).length === 0) {
        setFilter(FilterTypes.ALL);
      }
    } else {
      setSelected({ ...selected, [item.id]: item });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleDeselectAll = useCallback((e: any) => {
    setSelected({});
    setFilter(FilterTypes.ALL);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderWrappedItem = (item: any, index: number) => {
    const isSelected = !!selected[item.id];
    const isDisabled = disabled ? !!disabled[item.id] : false;

    return (
      <List.Item key={`${queryRoot}-${index}-li`} className={`selection-panel-list-item${isSelected ? " ant-select-item-option-selected" : ""}${isDisabled ? ' selection-panel-list-item-disabled' : ''}`}>
        <Skeleton avatar title={false} loading={item.loading} active>
          <div 
            className="selection-panel-list-item-content"
            onClick={!isDisabled ? () => toggleSelection(item) : undefined}
          >
            { multi && <Checkbox className="selection-panel-list-item-checkbox" checked={isSelected} disabled={isDisabled} />}
            { renderItem(item, index) }
          </div>
        </Skeleton>
      </List.Item>
    );
  };

  const selectedCount = selected ? Object.keys(selected).length : 0;

  const listData = filter === FilterTypes.ALL 
    ? !!data 
      ? (results ? (results.length ? results : undefined) : Object.values(data))
      : extractNodes(lazyQuery.data, queryRoot)
    : Object.values(selected);

  return (
    <div className={`selection-panel${className ? ` ${className}` : ''}`}>
      { title && <Title level={4}>{title}</Title>}
      <Header className="selection-panel-header">
        { filter === FilterTypes.ALL ? (
        <Search
          key='search'
          placeholder={searchPlaceholder || t('common.search')}
          onSearch={value => handleSearch(value)}
          onChange={e => handleSearch(e.target.value)}
          className="selection-panel-search"
          allowClear={true}
        />
        ) : (
        <Button
          type="link"
          onClick={handleDeselectAll}
        >
          {t(`common.${selectedCount === 1 ? 'deselectOneItem' : 'deselectAllItemsCount'}`, { count: selectedCount})}
        </Button>
        )}
      </Header>
      <Content className="selection-panel-content">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={handleLoadMore}
          hasMore={lazyQuery.data ? lazyQuery.data[queryRoot].pageInfo.hasNextPage : false}
          useWindow={false}
        >
          <List
            className="selection-panel-list"
            loading={lazyQuery.loading}
            itemLayout="horizontal"
            dataSource={listData}
            renderItem={renderWrappedItem}
            bordered={false}
          />
        </InfiniteScroll>
      </Content>
      { ((multi && !hideSummary) || !!extra || !!onCancel || !!onConfirm) && (
      <Footer className="selection-panel-footer">
        { multi && !hideSummary && (
        <Radio.Group onChange={(e) => setFilter(e.target.value)} value={filter} size="small">
          <Radio.Button value={FilterTypes.ALL}>{t('common.allItems')}</Radio.Button>
          <Radio.Button value={FilterTypes.SELECTED} disabled={!selectedCount}>{t('common.selectedCount', { count: selectedCount })}</Radio.Button>
        </Radio.Group>
        )}

        {extra}

        <div className="selection-panel-actions">
          {onCancel && <Button onClick={onCancel}>{cancelLabel || 'Cancel'}</Button>}
          {onConfirm && <Button type="primary" disabled={!selectedCount} onClick={() => onConfirm(selected)}>{confirmLabel || t('common.done')}</Button>}
        </div>
      </Footer>
      )}
    </div>
  );
};

export default SelectionPanel;