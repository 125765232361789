import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { appRoutes, defaultPage } from '../../routes';
import NotFound from '../NotFound';

const RouteComponent = (route) => <Route
  path={route.path}
  render={props => (
    <route.component {...props} {...route} />
  )}
  exact={route.exact}
/>;

const Navigation = () => {
  return (
    <Switch>
      {appRoutes.map((route, index) => <RouteComponent key={index} {...route} />)}
      {!!defaultPage && <Redirect from="/" to={defaultPage || '/'} exact={true} />}
      <Route component={NotFound} />
    </Switch>
  );
}

export default Navigation;
