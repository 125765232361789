import { combineReducers } from "redux";
import { RouterState } from "redux-first-history";
import { authReducer, AuthState } from './authentication';
import { postReducer, PostState } from './post';
import { routerReducer } from './config.history';

export interface RootState {
  router: RouterState;
  auth: AuthState;
  post: PostState;
}

export const rootReducer = combineReducers<RootState>({
  router: routerReducer,
  auth: authReducer,
  post: postReducer,
});

export default rootReducer;