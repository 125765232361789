import './TestSessionsPage.css';

import { Button, Form, Input, List, Typography } from "antd";
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useState } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { EyeOutlined, FileTextOutlined } from '@ant-design/icons';

import { GET_QUESTIONNAIRE_MODELS, QuestionnaireModel } from '../../../../api/questionnaireModel';
import { CREATE_ROOM_TEST_SESSION, GET_ROOM_TEST_SESSSIONS, ParticipantRoles, RoomTestSessions } from '../../../../api/room';
import { TestSession, TestSessionWithResults } from '../../../../api/testSession';
import { User } from '../../../../api/user';
import QuestionnaireForm from '../../../../shared/Questionnaire';
import SmartSelect from '../../../../shared/SmartSelect';
import { useUser } from '../../../../shared/useUser';
import { useVideoConference, VideoConferenceClientEvents } from '../../../../videoconf';
import TestSessionItem from './TestSessionItem';

const { Text, Title } = Typography;

export type TestSessionPageProps = {
  isDemo?: boolean;
}

const makeDemoTestSession = (user: User): TestSessionWithResults => ({
  id: 'tour-test-session',
  name: 'Tour Test Session 1',
  questionnaireModelId: '',
  questionnaireModel: {
    id: 'tour-questionnaire-model',
    title: 'Tour Questionnaire model',
    items: [],
    createdAt: (new Date()).toISOString(),
    updatedAt: (new Date()).toISOString(),
  },
  participants: [{
    user,
    completed: false,
  }],
  results: [],
  completed: false,
  completion: 32,
  createdAt: new Date(),
  updatedAt: new Date(),
});

const TestSessionsPage: React.FC<TestSessionPageProps> = (props) => {
  const {
    isDemo,
  } = props;

  const { user } = useUser();
  const { t } = useTranslation();
  const conference = useVideoConference();
  const [form] = useForm();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [selected, setSelected] = useState<{ [id: string]: QuestionnaireModel }>({});
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [testSessions, setTestSessions] = useState<TestSession[]>();

  const [getTestSessions, roomTestSessions] = useLazyQuery<RoomTestSessions>(GET_ROOM_TEST_SESSSIONS, { fetchPolicy: 'network-only', errorPolicy: 'all' });
  const [createTestSession] = useMutation(CREATE_ROOM_TEST_SESSION);

  // <Button type='link' icon={<EyeOutlined />} />

  const renderQuestionnaire = (item: QuestionnaireModel) => <div><strong>{item.title}</strong><br />{item.description}</div>;
  const renderTestSession = (item: TestSession) => (
    <TestSessionItem testSession={item} onClose={handleClose} isDemo={isDemo} />
  );

  const selectedCount = Object.keys(selected).length;

  const handleCommand = React.useCallback((command: string) => {
    if (!conference) {
      return;
    }

    if (command === 'testSessionsChanged' && roomTestSessions) {
      getTestSessions({
        variables: {
          id: conference.room.id,
        }
      });
    }
  }, [roomTestSessions, conference]);

  const commandCallback = (command: string) => {
    handleCommand(command);
  };

  React.useEffect(() => {
    if (!conference || !conference.room) {
      return;
    }

    conference.addEventListener(VideoConferenceClientEvents.onCommandReceived, commandCallback);

    getTestSessions({
      variables: {
        id: conference.room.id,
      }
    });

    return () => {
      conference.removeEventListener(VideoConferenceClientEvents.onCommandReceived, commandCallback);
    }
  }, [conference]);

  React.useEffect(() => {
    if (roomTestSessions.loading || !roomTestSessions.data) {
      return;
    }

    const testSessions: TestSession[] = [];
    roomTestSessions.data.room.testSessions.forEach(({ testSession }) => {
      testSessions.push({
        ...testSession,
      });
    });

    testSessions.sort((a, b) => a.completed ? -1 : 0);

    setTestSessions(testSessions);
  }, [roomTestSessions]);

  const handlePreview = useCallback(() => {
    setShowPreview(true);
  }, []);

  const handleClosePreview = useCallback(() => {
    setShowPreview(false);
  }, []);

  const handleValuesChange = useCallback((values) => {
    setIsValid(Object.values(values).filter(x => !x).length == 0);
  }, [setIsValid]);

  const handleSubmit = useCallback((data: any) => {
    if (!selectedCount || !conference || !conference.room) {
      return;
    }

    const questionnaireId = Object.keys(selected)[0];

    createTestSession({
      variables: {
        input: {
          id: conference.room.id,
          testSession: {
            name: data.testSessionName,
            questionnaireModelId: questionnaireId,
            participants: conference.room.participants
              .filter(x => x.role === ParticipantRoles.AUDIENCE)
              .map(x => ({ userId: x.user.id })),
          },
        },
      },
    }).then((data: any) => {
      roomTestSessions.refetch();

      form.resetFields();
      setSelected({});

      conference.broadcastAudience('questionnaire', []);
      conference.broadcastPanelists('testSessionsChanged', []);
    });
  }, [selected, conference]);

  const handleClose = useCallback(() => {
    if (!conference || !conference.room) {
      return;
    }

    conference.broadcastAudience('questionnaire', []);
    conference.broadcastPanelists('testSessionsChanged', []);
  }, [conference]);

  return (
    <div className="videoconference-questionnaires">
      <Text>
        {t('testsessions.usage')}
      </Text>
      <Form
        form={form}
        layout='vertical'
        onValuesChange={handleValuesChange}
        onFinish={handleSubmit}
        className="videoconference-questionnaires-form"
      >
        <Form.Item name='testSessionName' label={t('testsessions.props.name')} required>
          <Input type='text' placeholder={t('testsessions.namePlaceholder')} />
        </Form.Item>
        <SmartSelect
          className="videoconference-questionnaires-select"
          title={t('questionnaireModel.select')}
          query={GET_QUESTIONNAIRE_MODELS}
          queryRoot="questionnaireModels"
          renderItem={renderQuestionnaire}
          onSelect={setSelected}
          selected={selected as any}
          block
        />
        <div>
          <Button
            icon={<EyeOutlined />}
            className="videoconference-questionnaires-button"
            disabled={!selectedCount}
            onClick={handlePreview}
          >{t('questionnaireModel.preview')}</Button>

          <Button 
            icon={<FileTextOutlined />}
            type="primary"
            className="videoconference-questionnaires-button"
            disabled={!selectedCount || !isValid}
            htmlType='submit'
          >{t('testsessions.send')}</Button>
        </div>
      </Form>

      <div className="videoconference-questionnaires-tests-container">
        <Title level={4}>{t('room.sentTestSessions')}</Title>
        <div className="videoconference-testSessions-list-container">
          <List
            className="videoconference-questionnaires-tests"
            loading={roomTestSessions.loading}
            itemLayout="horizontal"
            dataSource={!isDemo ? (testSessions || []) : [makeDemoTestSession(user)]}
            renderItem={renderTestSession}
            bordered={false}
          />
        </div>
      </div>

      {!!selectedCount &&
      <Modal
        centered={true}
        visible={showPreview}
        width={1024}
        title={form.getFieldValue('testSessionName') || t('questionnaireModel.preview')}
        onCancel={handleClosePreview}
        footer={[
          <Button key="submit" type="primary" onClick={handleClosePreview}>
            {t('common.aknowledge')}
          </Button>
        ]}
      >
        <div className="preview-questionnaire-modal-container">
          <QuestionnaireForm
            editable={true}
            questionnaireModel={Object.values(selected)[0]}
          />
        </div>
      </Modal>
      }
    </div>
  );
};

export default TestSessionsPage;