import React from 'react';

import euLogo from '../../assets/images/partners/eu-logo.jpg';
import cispLogo from '../../assets/images/partners/cisp-logo.jpg';
import grootsLogo from '../../assets/images/partners/groots-kenya-logo.jpg';
import natureKenyaLogo from '../../assets/images/partners/nature-kenya-logo.jpg';
import procasurLogo from '../../assets/images/partners/new_procasur.png';
import ndmaLogo from '../../assets/images/partners/ndma-logo.png';

export type PartnersProps = {
  className?: string;
  imageClassName?: string;
};

const Partners: React.FC<PartnersProps> = ({ className, imageClassName }) => (
  <div className={className}>
    <a href='https://www.eeas.europa.eu/delegations/kenya_en' target='_blank'><img src={euLogo} alt="Co-funded by the European Union" className={imageClassName} /></a>
    <a href='https://www.ndma.go.ke/' target='_blank'><img src={ndmaLogo} alt="NDMA - National Drought Management Authority - Committed to ending drought emergencies" className={imageClassName} /></a>
    <a href='https://developmentofpeoples.org/en?fbclid=IwAR3b1tjvXMDZHt1Fq7a3pYQBqqy8KT1QwAkr99YvZyDw6sSaHHfWLiffL7k' target='_blank'><img src={cispLogo} alt="CISP - Comitato internazionale per Sviluppo dei Popoli" className={imageClassName} /></a>
    <a href='https://grootskenya.org/' target='_blank'><img src={grootsLogo} alt="Groots Kenya" className={imageClassName} /></a>
    <a href='https://naturekenya.org/' target='_blank'><img src={natureKenyaLogo} alt="Nature Kenya - The East Africa natural history society" className={imageClassName} /></a >
    <a href='https://procasur.org/en/home/' target='_blank'><img src={procasurLogo} alt="Procasur Africa - Harvesting Innovation Spreading Opportunities" className={imageClassName} /></a >
  </div>
);

export default Partners;