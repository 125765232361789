import { Actions, Resources, Roles, VerifiedAction } from "./rbac.types"

export enum Tenants {
  CISP = 'cisp',
}

type RolePermissions = {
  [resource in Resources]: VerifiedAction<resource>[];
};

type TenantConfig = {
  [role in Roles]: RolePermissions;
};

export type RBACConfig = {
  [k in Tenants]: TenantConfig;
};

const SystemAdmin: RolePermissions = {
  // User roles
  [Resources.SYSTEM_ADMIN]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
  [Resources.PARTNERS]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
  [Resources.DONORS]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
  [Resources.LOCAL_AUTHORITIES]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
  [Resources.USER]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
  // Apps
  [Resources.BACKOFFICE]: [Actions.LAUNCH],
  // Other entities
  [Resources.CHART]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
  [Resources.FILE]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
  [Resources.POST]: [Actions.CREATE, Actions.UPDATE, Actions.DELETE, Actions.FEATURE],
  [Resources.USER_GROUP]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
  [Resources.TEAM]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
  [Resources.ROOM]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE, Actions.OPEN, Actions.CLOSE],
  [Resources.QUESTIONNAIRE_MODEL]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
  [Resources.QUESTIONNAIRE]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
  [Resources.TEST_SESSION]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE, Actions.CLOSE],
};

const CISP: TenantConfig = {
  [Roles.SYSTEM_ADMIN]: SystemAdmin,
  [Roles.PARTNERS]: {
    // User roles
    [Resources.SYSTEM_ADMIN]: [],
    [Resources.PARTNERS]: [Actions.CREATE, Actions.READ],
    [Resources.DONORS]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
    [Resources.LOCAL_AUTHORITIES]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
    [Resources.USER]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
    // Apps
    [Resources.BACKOFFICE]: [Actions.LAUNCH],
    // Other entities
    [Resources.CHART]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
    [Resources.FILE]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
    [Resources.POST]: [Actions.CREATE, Actions.UPDATE, Actions.DELETE, Actions.FEATURE],
    [Resources.USER_GROUP]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
    [Resources.TEAM]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
    [Resources.ROOM]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE, Actions.OPEN, Actions.CLOSE],
    [Resources.QUESTIONNAIRE_MODEL]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
    [Resources.QUESTIONNAIRE]: [Actions.READ, Actions.DELETE],
    [Resources.TEST_SESSION]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE, Actions.CLOSE],
  },
  [Roles.DONORS]: {
    // User roles
    [Resources.SYSTEM_ADMIN]: [],
    [Resources.PARTNERS]: [],
    [Resources.DONORS]: [Actions.CREATE, Actions.READ],
    [Resources.LOCAL_AUTHORITIES]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
    [Resources.USER]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
    // Apps
    [Resources.BACKOFFICE]: [],
    // Other entities
    [Resources.CHART]: [Actions.READ],
    [Resources.FILE]: [Actions.READ],
    [Resources.USER_GROUP]: [],
    [Resources.TEAM]: [],
    [Resources.ROOM]: [],
    [Resources.POST]: [],
    [Resources.QUESTIONNAIRE_MODEL]: [Actions.READ],
    [Resources.QUESTIONNAIRE]: [Actions.CREATE, Actions.READ, Actions.UPDATE],
    [Resources.TEST_SESSION]: [],
  },
  [Roles.LOCAL_AUTHORITIES]: {
    // User roles
    [Resources.SYSTEM_ADMIN]: [],
    [Resources.PARTNERS]: [],
    [Resources.DONORS]: [],
    [Resources.LOCAL_AUTHORITIES]: [Actions.CREATE, Actions.READ],
    [Resources.USER]: [Actions.CREATE, Actions.READ, Actions.UPDATE, Actions.DELETE],
    // Apps
    [Resources.BACKOFFICE]: [],
    // Other entities
    [Resources.CHART]: [Actions.READ],
    [Resources.FILE]: [Actions.READ],
    [Resources.POST]: [],
    [Resources.USER_GROUP]: [],
    [Resources.TEAM]: [],
    [Resources.ROOM]: [],
    [Resources.QUESTIONNAIRE_MODEL]: [Actions.READ],
    [Resources.QUESTIONNAIRE]: [Actions.CREATE, Actions.READ, Actions.UPDATE],
    [Resources.TEST_SESSION]: [],
  },
  [Roles.USER]: {
    // User roles
    [Resources.SYSTEM_ADMIN]: [],
    [Resources.PARTNERS]: [],
    [Resources.DONORS]: [],
    [Resources.LOCAL_AUTHORITIES]: [],
    [Resources.USER]: [Actions.READ, Actions.READ, Actions.UPDATE],
    // Apps
    [Resources.BACKOFFICE]: [],
    // Other entities
    [Resources.CHART]: [Actions.READ],
    [Resources.FILE]: [Actions.READ],
    [Resources.POST]: [],
    [Resources.USER_GROUP]: [],
    [Resources.TEAM]: [],
    [Resources.ROOM]: [Actions.READ],
    [Resources.QUESTIONNAIRE_MODEL]: [Actions.READ],
    [Resources.QUESTIONNAIRE]: [Actions.CREATE, Actions.READ, Actions.UPDATE],
    [Resources.TEST_SESSION]: [],
  }
};

export const RBAC: RBACConfig = {
  [Tenants.CISP]: CISP,
};

export type AuthenticatedUser = {
  tenantId?: Tenants;
  roles?: Roles[];
}