import { MenuConfig, Route } from '../routes.types';
import VideoConference from './VideoConference';

export const jitsiTestRoutePaths = {
  root: '/rooms/:roomId',
};

export const routes: Route[] = [
  {
    path: jitsiTestRoutePaths.root,
    component: VideoConference,
    exact: true,
  }
];

export const menus: MenuConfig = [];
