import { Participant, ParticipantRoles } from "../../api/room/types";
import { VideoConferenceClient } from '.';
import { VideoConferenceContextProps } from '..';

export enum RoomTypes {
  CONFERENCE = 'conference', // One or more panelists speaking to many attendees
  CHATROOM = 'chatroom', // Two or more people, everyone is specing
}

export enum RoomCommands {
  SHOW_UP = 'showUp',
  RAISE_HAND = 'raiseHand',
  LOWER_HAND = 'lowerHand',
  TOGGLE_AUDIO = 'toggleAudio',
  TOGGLE_VIDEO = 'toggleVideo',
  COMMIT_MEDIA_STATUS = 'commitMediaStatus',
  BROADCAST_AUDIENCE = 'broadcastAudience',
  BROADCASE_PANELISTS = 'broadcastPanelists',
  PARTICIPANTS_CHANGED = 'participantsChanged',
  HANG_UP = 'hangUp',
}

export type ToolbarButtons =
  'microphone' |
  'camera' |
  'closedcaptions' |
  'desktop' |
  'fullscreen' |
  'fodeviceselection' |
  'hangup' |
  'profile' |
  'info' |
  'chat' |
  'recording' |
  'livestreaming' |
  'etherpad' |
  'sharedvideo' |
  'settings' |
  'raisehand' |
  'videoquality' |
  'filmstrip' |
  'invite' |
  'feedback' |
  'stats' |
  'shortcuts' |
  'tileview' |
  'videobackgroundblur' |
  'download' |
  'help' |
  'mute-everyone' |
  'e2ee';

export type ClientSettings = {
  interfaceConfigOverwrite: {
    // filmStripOnly: boolean;
    SHOW_JITSI_WATERMARK: boolean;
    SHOW_WATERMARK_FOR_GUESTS: boolean;
    SHOW_BRAND_WATERMARK: boolean;
    BRAND_WATERMARK_LINK: string;
    SHOW_POWERED_BY: boolean;
    SHOW_DEEP_LINKING_IMAGE: boolean;
    GENERATE_ROOMNAMES_ON_WELCOME_PAGE: boolean;
    DISPLAY_WELCOME_PAGE_CONTENT: boolean;
    DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: boolean;
    APP_NAME: string;
    NATIVE_APP_NAME: string;
    PROVIDER_NAME: string;
    LANG_DETECTION: boolean;
    INVITATION_POWERED_BY: boolean;

    TOOLBAR_BUTTONS: Partial<ToolbarButtons>[],

    CLOSE_PAGE_GUEST_HINT: boolean;
    SHOW_PROMOTIONAL_CLOSE_PAGE: boolean;
    MOBILE_APP_PROMO: boolean;

    // RANDOM_AVATAR_URL_PREFIX: boolean;
    // RANDOM_AVATAR_URL_SUFFIX: boolean;
    FILM_STRIP_MAX_HEIGHT: number;
    VERTICAL_FILMSTRIP: boolean;
  },
  configOverwrite: {
    openBridgeChannel: boolean;
    enableWelcomePage: boolean;
    remoteVideoMenu: {
      disableKick: true
    },
    disableRemoteMute: boolean;
    startWithVideoMuted?: boolean;
    startWithAudioMuted?: boolean;
    requireDisplayName: boolean;
    prejoinPageEnabled: boolean;
    disableInviteFunctions: boolean;
    doNotStoreRoom: boolean;
    enableNoAudioDetection: boolean;
    enableNoisyMicDetection: boolean;
  }
};

export type RoomConfig = {
  clientSettings: ClientSettings;
  hasControlPanel: boolean;
};

export type RoomTypeConfig = {
  [r in ParticipantRoles]: RoomConfig;
};

export type RoomsConfig = {
  [t in RoomTypes]: RoomTypeConfig;
};

export type RoomStateSetter = React.Dispatch<React.SetStateAction<VideoConferenceContextProps | undefined>>;

export type JitsiMessage = {
  data: {
    senderInfo: {
      jid: string;
      id: string;
    },
    eventData: {
      name: string; // the name of the datachannel event: `endpoint-text-message`
      text: string;
    }
  }
};

export type JitsiDevice = {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
};

export type JitsiAvailableDevices = {
  audioInput: JitsiDevice[];
  audioOutput: JitsiDevice[];
  videoInput: JitsiDevice[];
};

export type JitsiCurrentDevices = {
  audioInput: JitsiDevice;
  audioOutput: JitsiDevice;
  videoInput: JitsiDevice;
};

export type RoomCommandHandler = (command: string, data: string[]) => void;

export enum VideoConferenceClientEvents {
  onCommandReceived = 'onCommandReceived',
  onToggleEvent = 'onToggleEvent',
}

export type VideoConferenceClientEventsTypes = {
  [VideoConferenceClientEvents.onCommandReceived]: RoomCommandHandler;

  /**
   * This callback works with 3-way states for both audio or video.
   * Will pass true or false if the request is to enable or disable audio or video.
   * Will pass undefined if no change is required.
   */
  [VideoConferenceClientEvents.onToggleEvent]: (audio: boolean | undefined, video: boolean | undefined, client: VideoConferenceClient) => void; 
}

export type VideoConferenceClientEvent = keyof VideoConferenceClientEventsTypes;

type VerifiedType<R extends VideoConferenceClientEvent> = Pick<VideoConferenceClientEventsTypes, R>;
export type VideoConferenceClientEventFunction<T extends VideoConferenceClientEvents> = VerifiedType<T>[T];

export type ParticipantUpdater = (x: Participant) => Participant;