import gql from 'graphql-tag';

export const CREATE_ROOM = gql`
  mutation CreateRoom($room: RoomCreateInput!) {
    createRoom(input: $room) {
      id
      name
      type
      participants { 
        user {
          id
          firstName
          lastName
        }
        role
        status
        metadata {
          clientId
          online
          joined
          handRaised
          audioMuted
          videoMuted
        }
      } 
      openingAt 
      closingAt 
      status
      createdAt 
    }
  }
`;

export const UPDATE_ROOM = gql`
  mutation UpdateRoom($input: RoomUpdateInput!) {
    updateRoom(input: $input) {
      id
      name
      type
      participants { 
        user {
          id
          firstName
          lastName
        }
        role
        status
        metadata {
          clientId
          online
          joined
          handRaised
          audioMuted
          videoMuted
        }
      } 
      openingAt 
      closingAt 
      status
      createdAt 
    }
  }
`;

export const DELETE_ROOM = gql`
  mutation DeleteRoom($id: ObjectId!) {
    deleteRoom(id: $id) {
      id
      name
    }
  }
`;

export const OPEN_ROOM = gql`
  mutation OpenRoom($id: ObjectId!) {
    openRoom(id: $id) {
      id
      name
      participants {
        user {
          id
          firstName
          lastName
        }
        role
        status
        metadata {
          clientId
          online
          joined
          handRaised
          audioMuted
          videoMuted
        }
      }
    }
  }
`;

export const CLOSE_ROOM = gql`
  mutation CloseRoom($id: ObjectId!) {
    closeRoom(id: $id) {
      id
      name
    }
  }
`;

export const JOIN_ROOM = gql`
  mutation JoinRoom($input: RoomJoinInput!) {
    joinRoom(input: $input) {
      id
      participants {
        user {
          id
          firstName
          lastName
        }
        role
        status
        metadata {
          clientId
          online
          joined
          handRaised
          audioMuted
          videoMuted
        }
      }
    }
  }
`;

export const LEAVE_ROOM = gql`
  mutation LeaveRoom($id: ObjectId!) {
    leaveRoom(id: $id) {
      id
      participants {
        user {
          id
          firstName
          lastName
        }
        role
        status
        metadata {
          clientId
          online
          joined
          handRaised
          audioMuted
          videoMuted
        }
      }
    }
  }
`;

export const RAISE_HAND_IN_ROOM = gql`
  mutation RaiseHandInRoom($id: ObjectId!) {
    raiseHandInRoom(id: $id) {
      id
      participants {
        user {
          id
          firstName
          lastName
        }
        role
        status
        metadata {
          clientId
          online
          joined
          handRaised
          audioMuted
          videoMuted
        }
      }
    }
  }
`;

export const LOWER_HAND_IN_ROOM = gql`
  mutation LowerHandInRoom($id: ObjectId!) {
    lowerHandInRoom(id: $id) {
      id
      participants {
        user {
          id
          firstName
          lastName
        }
        role
        status
        metadata {
          clientId
          online
          joined
          handRaised
          audioMuted
          videoMuted
        }
      }
    }
  }
`;

export const TOGGLE_IN_ROOM = gql`
  mutation ToggleInRoom($input: RoomToggleInput!) {
    toggleInRoom(input: $input) {
      id
      participants {
        user {
          id
          firstName
          lastName
        }
        role
        status
        metadata {
          clientId
          online
          joined
          handRaised
          audioMuted
          videoMuted
        }
      }
    }
  }
`;

export const CREATE_ROOM_TEST_SESSION = gql`
  mutation RoomCreateTestSession($input: RoomCreateTestSessionInput!) {
    roomCreateTestSession(input: $input) {
      id
      testSessions {
        testSession {
          id
          name
          questionnaireModelId
        }
      }
    }
  }
`;