export enum ProcessEnvs {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
};

export const socketConfig = {
  PORT: 5000
};

export const clientEnv = {
  MAIN_URL: process.env.REACT_APP_URL,
  TENANT_ID: process.env.REACT_APP_TENANT_ID,
  GRAPHQL_API: process.env.REACT_APP_GRAPHQL_API || `http://${window.location.host.replace(/3000/g, '4000')}/`,
  GOOGLE_MEASUREMENT_ID_CODE: process.env.REACT_APP_GOOGLE_MEASUREMENT_ID_CODE || 'UA',
  GCP_STORAGE: process.env.REACT_APP_GCP_STORAGE,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN!,
  AUTH0_REDIRECT_URI: `${window.location.protocol}//${window.location.host}/`,
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE!,
  AUTH0_NAMESPACE: process.env.REACT_APP_AUTH0_NAMESPACE,
  FIREBASE_BASE_URL: process.env.REACT_APP_FIREBASE_BASE_URL,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  SENTRY_DNS: process.env.REACT_APP_SENTRY_DNS,
  FORCE_TOURS: process.env.REACT_APP_FORCE_TOURS,
};
