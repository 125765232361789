import './Footer.css';

import { Col, Divider, Row, Space } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { appMenus } from '../../routes';
import { useAuth } from '../../auth0';
import { hasPermissions } from '../../rbac';
import Partners from './Partners';
import { getYear } from '../../utils/dates';

const FooterView: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const menuItems = appMenus.sort((a, b) => a.order < b.order ? -1 : 0).filter(x => !x.route.permissions || hasPermissions(user, x.route.permissions));
  const CURRENT_YEAR = getYear();

  return (
    <div className='footer-container'>
      <footer>
        <Row align="middle">
          <Col xs={24} sm={4}>
            <nav>
              {menuItems.map((item, index) => (
                <a
                  key={`footer-menu-${index}`}
                  href={item.route.path}
                  onClick={(e) => { history.push(item.route.path); e.preventDefault && e.preventDefault(); }}
                >
                  {t(item.label)}{index !== appMenus.length - 1 ? <Divider type='vertical' /> : null}
                </a>
              ))}
            </nav>
          </Col>
          <Col xs={24} sm={16}>
            <Partners className="partner-logos-container" imageClassName="partner-logo" />
          </Col>
          <Col xs={24} sm={4} className="footer-right">
            {t('common.footer.privacyPolicy')}
          </Col>
        </Row>
        <Row align="middle">
          <Col xs={24} sm={12}>
            <span className="copyright">Copyright &copy; {CURRENT_YEAR}. All rights reserved.</span>
          </Col>
        </Row>
      </footer>
    </div>
  )
}

export default FooterView;
