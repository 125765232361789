import gql from 'graphql-tag';

export const GET_GROUPS = gql`
  query getGroups($first: Int, $cursor: String) {
    userGroups(first: $first, after: $cursor) {
      edges {
        node {
          id
          name
          description
          items {
            user {
              id
              firstName
              lastName
            }
          }
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        count
      }
    }
  }
`;
