import gql from 'graphql-tag';

export const CREATE_USER = gql`
  mutation CreateUser($user: UserCreateInput!) {
    createUser(input: $user) {
      id,
      email,
      firstName,
      lastName,
      createdAt,
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($user: UserUpdateInput!) {
    updateUser(input: $user) {
      id,
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ObjectId!) {
    deleteUser(id: $id) {
      id,
      firstName,
      lastName,
    }
  }
`;

export const REINVITE_USER = gql`
  mutation RemindUserToCompleteAccount($id: ObjectId!) {
    remindAccountCompletionToUser(id: $id)
  }
`;
