import './NotFound.css';

import { Button, Empty, Layout } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';

const HomeButton = ({ history }) => (
  <Button
    type='primary'
    className="not-found-action" 
    onClick={() => history.push('/')}
  >
    <HomeOutlined /> Go to Homepage
  </Button>
);

const BackButton = ({ history }) => (
  <Button
    type='primary'
    className="not-found-action" 
    onClick={() => history.goBack()}
  >
    <ArrowLeftOutlined /> Go back
  </Button>
);

export const NotFound = () => {
  const history = useHistory();

  const isFirstPage = history.length <= 2;

  return (
    <Layout className="not-found-container">
      <Empty
        className="not-found"
        description="Sorry, we can't find the content you were looking for."
      />
      <p>This URL does not point to anything on this website.</p>
      { isFirstPage ? <HomeButton history={history} /> : <BackButton history={history} /> }
    </Layout>
  );
};

export default NotFound;