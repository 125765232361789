import gql from 'graphql-tag';

export const CREATE_USER_GROUP = gql`
  mutation CreateUserGroup($input: UserGroupCreateInput!) {
    createUserGroup(input: $input) {
      id,
      name,
      description
      createdAt,
    }
  }
`;

export const UPDATE_USER_GROUP = gql`
  mutation UpdateUserGroup($input: UserGroupUpdateInput!) {
    updateUserGroup(input: $input) {
      id,
      name,
      description
      createdAt,
    }
  }
`;

export const DELETE_USER_GROUP = gql`
  mutation DeleteUserGroup($id: ObjectId) {
    deleteUserGroup(id: $id) {
      id,
      name,
      createdAt,
    }
  }
`;