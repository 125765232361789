import { all } from 'redux-saga/effects';

import { watchAuthSaga } from './authentication';
import { watchPostSaga } from './post';

export default function* rootSaga() {
    yield all([
        watchAuthSaga(),
        watchPostSaga()
    ])
  }