import { Carousel } from 'antd';
import React from 'react';

import "./Carousel.css";
import { images } from './images';

export type CarouselProps = {
  height: number;
}

const makeBG = (image: any) => ({
  backgroundImage: `linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.3)), url(${image})`,
});

const CarouselPanel: React.FC<CarouselProps> = ({ height }) => {
  if (!images || images.length === 0) {
    return null;
  }

  const toCarouselImage = (image: any, index: number) => (
    <div key={`carousel-image-${index}`}>
      <div className="carousel-image" style={{ ...makeBG(image), height}} />
    </div>
  );

  return (
    <Carousel className="carousel" effect="fade" autoplaySpeed={5000} autoplay>
      {images.map(toCarouselImage)}
    </Carousel>
  );
};

export default CarouselPanel;