import { EventEmitter } from 'events';

import sjcl from 'sjcl';

import graphqlClient from '../../api/graphqlClient';
import { GET_ROOM, JOIN_ROOM, LEAVE_ROOM, LOWER_HAND_IN_ROOM, RAISE_HAND_IN_ROOM, TOGGLE_IN_ROOM, UPDATE_ROOM } from '../../api/room';
import { Participant, ParticipantRoles, ParticipantUpdateInput, Room, RoomStatus, RoomTypes } from '../../api/room/types';
import { VideoConferenceContextProps } from '../components';
import { config as roomConfig } from '../config';
import { getRoomParticipant, isPanelist } from './room';
import { JitsiAvailableDevices, JitsiCurrentDevices, JitsiMessage, ParticipantUpdater, RoomCommands, RoomConfig, RoomStateSetter, VideoConferenceClientEventFunction, VideoConferenceClientEvents } from './types';

declare global {
  interface Window { JitsiMeetExternalAPI: any; }
}

window.JitsiMeetExternalAPI = window.JitsiMeetExternalAPI || {};

type RoomCommand = (sender: Participant, tokens: string[]) => void;
type Commands = { [x in RoomCommands]: RoomCommand };

export class VideoConferenceClient {
  private containerId: string;
  private user: any;
  private client: any;
  private clientId: string;
  private config: RoomConfig;
  private setState: RoomStateSetter;
  private readonly eventEmitter: EventEmitter;
  
  room: Room;
  participant: Participant;
  isOnline: boolean;
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  hasPreJoinScreen: boolean;
  hasControlPanel: boolean;
  hasHandRaised: boolean;

  constructor(
    containerId: string,
    room: Room,
    user: any,
    setState: RoomStateSetter
  ) {
    if (!room || !room.nonce) {
      throw new Error('Unable to create VideoConferenceClient: Invalid Room.');
    }

    this.containerId = containerId;
    this.room = room;
    this.user = user;
    this.setState = setState;

    this.eventEmitter = new EventEmitter();

    const participant = getRoomParticipant(room, user);
    if (!participant) {
      throw new Error('Unable to create VideoConferenceClient: You are not part of this Room.');
    }
    this.participant = participant;

    this.isOnline = false;

    const type = room.type || RoomTypes.CONFERENCE;
    const role = participant.role || ParticipantRoles.AUDIENCE;
    this.config = roomConfig[type][role];

    // Set room media initial state to what the config for the room says
    this.isAudioMuted = !!this.config.clientSettings.configOverwrite.startWithAudioMuted;
    this.isVideoMuted = !!this.config.clientSettings.configOverwrite.startWithVideoMuted;

    this.hasControlPanel = this.config.hasControlPanel;
    this.hasHandRaised = participant.metadata ? participant.metadata.handRaised : false;
    this.hasPreJoinScreen = this.config.clientSettings.configOverwrite.prejoinPageEnabled;

    this.commitMediaMuteState();

    this.setupClient();
    this.setupState();
  }

  private setupClient(directJoin?: boolean) {
    if (typeof directJoin !== 'undefined') {
      this.config.clientSettings.configOverwrite.prejoinPageEnabled = !directJoin;
    }

    this.hasPreJoinScreen = this.config.clientSettings.configOverwrite.prejoinPageEnabled;

    if (typeof this.client !== 'undefined') {
      this.client.dispose();
    }

    console.warn('VC.CLIENT', "NEW CLIENT", this.hasPreJoinScreen);

    this.client = new window.JitsiMeetExternalAPI(
      'meet.jit.si', {
      ...this.config.clientSettings,
      parentNode: document.querySelector(`#${this.containerId}`),
      roomName: this.room.id,
      userInfo: {
        email: this.user.id,
        displayName: this.participant ?  `${this.participant.user.firstName} ${this.participant.user.lastName}` : this.user.name,
        avatarURL: this.user.id,
      }
    });

    try {
      // Remove watermarks
      var x = document.getElementsByTagName("iframe")[0].contentWindow;
      const watermarks = x && x.document.querySelectorAll<HTMLDivElement>('.watermark');
      if (watermarks && watermarks.length) {
        watermarks.forEach(x => {
          x.style.display = 'none !important';
          x.style.visibility = 'hidden';
        });
      }
    } catch (e) {}

    // Set Room name
    this.client.executeCommand('subject', this.room.name);

    // Set User name
    // window.setTimeout(() => {
    //   this.client.executeCommand('displayName', `${this.participant.user.firstName} ${this.participant.user.lastName}`);
    // }, 3000);

    // Add event listeners
    this.client.addEventListener('videoConferenceJoined', ({ id }) => {
      this.handleJoin(id);
    });
    this.client.addEventListener('videoConferenceLeft', () => this.handleLeave());

    this.client.addEventListener('participantJoined', ({ id }) => this.handleJitsiParticipantJoined(id));
    this.client.addEventListener('participantLeft', ({ id }) => this.handleJitsiParticipantLeft(id));

    this.client.addEventListener('endpointTextMessageReceived', (data: any) => {
      console.warn('VC.CLIENT', 'MESSAGE RECEIVED');

      this.handleCommandReceived(data);
    });

    this.client.addEventListener('audioMuteStatusChanged', ({ muted }) => this.handleJitsiAudioMuted(muted));
    this.client.addEventListener('videoMuteStatusChanged', ({ muted }) => this.handleJitsiVideoMuted(muted));
  }

  private setupState() {
    this.setState({
      room: this.room,
      participant: this.participant,
      isOnline: this.isOnline,
      isAudioMuted: this.isAudioMuted,
      isVideoMuted: this.isVideoMuted,
      hasPreJoinScreen: this.hasPreJoinScreen,
      hasControlPanel: this.hasControlPanel,
      hasHandRaised: this.hasHandRaised,

      leave: () => this.leave(),
      raiseHand: () => this.raiseHand(),
      lowerHand: () => this.lowerHand(),
      toggleHand: () => this.toggleHand(),
      toggleMedia: (userId: string, audio?: boolean | undefined, video?: boolean | undefined) => this.toggleMedia(userId, audio, video),
      setUserAudio: (userId: string, on: boolean) => this.setUserAudio(userId, on),
      setUserVideo: (userId: string, on: boolean) => this.setUserVideo(userId, on),
      setAudioMuted: (muted: boolean) => this.setAudioMuted(muted),
      setVideoMuted: (muted: boolean) => this.setVideoMuted(muted),
      setAudioVideoMuted: (muted: boolean) => this.setAudioVideoMuted(muted),
      cancelToggleRequest: () => this.cancelToggleRequest(),
      broadcastAudience: (command: string, data: string[]) => this.broadcastAudience(command, data),
      broadcastPanelists: (command: string, data: string[]) => this.broadcastPanelists(command, data),
      changeParticipants: (participants: ParticipantUpdateInput[]) => this.changeParticipants(participants),
      addEventListener: <T extends VideoConferenceClientEvents>(name: T, fn: VideoConferenceClientEventFunction<T>) => this.addEventListener(name, fn),
      removeEventListener: <T extends VideoConferenceClientEvents>(name: T, fn: VideoConferenceClientEventFunction<T>) => this.removeEventListener(name, fn),
    })
  }

  private getClietId(userId: string) {
    const res = this.room.participants.find(x => x.user.id === userId);

    if (!res || !res.metadata) {
      return undefined;
    }

    return res.metadata.clientId;
  }

  private sendCommand(userId: string | null, command: RoomCommands, data?: string[]){
    if (!this.room.nonce) {
      throw new Error('Invalid room');
    }

    const cmd = `${command}${data ? `|${data.join('|')}`: ''}`;
    const res = sjcl.encrypt(this.room.nonce, cmd);
    const message = JSON.stringify(res);

    if (userId) {
      const clientId = this.getClietId(userId);

      if (clientId && this.clientId !== clientId) {
        console.warn('VC.CLIENT', 'SEND USER COMMAND', clientId, command, data);
        this.client.executeCommand('sendEndpointTextMessage', clientId, message);
      } else {
        console.warn('VC.CLIENT', 'DID NOT SEND USER COMMAND TO', clientId, this.clientId);
      }
    } else {
      for (const participant of this.room.participants) {
        if (isPanelist(participant)) {
          console.warn('VC.CLIENT', 'SEND COMMAND TO PANELIST', participant.metadata?.clientId, message);
          this.client.executeCommand('sendEndpointTextMessage', participant.metadata?.clientId, message);
        }
      }
    }
  }

  private sendCommandToAudience(command: RoomCommands, data?: string[]) {
    for (const participant of this.room.participants) {
      if (participant.role === ParticipantRoles.AUDIENCE && participant.metadata) {
        this.sendCommand(participant.user.id, command, data);
      }
    }
  }

  private sendCommandToEveryone(command: RoomCommands, data?: string[]) {
    for (const participant of this.room.participants) {
      this.sendCommand(participant.user.id, command, data);
    }
  }

  private async handleCommandReceived(data: JitsiMessage) {
    console.warn('VC.CLIENT', 'RECEIVE COMMAND', data);
    if (!this.room.nonce) {
      throw new Error('Invalid room');
    }

    const { eventData, senderInfo } = data.data;

    const encData = JSON.parse(eventData.text);
    const content = sjcl.decrypt(this.room.nonce, encData);

    const tokens = content.split('|');
    const command = tokens.splice(0, 1)[0];

    console.warn('VC.CLIENT', 'PARTICIPANTS', this.room.participants, senderInfo.id);

    const sender = this.room.participants.find(x => x.metadata && x.metadata.clientId === senderInfo.id);
    
    console.warn('VC.CLIENT', 'COMMAND DATA', command, tokens, sender);

    const _commands: Commands = {
      [RoomCommands.SHOW_UP]: (sender: Participant, tokens: string[]) => this.handleShowUp(sender, tokens),
      [RoomCommands.RAISE_HAND]: (sender: Participant, tokens: string[]) => this.handleRaiseHand(sender, tokens),
      [RoomCommands.LOWER_HAND]: (sender: Participant, tokens: string[]) => this.handleLowerHand(sender, tokens),
      [RoomCommands.TOGGLE_AUDIO]: (sender: Participant, tokens: string[]) => this.handleToggleAudio(sender, tokens),
      [RoomCommands.TOGGLE_VIDEO]: (sender: Participant, tokens: string[]) => this.handleToggleVideo(sender, tokens),
      [RoomCommands.COMMIT_MEDIA_STATUS]: (sender: Participant, tokens: string[]) => this.handleCommitMediaStatus(sender, tokens),
      [RoomCommands.BROADCAST_AUDIENCE]: (sender: Participant, tokens: string[]) => this.handleBroadcastAudience(sender, tokens),
      [RoomCommands.BROADCASE_PANELISTS]: (sender: Participant, tokens: string[]) => this.handleBroadcastPanlists(sender, tokens),
      [RoomCommands.PARTICIPANTS_CHANGED]: (sender: Participant, tokens: string[]) => this.handleParticipantsChanged(sender, tokens),
      [RoomCommands.HANG_UP]: (sender: Participant, tokens: string[]) => this.handleHangup(sender, tokens),
    };

    if (_commands[command]) {
      _commands[command](sender, tokens);
    } else {
      console.warn('VC.CLIENT', 'INVALID COMMAND', command, data);
    }
  }

  private async forceRoomUpdate() {
    console.warn('VC.CLIENT', 'forceRoomUpdate');

    const room = await graphqlClient.query({
      query: GET_ROOM,
      variables: {
        id: this.room.id,
      },
      fetchPolicy: 'network-only',
    });

    console.warn('VC.CLIENT', 'forceRoomUpdate', room);

    this.handleUpdate(room.data.room);
  }

  join() {
    this.setupClient(true);
  }

  leave() {
    this.client.executeCommand('hangup');

    return true;
  }

  // Expose internal client EventEmitter
  addClientEventListener(name: string, fn: () => void) {
    this.client.addEventListener(name, fn);
  }
  removeClientEventListener(name: string, fn: () => void) {
    this.client.removeEventListener(name, fn);
  }

  // Expose event emitter
  addEventListener<T extends VideoConferenceClientEvents>(name: T, fn: VideoConferenceClientEventFunction<T>) {
    this.eventEmitter.addListener(name, fn);
  }
  removeEventListener<T extends VideoConferenceClientEvents>(name: T, fn: VideoConferenceClientEventFunction<T>) {
    this.eventEmitter.removeListener(name, fn);
  }

  hangup() {
    for (const participant of this.room.participants) {
      this.sendCommand(participant.user.id, RoomCommands.HANG_UP);
    }

    this.client.executeCommand('hangup');
  
    return true;
  };

  private handleJoin(id: string) {
    console.warn('VC.CLIENT', 'handleJoin');

    this.clientId = id;
    this.isOnline = true;

    graphqlClient.mutate({
      mutation: JOIN_ROOM,
      variables: {
        input: {
          id: this.room.id,
          clientId: this.clientId,
        }
      }
    }).then((data) => {
      this.handleUpdate(data.data.joinRoom);

      // The next command is very likely to fail due to the still not opened channel data bug.
      // console error: modules/API/API.js] <Object.send-endpoint-text-message>:  Failed sending endpoint text message Error: No opened channel
      // So we try waiting a bit to see if we can handle things properly

      const _this = this;
      window.setTimeout(() => {
        _this.sendCommandToEveryone(RoomCommands.SHOW_UP, [this.user.userId, id]);
      }, 2000);
    });
  }

  private handleLeave() {
    graphqlClient.mutate({
      mutation: LEAVE_ROOM,
      variables: {
        id: this.room.id,
      }
    }).then((data) => {
      this.handleUpdate(data.data.leaveRoom);
    });
  }

  raiseHand() {
    this.hasHandRaised = true;

    graphqlClient.mutate({
      mutation: RAISE_HAND_IN_ROOM,
      variables: {
        id: this.room.id,
      }
    }).then((data) => {
      this.handleUpdate(data.data.raiseHandInRoom);
      this.sendCommand(null, RoomCommands.RAISE_HAND);
    });
  }

  lowerHand() {
    this.hasHandRaised = false;

    graphqlClient.mutate({
      mutation: LOWER_HAND_IN_ROOM,
      variables: {
        id: this.room.id,
      }
    }).then((data) => {
      this.handleUpdate(data.data.lowerHandInRoom);
      this.sendCommand(null, RoomCommands.LOWER_HAND);
    });
  }

  toggleHand() {
    if(!this.participant.metadata) {
      return;
    }

    return this.participant.metadata.handRaised ? this.lowerHand() : this.raiseHand();
  }

  async getAvailableDevices(): Promise<JitsiAvailableDevices> {
    return await this.client.getAvailableDevices();
  }

  async getCurrentDevices(): Promise<JitsiCurrentDevices> {
    return await this.client.getCurrentDevices();
  }

  toggleMedia(userId: string, audio?: boolean, video?: boolean) {
    let participant = this.room.participants.find(x => x.user.id === userId);
    if (!participant || !participant.metadata) {
      throw new Error(`Invalid Toggle command on an non-existing user ${userId}.`);
    }

    console.warn('VC.CLIENT', 'TOGGLE', audio, video, participant.metadata);

    const audioMuted = typeof audio !== 'undefined' ? !audio : participant.metadata.audioMuted;
    const videoMuted = typeof video !== 'undefined' ? !video : participant.metadata.videoMuted;

    console.warn('VC.CLIENT', 'TOGGLE Audio Muted', audioMuted, 'Video Muted', videoMuted);

    const oldMetadata = { ...participant?.metadata };

    const fn: ParticipantUpdater = (x: Participant) => {
      if (x.user.id === userId && x.metadata) {
        x.metadata = {
          ...x.metadata,
          audioMuted,
          videoMuted,
        };
      }
  
      return x;
    };

    this.updateStateInMemory(fn);

    graphqlClient.mutate({
      mutation: TOGGLE_IN_ROOM,
      variables: {
        input: {
          id: this.room.id,
          userId,
          audio: !audioMuted,
          video: !videoMuted,
        }
      }
    }).then((data) => {
      const newParticipant = data.data.toggleInRoom.participants.find(x => x.user.id === userId);
      const newMetadata = newParticipant ? newParticipant.metadata : undefined;

      this.handleUpdate(data.data.toggleInRoom);

      if (oldMetadata && newMetadata) {
        console.warn('VC.CLIENT', 'TOGGLE 3', newMetadata.audioMuted !== oldMetadata.audioMuted, newMetadata.videoMuted !== oldMetadata.videoMuted);
        if (newMetadata.audioMuted !== oldMetadata.audioMuted){
          this.sendCommand(userId, RoomCommands.TOGGLE_AUDIO, [!newMetadata.audioMuted ? 'true' : 'false']);
        }
        if(newMetadata.videoMuted !== oldMetadata.videoMuted) {
          this.sendCommand(userId, RoomCommands.TOGGLE_VIDEO, [!newMetadata.videoMuted ? 'true' : 'false']);
        }
      }
    });
  }

  setUserAudio(userId: string, on: boolean) {
    if (!isPanelist(this.participant)) {
      throw new Error('You cannot toggle audio for other Users.');
    }

    return this.toggleMedia(userId, on, undefined);
  }

  setUserVideo(userId: string, on: boolean) {
    if (!isPanelist(this.participant)) {
      throw new Error('You cannot toggle video for other Users.');
    }

    return this.toggleMedia(userId, undefined, on);
  }

  private commitMediaMuteState() {
    console.warn('VC.CLIENT', 'COMMIT MUTE STATE');
    graphqlClient.mutate({
      mutation: TOGGLE_IN_ROOM,
      variables: {
        input: {
          id: this.room.id,
          userId: this.participant.user.id,
          audio: !this.isAudioMuted,
          video: !this.isVideoMuted,
        }
      }
    }).then((data) => {
      console.warn('VC.CLIENT', 'commitMediaMuteState', data);
      this.handleUpdate(data.data.toggleInRoom);
      this.sendCommand(null, RoomCommands.COMMIT_MEDIA_STATUS, [
        this.isAudioMuted ? 'true' : 'false',
        this.isVideoMuted ? 'true' : 'false'
      ]);
    });
  }

  setAudioMuted(muted: boolean) {
    if (this.isAudioMuted !== muted) {
      this.client.executeCommand('toggleAudio');
      this.isAudioMuted = muted;
    }

    this.commitMediaMuteState();
  }

  setVideoMuted(muted: boolean) {
    if (this.isVideoMuted !== muted) {
      this.client.executeCommand('toggleVideo');
      this.isVideoMuted = muted;
    }

    this.commitMediaMuteState();
  }

  setAudioVideoMuted(muted: boolean) {
    if (this.isAudioMuted !== muted) {
      this.client.executeCommand('toggleAudio');
      this.isAudioMuted = muted;
    }

    if (this.isVideoMuted !== muted) {
      this.client.executeCommand('toggleVideo');
      this.isVideoMuted = muted;
    }

    this.commitMediaMuteState();
  }

  cancelToggleRequest(){
    this.commitMediaMuteState();
  }

  private handleJitsiAudioMuted(muted: boolean) {
    this.isAudioMuted = muted;
    this.commitMediaMuteState();
  }

  private handleJitsiVideoMuted(muted: boolean) {
    this.isVideoMuted = muted;
    this.commitMediaMuteState();
  }

  broadcastAudience(command: string, data: string[]) {
    this.sendCommandToAudience(RoomCommands.BROADCAST_AUDIENCE, [command, ...data]);
  }

  broadcastPanelists(command: string, data: string[]) {
    this.sendCommand(null, RoomCommands.BROADCASE_PANELISTS, [command, ...data]);
  }
  
  changeParticipants(participantsInput: ParticipantUpdateInput[]) {
    const removed = this.room.participants.filter(x => !participantsInput.find(p => p.userId === x.user.id));
    
    for (const participant of removed) {
      this.sendCommand(participant.user.id, RoomCommands.HANG_UP);
    }

    console.warn('VC.CLIENT', 'COMMIT PARTICIPANT CHANGE, removed =', removed);
    graphqlClient.mutate({
      mutation: UPDATE_ROOM,
      variables: {
        input: {
          id: this.room.id,
          participants: participantsInput,
        }
      }
    }).then((data) => {
      console.warn('VC.CLIENT', 'updateRoom', data);
      this.handleUpdate(data.data.updateRoom);
      
      this.sendCommandToEveryone(RoomCommands.PARTICIPANTS_CHANGED);
    });
  }

  private updateStateInMemory(fn: ParticipantUpdater) {
    if (!this.room || !this.room.participants) {
      return;
    }

    this.room = {
      ...this.room,
      participants: this.room.participants.map(fn),
    };

    const participant = getRoomParticipant(this.room, this.user);
    if (!participant) {
      window.location.reload();
      // throw new Error('Unable to handle Room: You are not part of this Room.');
      console.warn("VC.CLIENT", "Unable to handle Room: You are not part of this Room.");
      return;
    }

    this.participant = participant;
    this.hasControlPanel = isPanelist(participant);
    this.hasHandRaised = participant.metadata ? participant.metadata.handRaised : false;

    this.setState((prevState: VideoConferenceContextProps) => ({
      ...prevState,

      room: this.room,
      participant: this.participant,
      isOnline: this.isOnline,
      isAudioMuted: this.isAudioMuted,
      isVideoMuted: this.isVideoMuted,
      hasPreJoinScreen: this.hasPreJoinScreen,
      hasControlPanel: this.hasControlPanel,
      hasHandRaised: this.hasHandRaised,
    }));
  }

  private cmdCallback(command: RoomCommands, data?: string[]) {
    console.warn('VC.CLIENT CMD CALLBACK', command, data);
    this.eventEmitter.emit(VideoConferenceClientEvents.onCommandReceived, command, data || []);
  }

  private handleUpdate(room: Room) {
    if (room.id !== this.room.id) {
      throw new Error('Invalid Room, cannot update state.');
    }

    // Handle Hang Up
    if (room.status === RoomStatus.CLOSED) {
      this.handleHangup(this.participant, []);
    }

    this.room = {
      ...this.room,
      ...room,
    };

    const participant = getRoomParticipant(room, this.user);
    if (!participant) {
      window.location.reload();
      // throw new Error('Unable to handle Room: You are not part of this Room.');
      console.warn("VC.CLIENT", "Unable to handle Room: You are not part of this Room.");
      return;
    }

    this.participant = participant;
    this.hasControlPanel = isPanelist(participant);
    this.hasHandRaised = participant.metadata ? participant.metadata.handRaised : false;

    this.setState((prevState: VideoConferenceContextProps) => ({
      ...prevState,

      room: this.room,
      participant: this.participant,
      isOnline: this.isOnline,
      isAudioMuted: this.isAudioMuted,
      isVideoMuted: this.isVideoMuted,
      hasPreJoinScreen: this.hasPreJoinScreen,
      hasControlPanel: this.hasControlPanel,
      hasHandRaised: this.hasHandRaised,
    }));
  }

  private handleShowUp(sender: Participant, tokens: string[]){
    console.warn('VC.CLIENT', 'RECEIVED SHOWUP');
    const fn: ParticipantUpdater = (x: Participant) => {
      if (x.user.id === tokens[0]) {
        console.warn('VC.CLIENT', 'SETTING ID', tokens[1], ' to usre',x.user.firstName, x.user.lastName);
        x.metadata = {
          ...(x.metadata || {
            joined: true,
            online: true,
            handRaised: false,
            audioMuted: true,
            videoMuted: false
          }),
          clientId: tokens[1],
        };
      }
  
      return x;
    };

    this.updateStateInMemory(fn);
  }

  private handleRaiseHand(sender: Participant, tokens: string[]){
    if (!isPanelist(this.participant)) {
      return;
    }

    const { user } = sender;

    const fn: ParticipantUpdater = (x: Participant) => {
      if (x.user.id === sender.user.id && x.metadata) {
        x.metadata = {
          ...x.metadata,
          handRaised: true,
        };
      }
  
      return x;
    };

    this.updateStateInMemory(fn);

    this.cmdCallback(RoomCommands.RAISE_HAND, [`${user.id}-hand`, `${user.firstName} ${user.lastName}`]);
  }

  private handleLowerHand(sender: Participant, tokens: string[]){
    if (!isPanelist(this.participant)) {
      return;
    }

    const { user } = sender;

    const fn: ParticipantUpdater = (x: Participant) => {
      if (x.user.id === sender.user.id && x.metadata) {
        x.metadata = {
          ...x.metadata,
          handRaised: false,
        };
      }
  
      return x;
    };

    this.updateStateInMemory(fn);

    this.cmdCallback(RoomCommands.LOWER_HAND, [`${user.id}-hand`, `${user.firstName} ${user.lastName}`]);
  }

  private handleToggleAudio(sender: Participant, tokens: string[]) {
    if (!isPanelist(sender)) {
      return;
    }

    const hasAudio = tokens[0] === 'true';

    const fn: ParticipantUpdater = (x: Participant) => {
      if (x.user.id === this.user.userId && x.metadata) {
        x.metadata = {
          ...x.metadata,
          audioMuted: !hasAudio,
        };
      }
  
      return x;
    };

    this.updateStateInMemory(fn);

    this.eventEmitter.emit(VideoConferenceClientEvents.onToggleEvent, hasAudio, undefined, this);
  }

  private handleToggleVideo(sender: Participant, tokens: string[]) {
    if (!isPanelist(sender)) {
      return;
    }

    const hasVideo = tokens[0] === 'true';

    const fn: ParticipantUpdater = (x: Participant) => {
      if (x.user.id === this.user.userId && x.metadata) {
        x.metadata = {
          ...x.metadata,
          videoMuted: !hasVideo,
        };
      }
  
      return x;
    };

    this.updateStateInMemory(fn);

    this.eventEmitter.emit(VideoConferenceClientEvents.onToggleEvent, undefined, hasVideo, this);
  }

  private handleCommitMediaStatus(sender: Participant, tokens: string[]) {
    if (!sender) {
      return;
    }

    const fn: ParticipantUpdater = (x: Participant) => {
      if (x.user.id === sender.user.id && x.metadata) {
        x.metadata = {
          ...x.metadata,
          audioMuted: tokens[0] === 'true',
          videoMuted: tokens[1] === 'true',
        };
      }
  
      return x;
    };

    this.updateStateInMemory(fn);
  }

  private handleBroadcastAudience(sender: Participant, tokens: string[]){
    if (!isPanelist(sender)) {
      return;
    }

    const [broadcastedCommand, ...data] = tokens;

    this.cmdCallback(broadcastedCommand as RoomCommands, data);
  }

  private handleBroadcastPanlists(sender: Participant, tokens: string[]){
    if (!isPanelist(sender)) {
      return;
    }

    const [broadcastedCommand, ...data] = tokens;

    this.cmdCallback(broadcastedCommand as RoomCommands, data);
  }

  private handleParticipantsChanged(sender: Participant, tokens: string[]){
    if (isPanelist(sender)) {
      return;
    }

    this.forceRoomUpdate()
      .then(() => {
        this.cmdCallback(RoomCommands.PARTICIPANTS_CHANGED);
      });
  }

  private handleHangup(sender: Participant, tokens: string[]){
    if (!isPanelist(sender)) {
      return;
    }

    this.client.executeCommand('hangup');

    this.cmdCallback(RoomCommands.HANG_UP);
  }

  private handleJitsiParticipantJoined(clientId: string) {
    console.warn('VC.CLIENT', 'handleJitsiParticipantJoined', clientId);

    // This command gets here way before the Room is updated by the entering client on the db.
    // Therefore, we must wait a bit to be sure the operation has finished before getting back the room.

    const _this = this;
    window.setTimeout(() => {
      _this.forceRoomUpdate();
    }, 3000);

    // if (!this.room || !this.room.participants) {
    //   return;
    // }

    // const fn: ParticipantUpdater = (x: Participant) => {
    //   if (x.metadata && x.metadata.clientId === clientId) {
    //     x.metadata = {
    //       ...x.metadata,
    //       online: true,
    //       joined: true,
    //       handRaised: false,
    //       audioMuted: false,
    //       videoMuted: false,
    //     };
    //   }
  
    //   return x;
    // };

    // this.updateStateInMemory(fn);
  }

  private handleJitsiParticipantLeft(clientId: string) {
    console.warn('VC.CLIENT', 'handleJitsiParticipantLeft', clientId);

    // This command gets here way before the Room is updated by the entering client on the db.
    // Therefore, we must wait a bit to be sure the operation has finished before getting back the room.

    const _this = this;
    window.setTimeout(() => {
      _this.forceRoomUpdate();
    }, 3000);

    // if (!this.room || !this.room.participants) {
    //   return;
    // }

    // const fn: ParticipantUpdater = (x: Participant) => {
    //   if (x.metadata && x.metadata.clientId === clientId) {
    //     x.metadata = {
    //       ...x.metadata,
    //       online: false,
    //       joined: false,
    //       handRaised: false,
    //       audioMuted: false,
    //       videoMuted: false,
    //     };
    //   }
  
    //   return x;
    // };

    // this.updateStateInMemory(fn);
  }
}
