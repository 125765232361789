import { Input } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import TagSelect from '../../../../shared/TagSelect';
import Logo from '../../../../shared/assets/images/ekep-logo.svg';
import './PostBanner.less';
import CarouselPanel from './Carousel';

const { Search } = Input;

type PostBannerProps = {
  handleSearch: (value: string, tags?: string[]) => void,
  loading?: boolean
}

const PostBanner: React.FC<PostBannerProps> = ({
  handleSearch,
  loading
}) => {
  const { t } = useTranslation();
  const [search, setSearchFilter] = React.useState<string>('');
  const [tags, setTagsFilter] = React.useState<string[] | undefined>(undefined);

  React.useEffect(() => {
    if (search.trim() || tags) {
      handleSearch(search, tags)
    }

  }, [search, tags]);

  return (
    <div className="post-banner-container">
      <CarouselPanel height={400} />
      <div className="post-banner">
        <img
          src={Logo}
          className="post-banner-logo"
          alt='REBUID - Community Resilience Building in Livelihood and Disaster risk management'
        />
        <div className="post-search-container">
          <Input.Group className="post-search">
            <Search
              className="post-banner-search"
              placeholder={t('posts.props.search')}
              loading={loading}
              onChange={(e) => setSearchFilter(e.target.value)}
              size='large'
              allowClear
            />
            <TagSelect
              size="large"
              placeholder={t('post.searchByTag')}
              onChange={setTagsFilter}
              allowAdd={false}
            />
          </Input.Group>
        </div>
      </div>
    </div>
  )
}

export default PostBanner;
