import { createContext } from 'react';

import { Participant, Room } from '../../api/room/types';
import { VideoConferenceClient } from '../domain';

export type VideoConferenceContextProps = {
  room: Room;
  participant: Participant;
  isOnline: boolean;
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  hasPreJoinScreen: boolean;
  hasControlPanel: boolean;
  hasHandRaised: boolean;

  leave: VideoConferenceClient['leave'];
  raiseHand: VideoConferenceClient['raiseHand'];
  lowerHand: VideoConferenceClient['lowerHand'];
  toggleHand: VideoConferenceClient['toggleHand'];
  toggleMedia: VideoConferenceClient['toggleMedia'];
  setUserAudio: VideoConferenceClient['setUserAudio'];
  setUserVideo: VideoConferenceClient['setUserVideo'];
  setAudioMuted: VideoConferenceClient['setAudioMuted'];
  setVideoMuted: VideoConferenceClient['setVideoMuted'];
  setAudioVideoMuted: VideoConferenceClient['setAudioVideoMuted'];
  cancelToggleRequest: VideoConferenceClient['cancelToggleRequest'];
  broadcastAudience: VideoConferenceClient['broadcastAudience'];
  broadcastPanelists: VideoConferenceClient['broadcastPanelists'];
  changeParticipants: VideoConferenceClient['changeParticipants'];
  addEventListener: VideoConferenceClient['addEventListener'];
  removeEventListener: VideoConferenceClient['removeEventListener'];
}

export const VideoConferenceContext = createContext<VideoConferenceContextProps | undefined>(undefined);