import './TagsBrowser.less';

import { Affix, Button, Skeleton, Tag as TagLabel } from 'antd';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TagOutlined } from '@ant-design/icons';

import { GET_TAGS, Tag, Tags } from '../../../../../api/tag';
import Loader from '../../../../../shared/Components/Loader';
import { useUser } from '../../../../../shared/useUser';

const TagsBrowser: React.FC = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const { data, loading, refetch } = useQuery<Tags>(GET_TAGS, { fetchPolicy: 'cache-and-network' });
  const [tags, setTags] = React.useState<Tag[]>([]);
  const history = useHistory();

  React.useEffect(() => {
    refetch();
  }, [user]);

  React.useEffect(() => {
    if (data && data.tags.length) {
      setTags(data.tags.sort((a, b) => a.postsCount > b.postsCount ? -1 : 0))
    }
  }, [data]);

  const renderSkeleton = () => {
    return [1, 1, 1, 1, 1].map((x, index) => (
      <Skeleton key={index} paragraph={false} active />
    ))
  }

  const renderTag = (tag: Tag) => (
    <div
      key={tag.name}
      className="post-tag"
      onClick={() => history.push('/posts/tag/' + tag.name)}
    >
      <TagOutlined className="post-tag-icon" />
      <div className="post-tag-link">
        <a className='post-tag-text' href="#">{tag.name}</a>
        <TagLabel className="post-tag-label">
          {t(tag.postsCount === 1 ?
            'posts.tags.onePost' :
            `posts.tags.postsCount`, { count: tag.postsCount })}
        </TagLabel>
      </div>
    </div>
  );

  if (data && data.tags.length === 0) {
    return null;
  }

  return (
    <div>
      {t('posts.tags.title')}
      {loading && renderSkeleton()}
      { tags.map(renderTag)}
    </div>
  );
};

export default TagsBrowser;