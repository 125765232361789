import gql from 'graphql-tag';

export const GET_USER_ROOMS = gql`
query getUserRooms($input: UserRoomsInput!) {
  userRooms(input: $input) {
      id
      name
      type
      participants { 
        user {
          firstName
          lastName
        }
        role
      } 
      openingAt 
      closingAt 
      status
  }
}
`;

export const GET_ALL_ROOMS = gql`
query rooms($cursor: String, $status: [RoomStatuses]) {
  rooms(first: 50, after: $cursor, status: $status) {
    edges {
      node {
        id
        name
        type
        participants { 
          user { 
            id
            email
            firstName
            lastName
          }
          status
          role 
        } 
        openingAt 
        closingAt 
        status 
        createdAt 
        updatedAt
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      count
    }
  }
}
`;

export const GET_ROOM = gql`
query getRoom($id: ObjectId!) {
  room(id: $id) {
    id
    name
    type
    participants { 
      user {
        id
        firstName
        lastName
      } 
      status
      role 
      metadata {
        clientId
        online
        joined
        handRaised
        audioMuted
        videoMuted
      }
    } 
    openingAt 
    closingAt 
    status
    nonce
    createdAt 
    updatedAt
  }
}
`;

export const GET_ROOM_TEST_SESSSIONS = gql`
query getRoomTestSessions($id: ObjectId!) {
  room(id: $id) {
    testSessions {
      testSession {
        id
        name
        completed
        questionnaireModel {
          id
          title
        }
      }
    }
  }
}
`;

export const GET_ROOMS_REPORT = gql`
  query getRoomsReport($createdFrom: String!, $createdTo: String!) {
    roomsReport(createdFrom: $createdFrom, createdTo: $createdTo) {
      total
      newTotal
      conference
      chatroom
    }
  }
`;