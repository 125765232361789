import './VideoConference.css';

import { Button, Modal, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ScriptLoader from 'react-script-loader-hoc';

import { AudioOutlined, FlagFilled, FlagOutlined, LogoutOutlined, PhoneOutlined, VideoCameraOutlined } from '@ant-design/icons';

import { CLOSE_ROOM, Room, RoomStatus, RoomTypes } from '../../../api/room';
import { useAuth } from '../../../auth0';
import { adminRoutePaths } from '../../../routes/Admin/AdminRoutes';
import { roomRoutePaths } from '../../../routes/Admin/Room/RoomRoutes';
import { RoomCommands, VideoConferenceClient, VideoConferenceClientEvents, isPanelist as isRoomPanelist, isPanelist } from '../../domain';
import ClosedVideoConference from '../ClosedVideoConference';
import ForbiddenVideoConference from '../ForbiddenVideoConference';
import ScheduledVideoConference from '../ScheduledVideoConference';
import { VideoConferenceContext, VideoConferenceContextProps } from '../VideoConferenceContext';
import Multiline from '../../../shared/Multiline';

export type VideoConferenceProps<T = {}> = {
  room?: Room;
  onCommandReceived?: (command: string, data: string[]) => void;
};

type MediaPermissionModal = {
  audio: boolean | undefined;
  video: boolean | undefined;
  tKey: string;
};

const containerId = 'jitsi-container';

const VideoConferenceInner: React.FC<VideoConferenceProps> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const { room, onCommandReceived } = props;

  const { user } = useAuth();
  const history = useHistory();

  const [client, setClient] = useState<VideoConferenceClient | undefined>(undefined);
  const [state, setState] = useState<VideoConferenceContextProps>();
  const [hasError, setError] = useState<boolean>(false);
  const [mediaPermission, openMediaPermissionModal] = useState<MediaPermissionModal>();
  const [showCloseConfirm, setShowCloseConfirm] = React.useState<boolean>(false);
  const [showLeaveConfirm, setShowLeaveConfirm] = React.useState<boolean>(false);

  const [closeRoom] = useMutation(CLOSE_ROOM);

  const handleCloseRoom = useCallback(() => {
    if (!room || !client) {
      return;
    }

    setShowCloseConfirm(false);

    client.hangup();

    closeRoom({
      variables: {
        id: room.id,
      }
    }).then(() => {
      history.push(adminRoutePaths.root + roomRoutePaths.root);
    });
  }, [closeRoom, room, client, history]);

  const handleLeaveRoom = useCallback(() => {
    if (!room || !client) {
      return;
    }

    setShowLeaveConfirm(false);

    client.leave();
  }, [closeRoom, room, client]);

  const handleCommandReceived = useCallback((command: string, data: string[]) => {
    if (typeof onCommandReceived === 'function'){
      onCommandReceived(command, data);
    }

    if (command === RoomCommands.RAISE_HAND) {
      notification.info({
        key: data[0],
        message: `Hand Raised by ${data[1]}!`,
        description: 'Give speech with the Mic icon near his name on the right panel.',
        placement: 'bottomLeft',
        duration: 0,
      });
    } else if (command === RoomCommands.LOWER_HAND) {
      notification.close(data[0]);
    }
  }, [onCommandReceived]);

  const handleRequestToggleMedia = useCallback((audio: boolean | undefined, video: boolean | undefined, client: VideoConferenceClient) => {
    console.error('handleRequestToggleMedia', audio, video, client);

    if (!client) {
      return;
    }

    // No real change to be made on audio
    if (client.isAudioMuted && audio === false) {
      audio = undefined;
    }

    // No real change to be made on video
    if (client.isVideoMuted && video === false) {
      video = undefined;
    }

    // Nothing 
    if (typeof audio === 'undefined' && typeof video === 'undefined') {
      return;
    }

    const hadAudio = !client.isAudioMuted ? 'A' : '';
    const hadVideo = !client.isVideoMuted ? 'V' : '';
    let wantsAudio = typeof audio === 'undefined' ? '' : (audio ? 'A' : 'NA');
    let wantsVideo = typeof video === 'undefined' ? '' : (video ? 'V' : 'NV');

    /**
     * Resulting message keys:
     * - hadwantsA: had nothing, wants to activate audio
     * - hadwantsV: had nothing, wants to activate video
     * - hadwantsAV: had nothing, wants to activate audio and video
     * - hadAwantsNA: had audio, wants to mute it
     * - hadVwantsNV: had video, wants to mute it
     * - hadAwantsV: had audio, wants also video
     * - hadVwantsA: had video, wants also audio
     * - hadAVwantsNA: had audio/video, wants to mute audio
     * - hadAVwantsNV: had audio/video, wants to mute video
     * - hadAVwantsNANV: had audio/video, wants to mute both
     */
    const tKey = `videoconf.had${hadAudio}${hadVideo}wants${wantsAudio}${wantsVideo}`;

    if (wantsAudio === 'NA' && wantsVideo === 'NV') {
      notification.info({
        key: 'av',
        message: t('videoconf.didNANV'),
        placement: 'bottomLeft',
        duration: 3,
      });

      client.setAudioMuted(true);
      client.setVideoMuted(true);

      return;
    } else if (wantsAudio === 'NA') {
      notification.info({
        key: 'av',
        message: t('videoconf.didNA'),
        placement: 'bottomLeft',
        duration: 3,
      });

      client.setAudioMuted(true);

      if(wantsVideo === '') {
        return;
      }
    } else if (wantsVideo === 'NV') {
      notification.info({
        key: 'av',
        message: t('videoconf.didNV'),
        placement: 'bottomLeft',
        duration: 3,
      });

      client.setVideoMuted(true);

      if(wantsAudio === '') {
        return;
      }
    }

    openMediaPermissionModal({
      tKey,
      audio,
      video,
    });
  }, [t]);

  const handleToggleAudio = useCallback((on: boolean) => {
    client?.setAudioMuted(!on);
    openMediaPermissionModal(undefined);
  }, [client]);

  const handleToggleVideo = useCallback((on: boolean) => {
    client?.setVideoMuted(!on);
    openMediaPermissionModal(undefined);
  }, [client]);

  const handleToggleAudioVideo = useCallback((on: boolean) => {
    client?.setAudioVideoMuted(!on);
    openMediaPermissionModal(undefined);
  }, [client]);

  const handleCancelToggle = useCallback(() => {
    client?.cancelToggleRequest();
    openMediaPermissionModal(undefined);
  }, [client]);

  const renderModal = () => {
    if (!mediaPermission) {
      return null;
    }

    const requestsAudioChange = typeof mediaPermission.audio !== 'undefined';
    const requestsVideoCHange = typeof mediaPermission.video !== 'undefined';

    return (
      <Modal
        title="Modal"
        visible={true}
        onCancel={() => handleCancelToggle()}
        footer={(
          <div>
            { requestsAudioChange && 
              <Button type='primary' onClick={() => handleToggleAudio(!!mediaPermission.audio)}>{t('videoconf.activateAudio')}</Button>
            }
            { requestsVideoCHange && 
              <Button type='primary' onClick={() => handleToggleVideo(!!mediaPermission.video)}>{t('videoconf.activateVideo')}</Button>
            }
            { requestsAudioChange && requestsVideoCHange && mediaPermission.audio === mediaPermission.video &&
              <Button type='primary' onClick={() => handleToggleAudioVideo(!!mediaPermission.audio)}>{t('videoconf.activateAudioVideo')}</Button>
            }
          </div>
        )}
      >{t(mediaPermission?.tKey)}</Modal>
    );
  };

  useEffect(() => {
    if(client) {
      return;
    }

    if (!room || !user || room.status !== RoomStatus.OPEN) {
      return;
    }

    // console.log('room', room);
    // console.log('user', user);

    try {
      const vcClient = new VideoConferenceClient(containerId, room, user, setState);

      vcClient.addEventListener(VideoConferenceClientEvents.onCommandReceived, handleCommandReceived);
      vcClient.addEventListener(VideoConferenceClientEvents.onToggleEvent, handleRequestToggleMedia);

      // on Close, just go back where you started
      vcClient.addClientEventListener('readyToClose', () => {
        history.goBack();
      });

      setClient(vcClient);
    } catch (err) {
      console.log('ERROR', err);
      setError(err);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, user]);

  if (room){
    if(room.status === RoomStatus.SCHEDULED) {
      return (
        <ScheduledVideoConference room={room} />
      );
    } else if (room.status === RoomStatus.CLOSED) {
      return (
        <ClosedVideoConference />
      );
    }
  }

  if (hasError) {
    return (
      <ForbiddenVideoConference />
    );
  }

  const isPanelist = state ? isRoomPanelist(state.participant) : false;

  const renderLeaveRoomAction = () => (
    <>
      <Button 
        className="leave-room action-button"
        type={(isPanelist || room?.type === RoomTypes.CHATROOM) ? "default" : "primary"}
        onClick={() => setShowLeaveConfirm(true)}
        icon={<LogoutOutlined />}
        danger
      >
        {t('videoconf.leave')}
      </Button>
      <Modal
        visible={showLeaveConfirm}
        title={t('common.confirm')}
        okText={t('videoconf.leaveConfirm')}
        okButtonProps={{ danger: true }}
        cancelText={t('common.cancel')}
        onOk={handleLeaveRoom}
        onCancel={() => setShowLeaveConfirm(false)}
      >
        <div className="before-close-container">
          {t('videoconf.confirmLeaving')}
        </div>
      </Modal>
    </>
  );

  const renderHandToggleAction = (state: VideoConferenceContextProps) => (
    <Button 
      className="toggle-hand action-button"
      onClick={() => state.toggleHand()}
      icon={!state.hasHandRaised ? <FlagOutlined /> : <FlagFilled />}
    >
      { state.hasHandRaised ? 'Lower hand' : 'Raise hand' }
    </Button>
  );

  const renderCloseAction = () => (
    <>
      <Button 
        onClick={() => setShowCloseConfirm(true)}
        className="close-room action-button"
        type="primary"
        icon={<LogoutOutlined />}
        danger
      >{t('videoconf.close')}</Button>
      <Modal
        visible={showCloseConfirm}
        title={t('common.confirm')}
        okText={t('videoconf.closeConfirm')}
        okButtonProps={{ danger: true }}
        cancelText={t('common.cancel')}
        onOk={handleCloseRoom}
        onCancel={() => setShowCloseConfirm(false)}
      >
        <div className="before-close-container">
          <strong style={{ color: "#ff4d4f" }}>{t('videoconf.closeWarning')}</strong>
          <br /><br />
          {t('videoconf.confirmClosing')}
        </div>
      </Modal>
    </>
  );

  return (
    <VideoConferenceContext.Provider value={state}>
      {/* <div id={containerId} style={{ display: client?.hasPreJoinScreen ? 'none' : 'flex' }}> */}
      <div id={containerId}>
        {renderModal()}
        {(state && !!state.participant && state.isOnline) && (
          <div className="room-actions">
            {(isPanelist || room?.type === RoomTypes.CHATROOM) && renderCloseAction()}
            {renderLeaveRoomAction()}
            {(!isPanelist && room?.type === RoomTypes.CONFERENCE) && renderHandToggleAction(state)}
          </div>
        )}
      </div>
      {/* { client?.hasPreJoinScreen && <JoinScreen client={client} />} */}
      { children }
    </VideoConferenceContext.Provider>
  )
};

export const VideoConference: React.FC<VideoConferenceProps> = ScriptLoader("https://meet.jit.si/external_api.js")(VideoConferenceInner);
