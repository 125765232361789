import { Auth0Client, Auth0ClientOptions } from "@auth0/auth0-spa-js";

export const createClient = async (options: Auth0ClientOptions, oldClient?: Auth0Client): Promise<Auth0Client> => {
  if (!options) {
    throw new Error('Auth0Provider clientOptions are missing!');
  }

  if (oldClient) {
    await destroyClient(oldClient);
  }

  const client = new Auth0Client(options);  
  return client;
};

export const destroyClient =  async (client: Auth0Client): Promise<void> => {
  client.logout();
};