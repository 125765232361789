import { Alert } from 'antd';
import * as React from 'react';
import { useLazyQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';

import { GET_USER_ROOMS, Room } from '../../api/room';
import { useAuth } from '../../auth0';
import { useUserDate } from '../useUserDate';

const HeaderAlert = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { formatDate } = useUserDate();

  const [nextMeeting, setNextMeeting] = React.useState<Room>();
  const [getUserRooms, { data }] = useLazyQuery<{ userRooms: Room[] }>(GET_USER_ROOMS, {
    variables: {
      input: {
        status: ['scheduled', 'open']
      }
    }
  });

  React.useEffect(() => {
    if (user) {
      getUserRooms();
    }

    const todayMeeting = data && data.userRooms
      .filter(room => {
        return room.status === 'open';
      });
      
    todayMeeting && setNextMeeting(todayMeeting[0]);
  }, [user, data, getUserRooms]);

  if (!user || !nextMeeting) {
    return null;
  }

  const formatedTime =  formatDate(nextMeeting.openingAt, 'llll');

  const JoinMeeting = () => {
    const text = <a href={`/rooms/${nextMeeting.id}`} onClick={() => history.push(`/rooms/${nextMeeting.id}`)}> {`You have a meeting in ${nextMeeting.name} on ${formatedTime}`}</a>;

    return text;
  }
  return (
    <Alert className="header-alert" message={<JoinMeeting />} type="info" showIcon closable />
  );
};

export default HeaderAlert;
