import React from 'react';

import { useAuth } from '../../auth0';
import Loader from '../../shared/Components/Loader';

export type LoginProps = {
};

const Login: React.FC<LoginProps> = () => {
  const auth = useAuth();

  React.useEffect(() => {
    if (auth.logout) {
      auth.logout();
    }

    if(auth.login) {
      auth.login();
    }
  }, [auth]);

  return (
    <Loader isLoading={true} />
  );
};

export default Login;
