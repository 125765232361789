import gql from 'graphql-tag';

export const GET_TEST_SESSIONS = gql`
  query getTestSessions($first: Int, $cursor: String, $search: String) {
    testSessions(first: $first, after: $cursor, search: $search) {
      edges {
        node {
          id
          name
          questionnaireModelId
          completed
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_TEST_SESSION = gql`
  query getTestSession($id: ObjectId!) {
    testSession(id: $id) {
      id
      name
      questionnaireModelId
      completed
    }
  }
`;

export const GET_TEST_SESSION_RESULTS = gql`
  query getTestSessionResults($id: ObjectId!) {
    getTestSessionResults(id: $id) {
      id
      name
      completed
      completion
      questionnaireModel {
        title
        items {
          id
          options {
            label
          }
        }
      }
      participants {
        user {
          id
          firstName
          lastName
        }
        completed
      }
      results {
        type
        questionId
        question
        answersCount
        answers {
          answer
          users {
            userId
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;

