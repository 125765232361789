import * as Admin from './Admin';
import * as Login from './Login';
import * as Post from './Post';
import * as AcceptInvite from './AcceptInvite';
import * as VideoConference from './VideoConference';

export const appRoutes = [
  ...Admin.routes,
  ...VideoConference.routes,
  ...Post.routes,
  ...Login.routes,
  ...AcceptInvite.routes,
];

export const appMenus = [
  ...Admin.menus,
  ...VideoConference.menus,
  ...Post.menus,
  ...Login.menus,
];

export const defaultPage = Post.postRoutePaths.root;
