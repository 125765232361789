import { Participant, ParticipantRoles, Room } from "../../api/room/types";
import { RoomTypes } from "./types";

export const getRoomType = (room: Room): RoomTypes | undefined => {
  if (!room.participants) {
    return undefined;
  }

  let panelists = 0;
  let attendees = 0;
  for (const participant of room.participants) {
    if (participant.role === ParticipantRoles.HOST || participant.role === ParticipantRoles.PANELIST) {
      panelists++;
    } else {
      attendees++;
    }
  }

  console.log('panelists, attendees',panelists, attendees, room);

  return room.type;
};

export const getRoomParticipant = (room: Room, user: any): Participant | undefined => {
  if (!room.participants) {
    return undefined;
  }

  return room.participants.find(x => x.user.id === user.userId);
};

export const isPanelist = (participant: Participant): boolean => {
  return participant.role === ParticipantRoles.PANELIST || participant.role === ParticipantRoles.HOST
};