import gql from 'graphql-tag';
import { clientEnv } from '../../config';

export const GET_USERS = gql`
  query getUsers($first: Int, $cursor: String, $search: String) {
    users(first: $first, after: $cursor, search: $search) {
      edges {
        node {
          id
          email
          firstName
          lastName
          locale
          roles
          timezone
          status
          metadata {
            lastInvitedAt
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        count
      }
    }
  }
`;

export const GET_LOGGED_USER = gql`
  query getUser($id: ObjectId) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      roles
      locale
      timezone
      createdAt
    }
  }
`;

export const GET_USERS_REPORT = gql`
  query getUsersReport($createdFrom: String!, $createdTo: String!) {
    usersReport(createdFrom: $createdFrom, createdTo: $createdTo) {
      total
      newTotal
      partners
      systemAdmin
      donors
      localAuthorities
      user
    }
  }
`;
