import { all, takeEvery } from 'redux-saga/effects';
import { getAuthDetailsAction } from './authentication.action';

function* authenticationDetailsSaga() {

}

function* watchAuthSaga() {
  yield all([
    yield takeEvery(getAuthDetailsAction.started,  authenticationDetailsSaga),
  ])
}

export { watchAuthSaga };

