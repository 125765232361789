import './HeaderEditor.css';

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_POSTS, Post } from '../../../../../api/post';
import SmartSelect from '../../../../../shared/SmartSelect/SmartSelect';

export type HeaderEditorProps = {
  isEditing: boolean;
  selectedPosts: Post[];
  children: ReactElement<any, any> | null;
  onPostChange: (post: Post | null) => void; 
};

const HeaderEditor: React.FC<HeaderEditorProps> = (props) => {
  const { isEditing, selectedPosts,  onPostChange } = props;

  const { t } = useTranslation();
  const [post, setPost] = React.useState<{ [id: string]: Post }>();
  const [disabled, setDisabled] = React.useState<{ [id: string]: Post }>();

  React.useEffect(() => {
    const disabled: { [id: string]: Post } = {};

    selectedPosts.forEach(x => {
      if(x && x.id) {
        disabled[x.id] = x;
      }
    });
    setDisabled(disabled);
  }, [selectedPosts])

  const handleSelect = React.useCallback((selectedPost: { [id: string]: Post }) => {
    setPost(selectedPost);
    onPostChange(selectedPost ? Object.values(selectedPost)[0] : null);
  }, [onPostChange, setPost]);

  if (!isEditing) {
    return props.children || null;
  }

  return (
    <div className="header-editor">
      {props.children}
      <div className="header-editor-modal" onClick={() => null}>
        <SmartSelect
          title={t('posts.choosePost')}
          query={GET_POSTS}
          queryVariables={{ published: true }}
          queryRoot='posts'
          renderItem={(item: Post) => `${item.title}`}
          selected={post as unknown as any[]}
          onSelect={handleSelect}
          disabled={disabled as unknown as any[]}
          block
        />
      </div>
    </div>
  )
};

export default HeaderEditor;