import * as React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../shared/Components/Loader';

const PostDetailsContainer = Loadable({
  loader: () => import('./PostDetails'),
  loading: () => <Loader isLoading={true} />,
});

export default PostDetailsContainer;
