import "./UsersPanel.css";

import { Avatar, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckOutlined, UserOutlined } from '@ant-design/icons';

import { TestSessionParticipant } from '../../api/testSession';
import { User } from '../../api/user';

export type UsersPanelProps = {
  users: (Partial<User> | TestSessionParticipant)[];
  answerUsers?: { userId: string }[];
  isVisible: boolean;
  className?: string;
  slim?: boolean;
  size?: 'large' | 'default' | 'small',
};

const UsersPanel: React.FC<UsersPanelProps> = (props) => {
  const {
    users,
    answerUsers,
    isVisible,
    className,
    slim,
    size,
  } = props;

  const { t } = useTranslation();

  const renderBullet = (user: Partial<User>) => (
    <div className="users-panel-user">
      <Avatar
        className="users-panel-avatar"
        icon={<UserOutlined />}
        size={size}
      />
      {user.firstName} {user.lastName}
    </div>
  );

  const isUser = (user: Partial<User> | TestSessionParticipant): user is Partial<User> => !(user as TestSessionParticipant).user;

  const renderUser = (user: Partial<User> | TestSessionParticipant | undefined, index: number) => {
    if (!user) {
      return null;
    }

    return (
      <div key={`user-${index}`} className={`users-panel-item${!slim ? ' spaced' : ''}`} style={{ height: isVisible ? 32 : 0 }}>
        {isUser(user) ? renderBullet(user) : renderBullet(user.user)}

        {!isUser(user) && (
          user.completed
          ? <span className="user-completed"><CheckOutlined /> {t(`testsession.completed`)}</span>
          : <span>{t(`testsession.ongoing`)}</span>
        )}
      </div>
    )
  };

  return (
    <Space
      size="small"
      direction='vertical'
      style={{ width: isVisible ? '100%' : '0%', height: isVisible ? 'auto' : '0pc' }}
      className={`users-panel${className ? ` ${className}` : ''} ${isVisible ? 'visible' : 'hidden'}`}
    >
      { answerUsers
        ? answerUsers.map((x, index) => {
          const user = users.find((u) => isUser(u) ? u.id === x.userId : u.user.id === x.userId);

          return renderUser(user, index)
        })
        : users && users.map(renderUser)
      }
      <div />
    </Space>
  );
};

export default UsersPanel;
