import './ForbiddenVideoConference.css';

import { Button, Empty, Layout } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { VideoCameraTwoTone } from '@ant-design/icons';
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';

const HomeButton = ({ history }) => (
  <Button
    type='primary'
    className="forbidden-videoconf-action" 
    onClick={() => history.push('/')}
    icon={<HomeOutlined />}
  >
    Go to Homepage
  </Button>
);

const BackButton = ({ history, onBackCallback }) => (
  <Button
    type='primary'
    className="forbidden-videoconf-action" 
    onClick={() => onBackCallback ? onBackCallback() : history.goBack()}
    icon={<ArrowLeftOutlined />}
  >
    Go back
  </Button>
);

export type RoomError = {
  errorTitle: string;
  errorDescription: string;
  onBackCallback?: () => void;
}

export type ForbiddenVideoConferenceProps = {
  error?: RoomError
}

export const ForbiddenVideoConference: React.FC<ForbiddenVideoConferenceProps> = ({ error }) => {
  const history = useHistory();

  const isFirstPage = history.length <= 2;

  return (
    <Layout className="forbidden-videoconf-container">
      <Empty
        image={<VideoCameraTwoTone />}
        className="forbidden-videoconf"
        description={error ? error.errorTitle : 'Sorry, you don\'t have permission to enter this Room.'}
      />
      <p>{ error ? error.errorDescription : 'Try asking the Panelists to add you or wait for a future room invitation.'}</p>
      { isFirstPage ? 
      <HomeButton history={history} /> : 
      <BackButton 
        history={history} 
        onBackCallback={error && error.onBackCallback && error?.onBackCallback} 
      /> }
    </Layout>
  );
};

export default ForbiddenVideoConference;