import gql from 'graphql-tag';

export const CLOSE_TEST_SESSION = gql`
  mutation CloseTestSession($id: ObjectId!) {
    closeTestSession(id: $id) {
      id
      name
      completed
    }
  }
`;
