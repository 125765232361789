import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as authActions from './authentication.action';

export type AuthState = {
    details: any | null
};

const INITIAL_STATE: AuthState = {
    details: null,
};

export const authReducer = reducerWithInitialState(INITIAL_STATE)
    .case(authActions.getAuthDetailsAction.done, (state, { result }): AuthState => ({ ...state, details: result, }));
