import './ScheduledVideoConference.css';

import { Button, Empty, Layout } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowLeftOutlined, HomeOutlined, VideoCameraTwoTone } from '@ant-design/icons';

import { Room } from '../../../api/room';
import { useUser } from '../../../shared/useUser';

export type ScheduledVideoConferenceProps = {
  room: Room;
};

const HomeButton = ({ history }) => (
  <Button
    type='primary'
    className="scheduled-videoconf-action" 
    onClick={() => history.push('/')}
    icon={<HomeOutlined />}
  >
    Go to Homepage
  </Button>
);

const BackButton = ({ history }) => (
  <Button
    type='primary'
    className="scheduled-videoconf-action" 
    onClick={() => history.goBack()}
    icon={<ArrowLeftOutlined />}
  >
    Go back
  </Button>
);

export const ScheduledVideoConference: React.FC<ScheduledVideoConferenceProps> = ({ room }) => {
  const { user } = useUser();
  const history = useHistory();

  const isFirstPage = history.length <= 2;

  let message = '';
  const opening = moment(room.openingAt);
  if (opening.isBefore()) {
    message = 'This Room will open soon.';
  } else {
    const a = user ? opening.tz(user.timezone).fromNow() : opening.fromNow();
    message = `This Room will open ${a}`;
  }

  return (
    <Layout className="scheduled-videoconf-container">
      <Empty
        image={<VideoCameraTwoTone />}
        className="scheduled-videoconf"
        description={message}
      />
      <p>Please be patient, page will update automatically.</p>
      { isFirstPage ? <HomeButton history={history} /> : <BackButton history={history} /> }
    </Layout>
  );
};

export default ScheduledVideoConference;