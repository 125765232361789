import { Roles } from '../../rbac';
import { Paginated } from '../types';

export enum UserStatuses {
  REGISTERED = 'registered',
  ACTIVE = 'active',
}

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  locale: string;
  timezone: string;
  roles: Roles[];
  status?: UserStatuses;
  createdAt: string;
  metadata: {
    lastInvitedAt: Date,
  }
};

export type PaginatedUsers = {
  users: Paginated<User>;
};