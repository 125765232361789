

import { EditOutlined } from '@ant-design/icons'
import { Actions, Resources } from '../../../rbac';

import { MenuConfig, Route } from '../../routes.types';
import AdminPostList from './AdminPostList';

export const adminPostRoutePaths = {
  root: '/post',
};

export const routes: Route[] = [
  {
    path: adminPostRoutePaths.root,
    component: AdminPostList,
    permissions: { [Resources.POST]: [Actions.CREATE], [Resources.SYSTEM_ADMIN]: [Actions.READ] }
  },
];

export const menus: MenuConfig = [
  {
    route: routes[0],
    label: 'post.props.title',
    icon: EditOutlined,
    order: 3,
  }
];
