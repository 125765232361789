import { ApolloError } from 'apollo-boost';
import { GraphQLError } from 'graphql';
import isArray from 'lodash/isArray';
import { useTranslation } from 'react-i18next';

export type RenderableError = {
  title: string;
  message?: string;
};

export type ErrorsContainerTKey = string | string[];

export type UseApolloErrors = {
  format: (error?: ApolloError, errorsContainerTKey?: ErrorsContainerTKey) => RenderableError[] | null;
};

export const useApolloErrors = (globalErrorsContainerTKey?: ErrorsContainerTKey): UseApolloErrors => {
  const { t, i18n } = useTranslation();

  const toKeyArray = (errorsContainerTKey: ErrorsContainerTKey): string[] => {
    if (isArray<string>(errorsContainerTKey)) {
      return errorsContainerTKey;
    } else {
      return [errorsContainerTKey];
    }
  };

  const findKey = (tkeys: string[], key: string): RenderableError | null => {
    for (const base of tkeys) {
      const finalKey = `${base}.${key}`;

      const titleKey = `${finalKey}.title`;
      if (i18n.exists(titleKey)) {
        const res: RenderableError = {
          title: titleKey
        };

        const messageKey = `${finalKey}.message`;
        if (i18n.exists(messageKey)) {
          res.message = messageKey;
        }

        return res;
      } else if (i18n.exists(finalKey)) {
        return {
          title: finalKey,
        };
      }
    }

    return null;
  };

  const makeError = (tkeys: string[], gqlError: GraphQLError): RenderableError => {
    const k = findKey(tkeys, gqlError.extensions?.code || 'genericError');
    if (!k) {
      return {
        title: gqlError.name,
        message: gqlError.message,
      };
    } else {
      return {
        title: t(k.title),
        message: k.message 
          ? t(k.message, {
              ...gqlError.extensions?.metadata,
              name: gqlError.name,
              message: gqlError.message,
            })
          : undefined,
      };
    }
  };

  const format = (error?: ApolloError, errorsContainerTKey?: ErrorsContainerTKey): RenderableError[] | null => {
    if (!error) {
      return null;
    }

    const tkey = errorsContainerTKey || globalErrorsContainerTKey;
    if (!tkey) {
      throw new Error('You must pass a errors container tkey to useApolloErrors or directly to the format function.');
    }

    // Let's make it easier for us now
    const tkeys = toKeyArray(tkey);

    const errors: RenderableError[] = [];

    // Network errors always comes first
    if (error.networkError) {
      const k = findKey(tkeys, 'networkError');
      if (!k) {
        errors.push({
          title: error.networkError.name,
          message: error.networkError.message,
        });
      } else {
        errors.push({
          title: t(k.title),
          message: k.message ? t(k.message, { name: error.networkError.name, message: error.networkError.message }) : undefined,
        });
      }
    }

    // Then process all the other errors, as returned by the server
    if(error.graphQLErrors) {
      for (const gqlError of error.graphQLErrors) {
        errors.push(
          makeError(tkeys, gqlError),
        );
      }
    }

    return errors;
  };

  return {
    format,
  };
};