import { BarChartOutlined } from '@ant-design/icons';
import { MenuConfig, Route } from '../../routes.types';
import UsageReportsDetails from './UsageReportsDetails';

export const usageReportsRoutePaths = {
  root: '/usageReports',
};

export const routes: Route[] = [
  {
    path: usageReportsRoutePaths.root,
    component: UsageReportsDetails,
  },
];

export const menus: MenuConfig = [
  {
    route: routes[0],
    label: 'usageReports.menuTitle',
    icon: BarChartOutlined,
    order: 5,
  }
];
