import gql from 'graphql-tag';

import { clientEnv } from '../../config';

export const GET_TAGS = gql`
  query getTags {
    tags(tenantId: "${clientEnv.TENANT_ID}") {
      name
      postsCount
    }
  }
`;
