import './ControlPanel.css';

import { Tabs } from "antd";
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AudioOutlined, CameraOutlined, StopOutlined, TeamOutlined, VideoCameraOutlined } from '@ant-design/icons';

import GuidedTour from '../../../shared/GuidedTour';
import { useVideoConference } from '../../../videoconf';
import ParticipantsPage from './ParticipantsPage';
import TestSessionsPage from './TestSessionsPage';

const { TabPane } = Tabs;

export type ControlPanelProps = {
};

const ControlPanel: React.FC<ControlPanelProps> = (props) => {
  const { t } = useTranslation();
  const videoConf = useVideoConference();
  const [activeKey, setActiveKey] = React.useState<string | undefined>(undefined);
  const [showEditMode, setShowEditMode] = React.useState<boolean | undefined>(undefined);
  const [showDemoMode, setShowDemoMode] = React.useState<boolean | undefined>(undefined);

  if (!videoConf || !videoConf.hasControlPanel) {
    return null;
  }

  return (
    <div className="videoconference-control-panel">
       <Tabs defaultActiveKey="participants" className="control-panel-tabs" activeKey={activeKey}>
        <TabPane tab={t('room.props.participants')} key="participants" className="control-panel-tab">
          <ParticipantsPage isEditMode={showEditMode} />
        </TabPane>
        <TabPane tab={t('room.props.testSessions')} key="testsessions" className="control-panel-tab" disabled={!videoConf.isOnline}>
          <TestSessionsPage isDemo={showDemoMode} />
        </TabPane>
      </Tabs>
      { videoConf.isOnline && <GuidedTour
        id='room-panelist-guide'
        title="Control your Room"
        message="Being a Panelist maks you able to manage a lot of things in the Room. Take a tour and discover what you can do."
        steps={[
          {
            selector: '#jitsi-container',
            content: <><p>This is the main Room panel. Try positioning your cursor(mouse) over the various controls to discover their feature.</p></>,
            position: 'center'
          },
          {
            selector: '.videoconference-control-panel',
            content: <><p>Here you see who participants of this Room.</p><p>In green, you have people currently connected; the others are shown in grey.</p><p>You can ask any connected Audience user to participate with audio and/or video with the  <AudioOutlined />  and  <VideoCameraOutlined />  icons near their name. Clicking again revokes their right to speak.</p></>,
            position: 'left',
            action: () => {
              setActiveKey('participants');
            }
          },
          {
            selector: '.videoconference-participants-edit',
            content: <p>You can edit Room participants while already inside the Room with the  <TeamOutlined /> Edit button.</p>,
            position: 'left',
            action: () => {
              setActiveKey('participants');
            }
          },
          {
            selector: '.videoconference-control-panel',
            content: <><p>The newly invited participants will directly receive the link for joining via email.</p><p>The removed ones will be taken out of the Room and they'll receive an email saying the Room has been cancelled.</p><p>Please remember that changes will take action immediately, even before pressing the  <TeamOutlined /> Done  button</p></>,
            position: 'left',
            action: () => {
              setActiveKey('participants');
              setShowEditMode(true);
            }
          },
          {
            selector: '.videoconference-control-panel',
            content: <><p>This page helps you send participants one or more Questionnaires to be filled.</p><p>To do that, you initiate a Test Session, which will collect all the answers in a convenient way for later review, You'll find results in Admin's area Test Results page.</p><p>We suggest including Room's name in Test Session name.</p></>,
            position: 'left',
            action: () => {
              setShowEditMode(undefined);
              setActiveKey('testsessions');
              setShowDemoMode(true);
            }
          },
          {
            selector: '.videoconference-questionnaires-tests-container',
            content: <><p>Here you can keep an eye on TestSession completion.</p><p>Test Sessions should automatically close when every Audience participant has given their answers; If something goes wrong you can use the  <StopOutlined /> Close  button to close it manually.</p></>
          },
          {
            selector: '.close-room',
            content: <><p>Close the Room with this button.</p><p><strong>Closing the Room means that everyone will be kicked out of the Room and it will be definitely closed.</strong></p><p>A closed Room cannot be reopened. If you just want to quit, close Videoconference window/tab in your browser.</p></>,
            position: 'bottom'
          },
          {
            content: <><p><strong>Please remember that you have to keep the Conference open to be connected.</strong></p><p>Please make sure to open eventual other Admin/Posts pages in other Tabs leaving this open it you don't want to loose connection with the Room.</p></>,
            position: 'left',
            action: () => {
              setActiveKey(undefined);
              setShowDemoMode(undefined);
            }
          },
        ]}
      />
      }
    </div>
  );
};

export default ControlPanel;