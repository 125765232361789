import './ClosedVideoConference.css';

import { Button, Empty, Layout } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { VideoCameraTwoTone } from '@ant-design/icons';
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';

const HomeButton = ({ history }) => (
  <Button
    type='primary'
    className="closed-videoconf-action" 
    onClick={() => history.push('/')}
    icon={<HomeOutlined />}
  >
    Go to Homepage
  </Button>
);

const BackButton = ({ history }) => (
  <Button
    type='primary'
    className="closed-videoconf-action" 
    onClick={() => history.goBack()}
    icon={<ArrowLeftOutlined />}
  >
    Go back
  </Button>
);

export const ClosedVideoConference = () => {
  const history = useHistory();

  const isFirstPage = history.length <= 2;

  return (
    <Layout className="closed-videoconf-container">
      <Empty
        image={<VideoCameraTwoTone />}
        className="closed-videoconf"
        description="Sorry, this Room is finished and has been closed."
      />
      <p>Please wait for a future room invitation or contact your panelists if you did not expect this.</p>
      { isFirstPage ? <HomeButton history={history} /> : <BackButton history={history} /> }
    </Layout>
  );
};

export default ClosedVideoConference;