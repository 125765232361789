import { compose, Middleware, Store } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { StoreConfiguration } from "./store";

const createStoreConfig = (): StoreConfiguration => {
  // Redux middleware that spits an error on you when you
  // try to mutate your state either inside a dispatch or between dispatches.
  const invariantMiddleware = reduxImmutableStateInvariant();

  // Additional dev middlewares
  const additionalMiddlewares: Middleware[] = [
    invariantMiddleware,
  ];

  // Cast Window to avoid type problems
  const _window = (window as any);

  // Add support for Redux DevTools browser plugin
  const enhancedCompose = _window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? _window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: true,
      })
    : compose;

  // Callback to support Webpack's HMR for reducers
  const webpackHMR = (store: Store) => {
    // Cast Module to avoid type problems
    const _module = (module as any);

    if (_module.hot) {
      _module.hot.accept('./reducers', () => {
        const nextReducer = require('./reducers').default;
        store.replaceReducer(nextReducer);
      });
    }
  };

  return {
    additionalMiddlewares,
    compose: enhancedCompose,
    callback: webpackHMR,
  };
};

export default createStoreConfig;