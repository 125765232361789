import './TagSelect.css';

import { Button, Divider, Input, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React from 'react';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';

import { GET_TAGS, Tag } from '../../api/tag';
import { useAuth } from '../../auth0';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const { Option } = Select;

export type TagSelectProps<T = {}> = {
  tags?: string[];
  initialTags?: string[];
  onChange?: (tags: string[]) => void;
  value?: SelectProps<string[]>['value'];
  placeholder?: string;
  allowAdd?: boolean;
  size?: SizeType;
};

const TagSelect: React.FC<TagSelectProps> = (props) => {
  const {
    initialTags,
    value,
    tags: externalTags,
    placeholder,
    onChange,
    allowAdd,
    size
  } = props;

  const { t } = useTranslation();

  const { user } = useAuth();
  const [getTags, tagsQuery] = useLazyQuery<{ tags: Tag[] }>(GET_TAGS, { fetchPolicy: 'cache-and-network' });
  const [internalTags, setInternalTags] = React.useState<string[] | undefined>();
  const [tagName, setTagName] = React.useState<string>();

  const tags = externalTags || internalTags || [];

  React.useEffect(() => {
    if (tagsQuery.called) {
      tagsQuery.refetch();
    } else {
      getTags();
    }
  }, [user]);

  React.useEffect(() => {
    if (initialTags) {
      setInternalTags(initialTags);
    }
  }, [initialTags]);

  React.useEffect(() => {
    if (value) {
      setInternalTags(value);
    }
  }, [value]);

  const handleChange = React.useCallback((newTags: string[]) => {
    if (typeof onChange === 'function') {
      onChange(newTags);
    }

    setInternalTags(newTags);
  }, [onChange]);

  const handleAddNewTag = React.useCallback(() => {
    const newTags: string[] = internalTags ? internalTags.map(x => x) : [];
    const newTag = tagName ? tagName.trim() : null;

    if (newTag) {
      newTags.push(newTag);

      handleChange(newTags);
    }
  }, [handleChange, internalTags, tagName]);

  const allowAddTag = allowAdd !== undefined ? allowAdd : true;

  return (
    <Select
      mode='multiple'
      placeholder={placeholder || t('post.editor.tags.searchTag')}
      className="tag-select"
      value={tags}
      size={size ? size : "middle"}
      onChange={handleChange}
      // style={{ overflowY: 'hidden'}}
      showArrow
      allowClear
      dropdownRender={menu => (
        <div>
          {menu}
          {allowAddTag &&
            (
              <>
                <Divider style={{ margin: '4px 0' }} />
                <div className="create-tags-container">
                  <Input
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                    placeholder={t('post.editor.tags.addTagPlaceholder')}
                  />
                  <Button
                    type="link"
                    className='add-tag-button'
                    icon={<PlusOutlined />}
                    onClick={handleAddNewTag}
                  >
                    {t('post.editor.tags.addTag')}
                  </Button>
                </div>
              </>)}
        </div>
      )}
    >
      {tagsQuery?.data?.tags.map((tag: Tag) => tag && (
        <Option key={tag.name} value={tag.name}>{tag.name}</Option>
      ))}
    </Select>
  );
};

export default TagSelect;