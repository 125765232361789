import { UserOutlined } from '@ant-design/icons';

import { MenuConfig, Route } from '../../routes.types';
import Users from './UserList';

export const userRoutePaths = {
  root: '/users',
};

export const routes: Route[] = [
  {
    path: userRoutePaths.root,
    component: Users,
  }
];

export const menus: MenuConfig = [
  {
    route: routes[0],
    label: 'user.menuTitle',
    icon: UserOutlined,
    order: 1,
  }
];
