import gql from 'graphql-tag';

export const GET_INCOMPLETE_QUESTIONNAIRES = gql`
  query UserIncompleteQuestionnaire($roomId: ObjectId) {
    userIncompleteQuestionnaires(roomId: $roomId) {
      id
      questionnaireModel {
        title
        description
        items {
          type
          question
          id
          required
          other
          options {
            label
          }
        }
      }
      testSessionId
      answers {
        questionId
        answer
      }
      completed
      createdAt
      updatedAt
    }
  }
`;
