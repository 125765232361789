export const basicJitsiSettings = {
  // filmStripOnly: true
  SHOW_JITSI_WATERMARK: false,
  SHOW_WATERMARK_FOR_GUESTS: false,
  SHOW_BRAND_WATERMARK: true,
  BRAND_WATERMARK_LINK: '...',
  SHOW_POWERED_BY: false,
  SHOW_DEEP_LINKING_IMAGE: false,
  GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
  HIDE_INVITE_MORE_HEADER: true,
  DISPLAY_WELCOME_PAGE_CONTENT: false,
  DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
  APP_NAME: 'VideoConference',
  NATIVE_APP_NAME: 'Jitsi',
  PROVIDER_NAME: 'Jitsi',
  LANG_DETECTION: true,
  INVITATION_POWERED_BY: false,
  CLOSE_PAGE_GUEST_HINT: false,
  SHOW_PROMOTIONAL_CLOSE_PAGE: false,
  MOBILE_APP_PROMO: false,
  TOOLBAR_ALWAYS_VISIBLE: true,
  SHOW_CHROME_EXTENSION_BANNER: false,

  debug: false,
  requireDisplayName: false,
};