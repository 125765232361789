import './Post.css';

import React, { useEffect } from 'react';

import Footer from '../../shared/Footer';
import PostList from './PostList';
import { useTranslation } from 'react-i18next';

export type PostProps = {};

const Post: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('posts.menuTitle');
  })

  return (
    <div className='post-container'>
      <PostList />
      <Footer />
    </div>
  );
};

export default Post;
