import { Button, Modal } from 'antd';
import * as React from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { ANSWER_QUESTIONNAIRE_QUESTION, COMPLETE_QUESTIONNAIRE, Questionnaire, QuestionnaireAnswer } from '../../api/questionnaire';
import Loader from '../Components/Loader';
import QuestionnaireForm from '../Questionnaire';

import './QuestionnaireModal.css';

interface UserQuestionnaireContainerProps {
  isVisible: boolean;
  questionnaire: Questionnaire;
  editable?: boolean;
  onQuestionAnser?: () => void;
  onQuestionnaireComplete: () => void;
}

const QuestionnaireModal: React.FC<UserQuestionnaireContainerProps> = (props) => {
  const {
    isVisible,
    questionnaire,
    editable,
    onQuestionAnser,
    onQuestionnaireComplete,
  } = props;

  const { t } = useTranslation();

  const [answerQuestion, answerQuestionResult] = useMutation(ANSWER_QUESTIONNAIRE_QUESTION);
  const [completeQuestionnaire, completeQuestionnaireResult] = useMutation(COMPLETE_QUESTIONNAIRE);
  const [completed, setCompleted] = React.useState<boolean>(false);

  const handleAnswerQuestion = React.useCallback((answer: string, questionId: string, answers: QuestionnaireAnswer[]) => {
    console.log('ANSWERS', answers);

    if (!questionnaire) {
      return;
    }

    let isCompleted = true;
    for (const item of questionnaire.questionnaireModel.items) {
      if (item.required && !answers.find(x => x.questionId === item.id)) {
        console.log('Question', item.question, 'is icompleted!!!');
        isCompleted = false;
        break;
      }
    }

    setCompleted(isCompleted);

    answerQuestion({
      variables: {
        input: {
          id: questionnaire.id,
          questionId,
          answer,
        }
      }
    })
      .then(() => {
        if (typeof onQuestionAnser === 'function') {
          onQuestionAnser();
        }
      })
      .catch((e) => {
        console.error('APOLLO ERROR', e);
        // handleAnswerQuestion(answer, questionId, answers);
      });
  }, [questionnaire]);

  const handleSubmit = React.useCallback(() => {
    if (answerQuestionResult.loading) {
      window.setTimeout(() => handleSubmit(), 500);
    }

    completeQuestionnaire({
      variables: {
        id: questionnaire.id,
      },
    }).then(() => {
      if (typeof onQuestionnaireComplete === 'function') {
        onQuestionnaireComplete();
      }
    }).catch((e) => {
      handleSubmit();
    });
  }, [answerQuestionResult, completeQuestionnaire, questionnaire, onQuestionnaireComplete]);

  return (
    <Modal
      title={t('questionnaire.modalTitle')}
      visible={isVisible}
      centered={true}
      closable={false}
      width={1024}
      footer={
        <div className="user-questionnaire-modal-footer">
          {answerQuestionResult.loading ? <Loader /> : <div></div>}
          <Button
            key="submit"
            type="primary"
            loading={completeQuestionnaireResult.loading}
            disabled={answerQuestionResult.loading || !completed}
            onClick={handleSubmit}
          >
            {t('questionnaire.submit')}
          </Button>
        </div>
      }
    >
      <div className="user-questionnaire-modal-content">
        <QuestionnaireForm
          editable={editable || false}
          questionnaireModel={questionnaire.questionnaireModel}
          questionnaire={questionnaire}
          onChange={handleAnswerQuestion}
        />
      </div>
    </Modal>
  );
}

export default QuestionnaireModal;
