import { MenuConfig, Route } from '../routes.types';
import Posts from './Post';
import PostDetails from './PostDetails';
import TagPosts from './TagPosts';
import UserPosts from './UserPosts';

export const postRoutePaths = {
  root: '/posts',
  tagPosts: '/posts/tag/:name',
  userPosts: '/user-posts',
  draftPosts: '/draft-posts',
  postDetails: '/posts/:id',
};

export const routes: Route[] = [
  {
    path: postRoutePaths.tagPosts,
    component: TagPosts,
  },
  {
    path: postRoutePaths.postDetails,
    component: PostDetails,
  },
  {
    path: postRoutePaths.userPosts,
    component: UserPosts,
  },
  {
    path: postRoutePaths.root,
    component: Posts,
    exact: true,
  }
];

export const menus: MenuConfig = [
  {
    route: routes[routes.length - 1],
    label: 'posts.menuTitle',
    order: 1,
  },
];
