import './VideoConference.css';

import React, { useCallback } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useHistory, useParams } from 'react-router-dom';

import { GET_INCOMPLETE_QUESTIONNAIRES, UserIncompleteQuestionnaires } from '../../api/questionnaire';
import { GET_ROOM, GetRoomResponse, RoomStatus, RoomTypes } from '../../api/room';
import { useAuth } from '../../auth0';
import Loader from '../../shared/Components/Loader';
import QuestionnaireModal from '../../shared/QuestionnaireModal';
import { VideoConference as Video } from '../../videoconf';
import ControlPanel from './ControlPanel';
import ForbiddenVideoConference, { RoomError } from '../../videoconf/components/ForbiddenVideoConference';

export type VideoConferenceProps = {
  id: string;
};

const VideoConference: React.FC<VideoConferenceProps> = ({ id }) => {
  const auth = useAuth();
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const [getRoom, roomQuery] = useLazyQuery<GetRoomResponse>(GET_ROOM);
  const [getIncompleteQuestionnaires, questionnaires] = useLazyQuery<UserIncompleteQuestionnaires>(GET_INCOMPLETE_QUESTIONNAIRES, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const [timer, setTimer] = React.useState<number>();
  const [counter, setCount] = React.useState<number>(0);
  const [roomError, setError] = React.useState<RoomError>();

  React.useEffect(() => {
    if (!roomId) {
      history.push('/');
    }

    if (roomQuery.called || roomQuery.loading) {
      return;
    }

    if (!auth.user || auth.isAuthenticating) {
      return;
    }

    getRoom({
      variables: { id: roomId }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, roomQuery, auth]);

  React.useEffect(() => {
    if(roomQuery.called && roomQuery.error) {
      if(roomQuery.error.graphQLErrors[0].extensions?.code === 'RoomNotFound') {
          setError ({
            errorTitle: 'Sorry, this room is no longer available.  ',
            errorDescription: 'This room might have been deleted by the owner or an admin user.',
            onBackCallback: () => history.push('/posts'),
        })
      }
    }
    
    if (!roomQuery.data || !roomQuery.called) {
      return;
    }

    if (roomQuery.data.room.status === RoomStatus.OPEN) {
      if (timer) {
        window.clearInterval(timer);
      }

      return;
    }

    if (timer) {
      return;
    }

    if ((new Date(roomQuery.data.room.openingAt).getTime() < (new Date()).getTime() + 15 * 60 * 1000)) {
      let i = 0;
      setTimer(window.setInterval(() => {
        roomQuery.refetch();
        console.log('refetch');
        i = i + 1;
        setCount(i);
      }, 5000));
    }

    document.title = roomQuery.data.room.name;
  }, [roomQuery, timer, setTimer]);

  React.useEffect(() => {
    if (!auth.user || auth.isAuthenticating) {
      return;
    }

    if (questionnaires.data || questionnaires.loading || questionnaires.called) {
      return;
    }

    getIncompleteQuestionnaires({
      variables: {
        roomId,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaires, auth]);

  React.useEffect(() => {
    if (!auth.isAuthenticating && !auth.isAuthenticated && !auth.user) {
      if (auth.login) {
        auth.login();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleCommandReceived = useCallback((command: string, data: string[]) => {
    if (command === 'questionnaire') {
      getIncompleteQuestionnaires({
        variables: {
          roomId,
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuestionnaireCompleted = useCallback(() => {
    getIncompleteQuestionnaires({
      variables: {
        roomId,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (auth.isAuthenticating || !auth.user) {
    return <Loader isLoading={true}>Authenticating user...</Loader>
  }

  if(roomError)  {
    return <ForbiddenVideoConference error={roomError}/>
  }

  const questionnaire = questionnaires.data && questionnaires.data.userIncompleteQuestionnaires.length
    ? questionnaires.data.userIncompleteQuestionnaires[0]
    : null;

  return (
    <div id="videocall-layout" className={`updater-${counter}`}>
      <Video room={roomQuery.data?.room} onCommandReceived={handleCommandReceived}>
        { (roomQuery.data?.room && roomQuery.data.room.type === RoomTypes.CONFERENCE) && <ControlPanel /> }

        { questionnaire &&
          <QuestionnaireModal
            questionnaire={questionnaire}
            isVisible={true}
            onQuestionnaireComplete={handleQuestionnaireCompleted}
            editable
          />
        }
      </Video>
    </div>
  );
};

export default VideoConference;
