import { AppstoreOutlined, SaveOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Empty, List, Radio, Row, Space } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { head } from 'lodash';
import * as React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaginationVariables } from '../../../../api';
import { GET_HEADER_POSTS, PaginatedPosts, Post, POST_ADD_TO_HEADER, POST_REMOVE_FROM_HEADER } from '../../../../api/post';
import { useAuth } from '../../../../auth0';
import { Actions, hasPermission, Resources } from '../../../../rbac';
import PostCard from '../PostCard';
import HeaderEditor from './HeaderEditor';
import './PostHeader.css';

const Layouts = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

interface PostHeaderProps extends RouteComponentProps {
  onUpdate: () => void;
}

const getHeaderPost = (posts: Post[], headerPosition: number): Post | undefined => {
  if (!posts || !posts.length) {
    return;
  }

  return posts.find(x => x.metadata?.headerPosition === headerPosition) || undefined;
};

const getHeaderPosts = (posts: Post[], headerPositions: number[]): (Post | undefined)[] => {
  return headerPositions.map(x => getHeaderPost(posts, x));
};

type SelectedPost = Post | undefined;
type SelectedPosts = SelectedPost[];

const PostHeader: React.FC<PostHeaderProps> = ({ onUpdate }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [layout, setLayout] = React.useState<number>(Layouts.ONE);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [selectedPosts, setSelectedPosts] = React.useState<SelectedPosts>([undefined, undefined, undefined, undefined, undefined]);

  const { loading, data, error, called, refetch } = useQuery<PaginatedPosts, PaginationVariables>(GET_HEADER_POSTS);
  const [addPostToHeader] = useMutation(POST_ADD_TO_HEADER);
  const [removePostFromHeader] = useMutation(POST_REMOVE_FROM_HEADER);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const initWithQueryPosts = (data: PaginatedPosts) => {
    const posts = data.posts.edges;

    setSelectedPosts(() => {
      const headerPosts = getHeaderPosts(posts.map(x => x.node), posts.map((x, index) => index));

      return headerPosts;
    });

    setLayout(posts.length);
  }

  React.useEffect(() => {
    if (!loading && data && data.posts.edges.length) {
      initWithQueryPosts(data);
    }
  }, [data, loading]);

  const handleChangeLayout = React.useCallback((e: RadioChangeEvent) => {
    const layout = e.target.value as number;

    setLayout(layout);
    setSelectedPosts(() => {
      const res: SelectedPosts = [];

      selectedPosts.forEach((x, index) => {
        if (index >= layout || !x) {
          return;
        }

        res.push({
          ...x,
        });
      });

      if (res.length < layout) {
        for (let i=res.length; i<layout; i++) {
          res.push(undefined);
        }
      }

      return res;
    });


  }, [selectedPosts]);

  const handleCancel = React.useCallback(() => {
    if (data && data.posts.edges.length) {
      initWithQueryPosts(data);
    }

    setIsEditing(false);
  }, []);

  const handleSave = React.useCallback(async () => {
    let position = 0;

    setIsSaving(true);

    const oldPosts = (data && data.posts.edges.length) ? data.posts.edges.map(x => x.node) : [];
    if (selectedPosts.length < oldPosts.length) {
      // Delete the extra posts
      for (let headerPosition = selectedPosts.length; headerPosition < oldPosts.length; headerPosition++) {
        const oldPost = getHeaderPost(oldPosts, headerPosition);
        if (!oldPost) {
          console.error('Can\'t find a header post in postion', headerPosition);
          continue;
        }

        removePostFromHeader({
          variables: {
            input: {
              id: oldPost.id,
              headerPosition,
            },
          }
        }).catch((err) => {
          console.error('Could not remove the Post in headerPosition', headerPosition);
        });
      }
    }

    for (const post of selectedPosts) {
      const oldPost = getHeaderPost(oldPosts, position);
      if (!post || (oldPost && oldPost.id === post.id)) {
        position++;
        continue;
      }

      await addPostToHeader({
        variables: {
          input: {
            id: post?.id,
            headerPosition: position,
          }
        }
      });

      position++;
    }

    setIsEditing(false);
    setIsSaving(false);

    onUpdate();
  }, [selectedPosts]);

  const setHeaderPost = (headerPosition: number, post: Post | null) => {
    setSelectedPosts((prevState) => {
      return prevState.map((x, index) => index === headerPosition ? (post || undefined) : x) as SelectedPosts;
    });
  };

  const renderToolbar = () => (
    <div className="post-header-toolbar">
      {t('posts.editHeaderHints')}
      <Space direction='horizontal' className="post-header-toolbar-actions">
        <Button onClick={handleCancel} disabled={isSaving}>{t('common.cancel')}</Button>
        <Button onClick={handleSave} loading={isSaving} type="primary" icon={<SaveOutlined />}>{t('posts.saveHeader')}</Button>
      </Space>
    </div>
  );

  const renderCustomizeButton = () => (
    <div className="post-header-customize-bar">
      <Button type="link" onClick={() => setIsEditing(true)} icon={<AppstoreOutlined />}>{t('posts.setupHeader')}</Button>
    </div>
  );

  const renderBigPost = (headerPosition: number) => {
    const post = selectedPosts[headerPosition];

    return (
      <HeaderEditor selectedPosts={selectedPosts as Post[]} isEditing={isEditing} onPostChange={(post) => setHeaderPost(headerPosition, post)}>
        <PostCard post={post} isLoading={loading && !called} direction='vertical' imagePosition='before' />
      </HeaderEditor>
    );
  };

  const renderPostsList = () => {
    const postsList = selectedPosts.filter((x, index) => index > 0 && index < 4);

    return (
      <List
        loading={false}
        className="post-header-list"
        itemLayout='horizontal'
        dataSource={postsList.map(x => x || { id: 'null' })}
        renderItem={(item, index) => (
          <List.Item key={`header-posts-small-${index}`}>
            <HeaderEditor selectedPosts={selectedPosts as Post[]} isEditing={isEditing} onPostChange={(post) => setHeaderPost(index + 1, post)}>
              <PostCard isLoading={loading && !called} post={item.id === 'null' ? undefined : (item as Post)} small />
            </HeaderEditor>
          </List.Item>
        )}
      />
    )
  };

  if (error) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: 60 }}
        description={<>{t('posts.headerError')}</>}
      >
        <Button type="primary" onClick={() => window.location.reload()}>{t('common.reloadPage')}</Button>
      </Empty>
    );
  }

  const canEditHeader = !!user && hasPermission(user, Resources.POST, Actions.FEATURE);

  if (error || !data || data.posts.pageInfo.count === 0 && !isEditing) {
    if (canEditHeader) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 60 }}
          description={<>{t('posts.missingHeader')}</>}
        >
          <Button type="primary" icon={<AppstoreOutlined />} onClick={() => setIsEditing(true)}>{t('posts.setupHeader')}</Button>
        </Empty>
      );
    } else {
      return null;
    }
  }

  const showCustomizeButton = user && user.userId
    && !isEditing && canEditHeader;

  const renderOnePostLayout = () => (
    <Row gutter={[60, 30]} className="post-header-container">
      <Col xs={24}>
        {renderBigPost(0)}
      </Col>
    </Row>
  );

  const renderTwoPostLayout = () => (
    <Row gutter={[60, 30]} className="post-header-container">
      <Col xs={24} sm={12}>
        {renderBigPost(0)}
      </Col>
      <Col xs={24} sm={12}>
        {renderBigPost(1)}
      </Col>
    </Row>
  );

  const renderThreePostLayout = () => (
    <Row gutter={[60, 30]} className="post-header-container">
      <Col xs={24} sm={8}>
        {renderBigPost(0)}
      </Col>
      <Col xs={24} sm={8}>
        {renderBigPost(1)}
      </Col>
      <Col xs={24} sm={8}>
        {renderBigPost(2)}
      </Col>
    </Row>
  );

  const renderFourPostLayout = () => (
    <div className="post-header-container">
      <Row gutter={[60, 30]}>
        <Col xs={24} sm={12}>
          {renderBigPost(0)}
        </Col>
        <Col xs={24} sm={12}>
          {renderBigPost(1)}
        </Col>
      </Row>
      <Row gutter={[60, 30]}>
        <Col xs={24} sm={12}>
          {renderBigPost(2)}
        </Col>
        <Col xs={24} sm={12}>
          {renderBigPost(3)}
        </Col>
      </Row>
    </div>
  );

  const renderFivePostLayout = () => (
    <Row gutter={[60, 30]} className="post-header-container">
      <Col xs={24} sm={8}>
        {renderBigPost(0)}
      </Col>
      <Col xs={24} sm={8}>
        {renderPostsList()}
      </Col>
      <Col xs={24} sm={8}>
        {renderBigPost(4)}
      </Col>
    </Row>
  );

  const renderers = {
    [Layouts.ONE]: renderOnePostLayout,
    [Layouts.TWO]: renderTwoPostLayout,
    [Layouts.THREE]: renderThreePostLayout,
    [Layouts.FOUR]: renderFourPostLayout,
    [Layouts.FIVE]: renderFivePostLayout,
  };

  return (
    <div>
      {isEditing && <Alert message={renderToolbar()} type="warning" />}
      {isEditing && <div className="layout-chooser">
        <Radio.Group
          onChange={handleChangeLayout}
          value={layout}
          optionType="button"
          buttonStyle="solid"
        >
          {Object.keys(Layouts).map(x => (
            <Radio.Button key={`layout-${x}`} value={Layouts[x]}>{t(`posts.headerLayouts.layout${Layouts[x]}`)}</Radio.Button>
          ))}
        </Radio.Group>
      </div>
      }
      {renderers[layout]()}
      {showCustomizeButton && renderCustomizeButton()}
    </div>
  );
};

export default withRouter(PostHeader);
