import { AuthenticatedUser, RBAC } from "./rbac.config";
import { Permissions, Resource, VerifiedAction, Roles } from "./rbac.types";

export const hasPermission = <R extends Resource>(
  user: AuthenticatedUser,
  resource: R,
  action: VerifiedAction<R>
): boolean => {
  console.log("USER", user)
  if (!user) {
    return false;
  }

  if (!user.tenantId || !user.roles) {
    throw new Error("Invalid authenticated user");
  }

  const tenantConfig = RBAC[user.tenantId];

  for(const role of user.roles) {
    const resources = tenantConfig[role];
    const actions = resources[resource];

    for (const enabledActions of actions) {
      if (action === enabledActions) {
        return true;
      }
    }
  }
  
  return false;
}

export const hasPermissions = (
  user: AuthenticatedUser,
  permissions: Permissions,
): boolean => {
  console.log("USER", user)

  if (!user) {
    return false;
  }

  if (!user.tenantId || !user.roles) {
    throw new Error("Invalid authenticated user");
  }

  const tenantConfig = RBAC[user.tenantId];

  for(const role of user.roles) {
    const resources = tenantConfig[role];

    for (const askedResource in permissions){
      const askedActions = permissions[askedResource];

      for (const askedAction of askedActions) {
        const actions = resources[askedResource];

        if (!actions.find(x => x === askedAction)) {
          return false;
        }
      }
    }
  }
  
  return true;
};

export const hasRole = (
  user: AuthenticatedUser,
  role: Roles,
) => {
  if(!user || !user.roles || !role) {
    return false;
  }

  const checkUserRoles = user.roles.some((value) => value === role);

  return checkUserRoles;
}