import './UserSelect.css';

import { Button, Checkbox, List, Popover, Switch, Tabs } from "antd";
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { select } from 'redux-saga/effects';

import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { GET_GROUPS, UserGroup } from '../../api/group';
import { GET_USERS, User } from '../../api/user';
import SelectionPanel, { SelectionPanelProps } from '../SelectionPanel';
import UsersPanel from '../UsersPanel';

const { TabPane } = Tabs;

export type UserSelectProps = Pick<SelectionPanelProps, 'title' | 'multi' | 'selected' | 'onSelect'> & {
  className?: string;
  buttonLabel?: string;
  changeButtonLabel?: string;
  renderSelectedItem?: (item: any) =>  React.ReactNode;
  block?: boolean;
  disabled?: boolean | any[];
  clearable?: boolean;
};

const UserSelect: React.FC<UserSelectProps> = (props) => {
  const {
    className,
    buttonLabel,
    changeButtonLabel,
    title,
    renderSelectedItem,
    multi,
    block,
    onSelect: externalSetSelected,
    selected: externalSelected,
    disabled,
    clearable = true,
  } = props;

  const { t } = useTranslation();

  const [panelSelected, panelSetSelected] = useState<any>();
  const [internalSelected, internalSetSelected] = useState<any>();
  const [selectedGroups, setSelectedGroup] = useState<any>();

  const [opened, setOpened] = useState<boolean>(false);

  const [showGroupsUsers, setGroupsUsers] = useState<boolean>(false);
  
  const setSelected = externalSetSelected || internalSetSelected;
  const selected = externalSelected || internalSelected;

  React.useEffect(() => {
    if (selected) {
      panelSetSelected(selected);
    }
  }, [selected]);

  const selectedCount = selected ? Object.keys(selected).length : 0;
  const internalSelectedCount = panelSelected ? Object.keys(panelSelected).length : 0;
  const fullyDisabled = !!disabled ? (typeof disabled !== 'boolean' ? disabled : undefined) : undefined;

  const handleGroupSelection = useCallback((items: { [id: string]: UserGroup }) => {
    const addedUsers: User[] = [];
    const removedIds: string[] = [];

    if (selectedGroups) {
      // Check for removals
      const removed: UserGroup[] = Object.keys(selectedGroups)
        .filter(id => !items[id])
        .map(id => selectedGroups[id]);

      removed.forEach(group => 
        group.items && group.items.forEach(item => removedIds.push(item.user.id))
      );

      // Check for addition
      const added: UserGroup[] = Object.keys(items)
        .filter(id => !selectedGroups[id])
        .map(id => items[id]);
      
      
      added.forEach(group => 
        group.items && group.items.forEach(item => addedUsers.push(item.user))
      );
    } else {
      // All items are added
      Object.values(items).forEach(group => 
        group.items && group.items.forEach(item => addedUsers.push(item.user))
      );
    }

    // Update group selection
    setSelectedGroup(items);

    panelSetSelected(panelSelected => {
      // Build the new state
      const newState = {};

      if (panelSelected) { 
        // Copy only the non-removed items
        Object.keys(panelSelected).forEach(id => {
          if (!removedIds.find(x => x === id)) {
            newState[id] = panelSelected[id];
          }
        });
      }

      // Add the new ones, filtering out disabled users
      if (!disabled) {
        addedUsers.forEach(user => newState[user.id] = user);
      } else {
        addedUsers.forEach(user => {
          if (!disabled[user.id]) {
            newState[user.id] = user;
          }
        });
      }

      // Apply user selection
      return newState;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups, disabled]);
  
  const handleSelection = useCallback(() => {
    setOpened(false);
    setSelected(panelSelected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelSelected]);

  const handleCancel = useCallback(() => {
    setOpened(false);

    if (selectedCount === 0) {
      panelSetSelected({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCount]);

  const handleChangeSelection = useCallback((item: any) => {
    setOpened(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearSelection = useCallback((item: any) => {
    setSelected({});
    panelSetSelected({});
    setSelectedGroup({});
    setOpened(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedSearchFn = (item: User, term: string) => {
    const reg = new RegExp((term || '').trim().toLowerCase(), 'gmi');

    return reg.test(item.firstName) || reg.test(item.lastName);
  };

  const renderExpandSwitch = () => (
    <div className="user-select-group-switch-container">
      <Switch className="user-select-group-switch" checked={showGroupsUsers} onChange={setGroupsUsers} />{t('group.showGroupsUsers')}
    </div>
  );

  const renderDeselectButton = () => (
    <div className="user-select-group-deselect-container">
      <Button className="user-select-group-deselect-button" type="link" onClick={handleClearSelection}>{t('user.deselectAll')}</Button>
    </div>
  )

  const renderUserItem = (item: User) => `${item.firstName} ${item.lastName}`;

  const renderGroupItem = (item: UserGroup) => (
    <div className="user-select-group">
      <div className={`user-select-group-name${showGroupsUsers ? ' bold' : ''}`}>{item.name}</div>
      <UsersPanel
        key={`group-${item.id}-user`}
        className="user-select-group-users"
        users={item.items?.map(x => x.user)}
        isVisible={showGroupsUsers}
        size="small"
        slim
      />
    </div> 
  );

  const renderWrappedItem = () => {
    const keys = Object.keys(selected);

    return (
      <div className={`user-select-item${block ? ' user-select-item-block' : ''}`}>
        { multi 
          ? t(`user.${selectedCount === 1 ? 'oneItemSelected' : 'itemsSelectedCount'}`, { count: selectedCount })
          : (renderSelectedItem ? renderSelectedItem(selected[keys[0]]) : renderUserItem(selected[keys[0]])) 
        }
        <div className="user-select-item-action">
          <Button type="link" size="small" style={{ fontSize: 12 }} onClick={handleChangeSelection}>{changeButtonLabel || t('common.change').toLocaleLowerCase()}</Button>
          {clearable && <>| <Button type="link" size="small" style={{ fontSize: 12 }} onClick={handleClearSelection}>{t('common.clear').toLocaleLowerCase()}</Button></>}
        </div>
      </div>
    );
  };

  const renderPopover = () => (
    <Tabs className="user-select-tabs">
      <TabPane tab={t('user.menuTitle')} key="users">
        <SelectionPanel
          className="user-select-selection-panel"
          query={GET_USERS}
          queryRoot="users"
          initialItemsCount={20}
          renderItem={renderUserItem}
          onSelect={panelSetSelected}
          selected={panelSelected}
          multi={multi}
          disabled={fullyDisabled}
          hideSummary
        />
      </TabPane>

      <TabPane tab={t('group.menuTitle')} key="groups">
        <SelectionPanel
          className="user-select-selection-panel user-select-groups-panel"
          query={GET_GROUPS}
          queryRoot="userGroups"
          initialItemsCount={20}
          renderItem={renderGroupItem}
          extra={renderExpandSwitch()}
          onSelect={handleGroupSelection}
          selected={selectedGroups}
          multi={multi}
          disabled={fullyDisabled}
          hideSummary
        />
      </TabPane>

      <TabPane tab={t('user.selectedCount', { count: internalSelectedCount })} key="selected">
        <SelectionPanel
          className="user-select-selection-panel"
          data={panelSelected}
          searchFn={selectedSearchFn}
          renderItem={renderUserItem}
          extra={renderDeselectButton()}
          onSelect={panelSetSelected}
          selected={panelSelected}
          multi={multi}
          disabled={fullyDisabled}
          hideSummary
        />
      </TabPane>
    </Tabs>
    
  );

  return (
    <div className={`user-select${block ? ' user-select-block' : ''}${className ? ` ${className}` : ''}`}>
      <Modal
        visible={opened}
        className="user-select-modal"
        title={title}
        onCancel={handleCancel}
        onOk={handleSelection}
        okText={t('common.done')}
      >
        {renderPopover()}
      </Modal>
      { selectedCount === 0 
        ? (<Button type="link" onClick={handleChangeSelection} disabled={disabled === false}>
            { buttonLabel || t('user.select') }{opened ? <UpOutlined/> : <DownOutlined/>}
          </Button>)
        : renderWrappedItem()
      }
    </div>
  );
};

export default UserSelect;