import { createContext } from 'react';

import { Auth0Client } from '@auth0/auth0-spa-js';

export type Auth0ContextProps = {
  client?: Auth0Client;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  login?: () => void;
  error?: Error;
  user: any;
}

export const defaultContextValue: Auth0ContextProps = {
  client: undefined,
  isAuthenticating: false,
  isAuthenticated: false,
  login: undefined,
  error: undefined,
  user: undefined,
};

export const Auth0Context = createContext(defaultContextValue);