import * as React from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';

import { GET_LOGGED_USER } from '../../api/user';
import { useAuth } from '../../auth0';

export const useUser = () => {
  const { user: authUser } = useAuth();
  const [fetchUser, { loading, data, error, refetch }] = useLazyQuery(GET_LOGGED_USER);

  React.useEffect(() => {
    if (authUser) {
      fetchUser();
    }
  }, [authUser]);

  if (error) {
    return {
      loading: false,
      refetchUser: refetch,
    };
  }

  return {
    user: data && data.user,
    isLoading: loading,
    refetchUser: refetch,
  };
};
