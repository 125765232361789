import admin from './admin.json';
import backoffice from './backoffice';
import common from './common.json';
import graph from './graph.json';
import mereport from './mereport.json';
import posts from './posts.json';
import questionnaire from './questionnaire.json';
import testsessions from './testsessions.json';
import videoconf from './videoconf.json';

const translation = {
  ...backoffice,
  admin,
  common,
  graph,
  mereport,
  posts,
  questionnaire,
  testsessions,
  videoconf,
};

// Leave exports as they are here
const _translation = {
  translation,
};

export default _translation;
