import gql from 'graphql-tag';

export const CREATE_QUESTIONNAIRE_MODEL = gql`
  mutation CreateQuestionnaireModel($input: QuestionnaireModelCreateInput!) {
    createQuestionnaireModel(input: $input) {
      id
      title
      description
      items {
        question
        type
        required
        other
        options {
          label
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_QUESTIONNAIRE_MODEL = gql`
  mutation DeleteQuestionnaireModel($id: ObjectId!) {
    deleteQuestionnaireModel(id: $id) {
      id,
      title
    }
  }
`;

export const UPDATE_QUESTIONNAIRE_MODEL = gql`
  mutation UpdateQuestionnaireModel($input: QuestionnaireModelUpdateInput!) {
    updateQuestionnaireModel(input: $input) {
      id
      title
      description
      items {
        question
        type
        required
        other
        options {
          label
        }
      }
      createdAt
      updatedAt
    }
  }
`;