import moment from 'moment-timezone';
import * as React from 'react';

import { useUser } from '../useUser';

export type DateTimeProps = {
  datetime: string | number;
};

const DateTime: React.FC<DateTimeProps> = (props) => {
  const { user, isLoading } = useUser();
  const { datetime } = props;
  
  if (isLoading || !user) {
    return (<></>);
  }

  const m = moment(datetime);

  return <>{user.timezone ? m.tz(user.timezone).format('lll') : m.format('lll')}</>;
}

export default DateTime;
