export enum Roles {
  SYSTEM_ADMIN = 'systemAdmin',
  PARTNERS = 'partners',
  DONORS = 'donors',
  LOCAL_AUTHORITIES = 'localAuthorities',
  USER = 'user',
}

export enum Actions {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  OPEN = 'open',
  CLOSE = 'close',
  LAUNCH = 'launch',
  FEATURE = 'feature',
}

export enum Resources {
  // Users
  SYSTEM_ADMIN = 'systemAdmin',
  PARTNERS = 'partners',
  DONORS = 'donors',
  LOCAL_AUTHORITIES = 'localAuthorities',
  USER = 'user',

  // Apps
  BACKOFFICE = 'backoffice',

  // Other entities
  CHART = 'chart',
  FILE = 'file',
  POST = 'post',
  QUESTIONNAIRE_MODEL = 'questionnaireModel',
  ROOM = 'room',
  TEAM = 'team',
  TEST_SESSION = 'testSession',
  USER_GROUP = 'userGroup',
  QUESTIONNAIRE = 'questionnaire',
}

export type CRUD = Actions.CREATE | Actions.READ | Actions.UPDATE | Actions.DELETE;

export type ActionEnforcer = {
  // Users by role
  [Resources.SYSTEM_ADMIN]: CRUD,
  [Resources.PARTNERS]: CRUD,
  [Resources.DONORS]: CRUD,
  [Resources.LOCAL_AUTHORITIES]: CRUD,
  [Resources.USER]: CRUD,

  // Apps
  [Resources.BACKOFFICE]: Actions.LAUNCH,

  // Other entities
  [Resources.CHART]: CRUD,
  [Resources.FILE]: CRUD,
  [Resources.POST]: Actions.CREATE | Actions.UPDATE | Actions.DELETE | Actions.FEATURE,
  [Resources.QUESTIONNAIRE_MODEL]: CRUD,
  [Resources.ROOM]: CRUD | Actions.OPEN | Actions.CLOSE,
  [Resources.TEAM]: CRUD,
  [Resources.TEST_SESSION]: Actions.CREATE | Actions.READ | Actions.UPDATE | Actions.DELETE | Actions.CLOSE,
  [Resources.USER_GROUP]: CRUD,
  [Resources.QUESTIONNAIRE]: CRUD,
};

export type Resource = keyof ActionEnforcer;

type VerifiedRule<R extends Resource> = Pick<ActionEnforcer, R>;
export type VerifiedAction<T extends Resource> = VerifiedRule<T>[T];

export type Permissions = {
  [R in Resources]?: Partial<VerifiedAction<R>>[]
};
