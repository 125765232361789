import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Auth0ClientOptions, PopupLoginOptions, RedirectLoginOptions } from '@auth0/auth0-spa-js';

import { createClient, createSession, debug, getUrlWithCode } from '../core';
import { Auth0User, LoginType } from '../types';
import { Auth0Context, Auth0ContextProps, defaultContextValue } from './Auth0Context';

export type Auth0ProviderProps<T = {}> = {
  clientOptions: Auth0ClientOptions;
  loginType?: LoginType;
  loginOptions?: PopupLoginOptions | RedirectLoginOptions;
  forceLogin?: boolean;
  onBeforeRedirect?: () => Promise<T>;
  onRedirectCompleted?: (data: T) => void;
  onSuccessfulLogin?: ({ user: any, accessToken: string }) => void;
  tranformUser?: (user: Auth0User) => any;
};

export const Auth0Provider: React.FC<Auth0ProviderProps> = ({ children, ...props }) => {
  const [state, setState] = useState<Auth0ContextProps>(defaultContextValue);
  const [isSessionStarted, setSessionStarted] = useState<boolean>(false);
  const history = useHistory();

  const onBeforeRedirect = React.useCallback(async (): Promise<any> => {
    if (typeof props.onBeforeRedirect === 'function') {
      return props.onBeforeRedirect();
    }

    // Avoid login loops!
    if (window.location.pathname === '/login') {
      return { origin: null };
    }

    return {
      origin: window.location.href
    };
  }, [props]);

  const onRedirectCompleted = React.useCallback((appState: any) => {
    if (typeof props.onRedirectCompleted === 'function') {
      return props.onRedirectCompleted(appState);
    }

    // Don't just push again a redirect if not needed, it might lead to loops
    if (appState.origin != null && window.location.href != appState.origin) {
      debug('[AUTH]: Should redirect to:', appState.origin, 'host was', window.location.protocol, window.location.host);

      const redirectUrl = appState.origin as string;
      const redirectedBase = `${window.location.protocol}//${window.location.host}/`;
      if (redirectUrl.startsWith(redirectedBase)) {
        const pushUrl = redirectUrl.replace(new RegExp(redirectedBase, 'gi'), '');

        debug('[AUTH]: Will push to:', pushUrl);

        history.push(pushUrl);
      } else {
        debug('[AUTH]: Will use window.location.href to:', redirectUrl);
        window.location.href = redirectUrl;
      }
    } else {
      debug('[AUTH]: You\'re already where you should, avoid redirection.');
    }
  }, [history, props]);

  // This is here to overcome every possible SPA Router redirect
  const urlWithCode = getUrlWithCode();

  useEffect(() => {
    if (!isSessionStarted) {
      setSessionStarted(true);
      createSession({
          ...props,
          onBeforeRedirect,
          onRedirectCompleted,
        },
        { state, setState },
        urlWithCode,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionStarted]);

  return (
    <Auth0Context.Provider value={state}>
      { children }
    </Auth0Context.Provider>
  )
};