import gql from 'graphql-tag';

export const GET_QUESTIONNAIRE_MODELS = gql`
  query getQuestionnaireModels($first: Int, $cursor: String, $search: String) {
    questionnaireModels(first: $first, after: $cursor, search: $search) {
      edges {
        node {
          id
          title
          items {
            id
            type
            required
            other
            question
            options {
              label
            }
          }
          description
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_QUESTIONNAIRE_MODEL = gql`
  query getQuestionnaireModel($id: ObjectId!) {
    questionnaireModel(id: $id) {
      id
      title
      items {
        id
        type
        required
        other
        question
        options {
          label
        }
      }
      description
      createdAt
      updatedAt
    }
  }
`;