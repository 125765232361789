import './ParticipantList.css';

import { List, Typography } from 'antd';
import React from 'react';

import { Participant } from '../../../../../api/room';
import UserSelect, { UserSelectProps } from '../../../../../shared/UserSelect/UserSelect';

const { Title } = Typography;

type UserListProps = {
  title: string;
  participants?: Participant[];
  isEditMode: boolean;
  render: (participant: Participant) => JSX.Element;
  slim?: boolean;
  buttonLabel?: UserSelectProps['buttonLabel'];
  changeButtonLabel?: UserSelectProps['changeButtonLabel'];
  selected: UserSelectProps['selected'];
  onSelect: UserSelectProps['onSelect'];
  disabled: UserSelectProps['disabled'];
}

const ParticipantList: React.FC<UserListProps> = (props) => {
  const {
    title,
    participants,
    render,
    slim,
    isEditMode,
    buttonLabel,
    changeButtonLabel,
    selected,
    onSelect,
    disabled,
  } = props;

  const renderListTitle = () => (
    <>
      <Title className="videconference-user-list-title" level={4}>{title}</Title>
      { isEditMode &&
        <UserSelect
          title={title}
          buttonLabel={buttonLabel}
          changeButtonLabel={changeButtonLabel}
          selected={selected}
          onSelect={onSelect}
          disabled={disabled}
          multi
          block
          clearable={false}
        />
      }
    </>
  );

  return (
    <List
      size="small"
      className={`videconference-user-list${slim ? ' slim' : ''}`}
      header={renderListTitle()}
      dataSource={participants?.sort((a, b) => (a.metadata && b.metadata && a.metadata.online && !b.metadata.online) ? 1 : 0)}
      renderItem={(x, i) => render(x)}
    />
  )
};

export default ParticipantList;