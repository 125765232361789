import * as React from 'react';

type ModalState = {
  isVisible: boolean
}

const initialState: ModalState | null = {
  isVisible: false
}

const ACTIONS = {
  OPEN_MODAL: 'openModal',
  CLOSE_MODAL: 'closeModal',
}

export const modalReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_MODAL:
      return { isVisible: true };
    case ACTIONS.CLOSE_MODAL:
      return { isVisible: false };
    default:
      throw new Error();
  }
}

export const useModal = () => {
  const [state, dispatch] = React.useReducer(modalReducer, initialState);

  const openModal = () => {
    dispatch({ type: ACTIONS.OPEN_MODAL});
  }

  const closeModal = () => {
    dispatch({ type: ACTIONS.CLOSE_MODAL});
  }

  return {
    modalState: state,
    openModal,
    closeModal
  }
}
