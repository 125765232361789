import { Alert, AutoComplete, Button, Form, Input, Result, Select } from 'antd';
import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/react-hooks';

import { CREATE_USER, UPDATE_USER, User } from '../../../../api/user';
import { useAuth } from '../../../../auth0';
import { Actions, Resources, Roles, hasPermission } from '../../../../rbac';
import Multiline from '../../../../shared/Multiline';
import ResultError from '../../../../shared/PopoverResult/ResultError';
import ResultSuccess from '../../../../shared/PopoverResult/ResultSuccess';
import { useUser } from '../../../../shared/useUser';

const { Option } = Select;

enum UserEditPages {
  FORM = 'form',
  LOGOUT_WARNING = 'logout',
  SUCCESS = 'success',
  ERROR = 'error',
}

type UserFormProps = {
  initialValues?: Omit<User, 'createdAt' | 'updatedAt'>;
  disableSelfEditWarning?: boolean;
  done: () => void;
  close: () => void;
}

const autoTrim = (e) => e.target.value && e.target.value.toString().trim();

const UserForm: React.FC<UserFormProps> = (props) => {
  const { done, close, initialValues, disableSelfEditWarning } = props;

  const { user, logout, login } = useAuth();
  const { refetchUser } = useUser();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [createUser, userCreateOp] = useMutation(CREATE_USER);
  const [updateUser, userUpdateOp] = useMutation(UPDATE_USER);
  const [page, setPage] = React.useState<UserEditPages>(UserEditPages.FORM);

  const [formData, setFormData] = React.useState<any>();

  const selfEdit = !!initialValues && initialValues.id === user.userId;

  const storeUser = React.useCallback((formData) => {
    if (!initialValues) {
      createUser({
        variables: {
          user: {
            ...formData,
            roles: [formData.roles],
          }
        }
      }).then(() => {
        setPage(UserEditPages.SUCCESS);
      }).catch(() => {
        setPage(UserEditPages.ERROR);
      });
    } else {
      const update: Partial<User> = {};
      for (const prop in initialValues) {
        if (initialValues[prop] !== formData[prop]) {
          update[prop] = formData[prop];
        }
      }

      if (initialValues.roles && (initialValues.roles.length !== 1 || initialValues.roles[0] !== formData.roles)) {
        update.roles = [formData.roles];
      }

      update.id = initialValues.id;

      updateUser({
        variables: {
          user: update,
        }
      }).then(() => {
        setPage(UserEditPages.SUCCESS);

        if (selfEdit) {
          if (initialValues.email !== formData.email) {
            logout();
            if (login) {
              login();
            }
          }

          if (refetchUser) {
            refetchUser();
          }
        }
      }).catch(() => {
        setPage(UserEditPages.ERROR);
      });
    }
  }, [initialValues, login, logout, selfEdit, createUser, updateUser, setPage, refetchUser]);

  const onSubmit = React.useCallback((formData) => {
    if (initialValues && formData.email !== initialValues.email) {
      setFormData(formData);
      setPage(UserEditPages.LOGOUT_WARNING);
      return;
    }

    storeUser(formData);
  }, [initialValues, storeUser]);

  const handleContinue = React.useCallback(() => {
    storeUser(formData);
  }, [formData, storeUser]);

  const handleAknowledge = useCallback((hasToClose: boolean) => {
    form.resetFields();

    // Ask container to update data
    done();

    if (hasToClose) {
      // Tell container to close
      close();
    }

    // Go back to the first screen
    setPage(UserEditPages.FORM);
  }, [done, close, form, setPage]);

  const handleCancel = useCallback(() => {
    form.resetFields();

    // Tell container to close
    close();
  }, [form, close]);

  const timezones = moment.tz.names();

  const renderSuccessPage = () => {
    const action = initialValues ? 'updated' : 'created';

    return (
      <ResultSuccess
        title={t(`user.${action}.title`, { firstName: form.getFieldValue('firstName'), lastName: form.getFieldValue('lastName') })}
        tkey={`user.${action}.message`}
        initialized={!!initialValues}
        onOk={handleAknowledge}
      />
    );
  };

  const renderErrorPage = () => {
    return (
      <ResultError
        opError={userCreateOp.error || userUpdateOp.error}
        errorkey={'user.gqlErrors'}
        okText={t('common.aknowledge')}
        onOk={() => setPage(UserEditPages.FORM)}
      />
    );
  };

  const renderLogoutWarningPage = () => (
    <Result
      key='warning'
      status="warning"
      title={t(`user.willRequireLogin.${selfEdit ? 'self' : 'generic'}.title`)}
      subTitle={<Multiline tkey={`user.willRequireLogin.${selfEdit ? 'self' : 'generic'}.message`} />}
      extra={[
        <Button
          type='default'
          key="user-goback"
          disabled={userCreateOp.loading || userUpdateOp.loading}
          onClick={() => setPage(UserEditPages.FORM)}
        >{t('common.cancel')}</Button>,
        <Button
          type="primary"
          danger
          key="user-proceed"
          loading={userCreateOp.loading || userUpdateOp.loading}
          onClick={handleContinue}
        >{t('common.continue')}</Button>
      ]}
    />
  );

  const renderForm = () => (
    <>
      {selfEdit && !disableSelfEditWarning && <Alert type="info" message={t('user.selfEditWarning')} style={{ marginBottom: '1em' }} closable />}
      <Form
        form={form}
        initialValues={{
          ...initialValues,
          roles: (initialValues?.roles && initialValues?.roles[0]) ? initialValues?.roles[0] : undefined,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          name="firstName"
          getValueFromEvent={autoTrim}
          rules={[{ required: true }]}
          style={{ display: 'inline-block', maxWidth: "100%", width: 'calc(50% - 8px)' }}
        >
          <Input placeholder={t('user.props.firstName')} />
        </Form.Item>
        <Form.Item
          name='lastName'
          getValueFromEvent={autoTrim}
          rules={[{ required: true }]}
          style={{ display: 'inline-block', maxWidth: "100%", width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
        >
          <Input placeholder={t('user.props.lastName')} />
        </Form.Item>
        <Form.Item
          name='email'
          getValueFromEvent={autoTrim}
          rules={[{ required: true }, { type: 'email', message: t('user.errors.invalidEmail') }]}
        >
          <Input placeholder='user@email.com' />
        </Form.Item>
        <Form.Item
          name='roles'
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('user.props.roles')}
            disabled={initialValues ? !hasPermission(user, initialValues.roles[0] as unknown as Resources, Actions.CREATE) : false}
          >
            {Object.keys(Roles).filter(x => user && hasPermission(user, Roles[x], Actions.CREATE)).map(x => (
              <Option key={Roles[x]} value={Roles[x]}>{t(`user.roles.${Roles[x]}`)}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='locale'
          rules={[{ required: true }]}
        >
          <AutoComplete
            placeholder={t('user.props.locale')}
            filterOption={true}
            options={['en'].map(x => ({ value: x }))}
          />
        </Form.Item>
        <Form.Item
          name='timezone'
          rules={[{ required: true }]}
        >
          <AutoComplete
            placeholder={t('user.props.timezone')}
            filterOption={true}
            options={timezones.map(x => ({ value: x }))}
          />
        </Form.Item>
        <div className="actions-container">
          <Button disabled={userCreateOp.loading || userUpdateOp.loading} onClick={handleCancel}>
            {t('common.cancel')}
          </Button>
          <Button type='primary' htmlType='submit' loading={userCreateOp.loading || userUpdateOp.loading}>
            {!initialValues ? t('user.create') : t('user.update')}
          </Button>
        </div>
      </Form>
    </>
  );

  const renderers: { [x in UserEditPages]: () => JSX.Element } = {
    [UserEditPages.FORM]: renderForm,
    [UserEditPages.LOGOUT_WARNING]: renderLogoutWarningPage,
    [UserEditPages.SUCCESS]: renderSuccessPage,
    [UserEditPages.ERROR]: renderErrorPage,
  };

  return renderers[page]();
}

export default UserForm;
