import { VideoCameraOutlined } from '@ant-design/icons'
import { Actions, Resources } from '../../../rbac';

import { MenuConfig, Route } from '../../routes.types';
import Rooms from './RoomList';

export const roomRoutePaths = {
  root: '/rooms',
};

export const routes: Route[] = [
  {
    path: roomRoutePaths.root,
    component: Rooms,
    permissions: { [Resources.ROOM]: [Actions.READ] },
  }
];

export const menus: MenuConfig = [
  {
    route: routes[0],
    label: 'room.menuTitle',
    icon: VideoCameraOutlined,
    order: 3,
  }
];
