import { RawDraftContentState } from 'draft-js';
import { Paginated, PostAuthorTypes } from '../types';

export enum UserRoles {
  systemAdmin = 'systemAdmin',
  partners = 'partners',
  donors = 'donors',
  localAuthorities = 'localAuthorities',
  user = 'user'
}

export type Comment = {
  id: String;
  content: String;
  createdAt: String;
  updatedAt: String;
}


export type FileType = {
  id?: string;
  name: string;
  size: number;
  type: string;
  url: string;
}

export type Post = {
  id?: string;
  title: string,
  description: string,
  audienceRoles: UserRoles[],
  tags: string[],
  featuredImage?: {
    file: FileType
  },
  files: {
    file: FileType
  }[],
  content: RawDraftContentState | string;
  comments?: Comment[];
  publishedAs?: string | {
    teamId: string
  };
  author: {
    id: string;
    type: PostAuthorTypes;
    name: string;
  }
  published?: boolean;
  createdAt: string;
  updatedAt: string;
  metadata?: {
    headerPosition: number;
  };
}

export type PaginatedPosts = {
  posts: Paginated<Post>;
};

export type PaginatedUserPosts = {
  userPosts: Paginated<Post>;
};

export type RemovePost = {
  id: string;
  fileId: string;
}

export const POST_PROPS = {
  TITLE_MAX_COUNT: 140,
  DESCRIPTION_MAX_COUNT: 200,
}
