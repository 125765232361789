import React from "react";

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);

  React.useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("orientationchange", handleOrientationChange, false);
      window.addEventListener('resize', handleOnResize);
    }

    handleOrientationChange();

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange, false);
      window.removeEventListener('resize', handleOnResize);
    };
  },[]);

  const handleOnResize = (e: any) => {
    if(e.currentTarget.innerWidth < 413) {
      return setMobile(true);
    }

    setMobile(false);
  };

  const handleOrientationChange = () => {
    const userAgent = typeof window.navigator === "undefined" 
      ? ""
      : navigator.userAgent;

    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );

    setMobile(mobile);
  };

  return { isMobile };
}