
import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../shared/Components/Loader';

const AdminContainer = Loadable({
  loader: () => import('./Admin'),
  loading: () => <Loader isLoading={true} />,
});

export default AdminContainer;
