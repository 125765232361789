import * as React from 'react';
import Loadable from 'react-loadable';

import Loader from '../../../../shared/Components/Loader';

const UserListContainer = Loadable({
  loader: () => import('./GroupList'),
  loading: () => <Loader isLoading={true} />,
});

export default UserListContainer;
