import 'react-placeholder/lib/reactPlaceholder.css';
import 'antd/dist/antd.less';
import 'font-awesome/css/font-awesome.min.css';
import 'moment/min/locales.min';

import './index.css';
import './locales';

import React, { useEffect } from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import graphqlClient, { setToken } from './api/graphqlClient';
import { Auth0Provider } from './auth0';
import { Auth0User } from './auth0/types';
import { clientEnv } from './config';
import { FirebaseProvider } from './firebase';
import * as serviceWorker from './serviceWorker';
import AppLayout from './shared/AppLayout';
import { CookieManager } from './shared/CookieManager/CookieManager';
import { ErrorBoundary, initSentry } from './shared/ErrorBoundary';
import Navigation from './shared/Navigation';
import store, { history } from './store';
import ScriptLoader from 'react-script-loader-hoc';
declare global {
  interface Window { dataLayer: any[]; }
}

initSentry(history);

const initGA = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag(js, d) {
    window.dataLayer.push(arguments);
  };
  gtag('js', new Date());

  gtag('config', `${clientEnv.GOOGLE_MEASUREMENT_ID_CODE}`);
  
  console.log("Google Analytics configured.");
};

const auth0config = {
  client_id: clientEnv.AUTH0_CLIENT_ID,
  domain: clientEnv.AUTH0_DOMAIN,
  redirect_uri: clientEnv.AUTH0_REDIRECT_URI,
  audience: clientEnv.AUTH0_AUDIENCE,
};

const firebaseConfig = {
  appId: clientEnv.FIREBASE_APP_ID,
  projectId: clientEnv.FIREBASE_PROJECT_ID,
  apiKey: clientEnv.FIREBASE_API_KEY,
  storageBucket: clientEnv.FIREBASE_STORAGE_BUCKET,
};

const makeUser = (user: Auth0User) => ({
  ...user[`${clientEnv.AUTH0_NAMESPACE}user_metadata`],
  ...user[`${clientEnv.AUTH0_NAMESPACE}app_metadata`],
});

const onSuccessfulLogin = (authProps) => {
  setToken(authProps.accessToken);
};

const ApplicatioRoot = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <Router history={history}>
        <FirebaseProvider firebaseConfig={firebaseConfig}>
          <Auth0Provider
            clientOptions={auth0config}
            tranformUser={makeUser}
            onSuccessfulLogin={onSuccessfulLogin}
          >
            <ApolloProvider client={graphqlClient}>
              <AppLayout>
                <Navigation />
              </AppLayout>
              <CookieManager onPolicyAcceptance={() => initGA()} />
            </ApolloProvider>
          </Auth0Provider>
        </FirebaseProvider>
      </Router>
    </Provider>
  </ErrorBoundary>
);

const GWrappedApp = ScriptLoader(`https://www.googletagmanager.com/gtag/js?id=${clientEnv.GOOGLE_MEASUREMENT_ID_CODE}`)(ApplicatioRoot);

ReactDOM.render(<GWrappedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
