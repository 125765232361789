import './TopNavigationMenu.css';

import { Avatar, Button, Col, Drawer, Row } from 'antd';
import * as React from 'react';

import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';

import { useAuth } from '../../../auth0';
import { clientEnv } from '../../../config';
import useDeviceDetect from '../../useDeviceDetect';
import { useUser } from '../../useUser';
import MainMenu from './MainMenu';
import UserMenu from './UserMenu';

import Logo from '../../assets/images/ekep-inline-color-logo.svg';

const TopNavigationMenu = () => {
  const { isMobile } = useDeviceDetect();
  const [navVisible, showMobileNav] = React.useState<boolean>(false);
  const { isAuthenticated, login, logout, isAuthenticating, user: authUser } = useAuth();
  const { user, isLoading } = useUser();

  const userName = user ? `${user.firstName} ${user.lastName}` : 'User';

  const handleLogout = React.useCallback(() => {
    logout(clientEnv.AUTH0_REDIRECT_URI);
  }, [logout]);

  return (
    <nav id="main-header" className={isMobile ? 'mobile-header' : 'header'}>
      <Row justify='start' align='middle' gutter={[25, 0]} className="header-row">
        <Col flex={2} style={!isMobile ? { maxWidth: 290 } : {}}>
          <div className='logo'>
            <a href='/'>
              <img
                src={Logo}
                className="logo"
                alt='eKEP - Rebuild Knowledge Exchange Platform' />
            </a>
          </div>
        </Col>
        {isMobile ?
          <Col flex={1} className='burger-button'>
            <Button type="primary" onClick={() => showMobileNav(!navVisible)} style={{ marginBottom: 16 }}>
              {React.createElement(navVisible ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </Button>
          </Col>
          : <>
            <Col flex={5}>
              <MainMenu
                authUser={authUser}
                isMobile={isMobile}
              />
            </Col>
            <Col>
              <UserMenu
                userName={userName}
                authUser={authUser}
                isLoading={isLoading}
                isAuthenticated={isAuthenticated}
                isAuthenticating={isAuthenticating}
                login={login}
                logout={handleLogout}
                isMobile={isMobile}
              />
            </Col>
            {/* <Col>
              <LanguageSelector />
            </Col> */}
          </>
        }
      </Row>
      <Drawer
        title={<><Avatar icon={<UserOutlined />} className="header-user-icon" />{userName}</>}
        placement="right"
        closable={true}
        onClose={() => showMobileNav(!navVisible)}
        visible={navVisible}
      // footer={<DrawerFooter />}
      >
        <MainMenu
          authUser={authUser}
          isMobile={isMobile}
        />
        <UserMenu
          userName={userName}
          authUser={authUser}
          isLoading={isLoading}
          isAuthenticated={isAuthenticated}
          isAuthenticating={isAuthenticating}
          login={login}
          logout={handleLogout}
          isMobile={isMobile}
        />
      </Drawer>
    </nav>
  )
}

export default TopNavigationMenu;
