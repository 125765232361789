import { applyMiddleware, compose, createStore, Middleware, Reducer, Store } from "redux";
import { routerMiddleware } from './config.history';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';

export interface StoreConfiguration {
  additionalMiddlewares: Middleware[];
  compose: typeof compose,
  callback?: (store: Store) => void;
}

const initStore = (config: StoreConfiguration, rootReducer: Reducer, initialState: any): Store => {

  const sagaMiddleware = createSagaMiddleware();
  
  // Prepare middlewares list
  const middlewares: Middleware[] = [
    ...config.additionalMiddlewares,
    sagaMiddleware,
    routerMiddleware,
  ];

  const store = createStore(
    rootReducer,
    initialState,
    config.compose(
      applyMiddleware(...middlewares),
    ),
  );

  if (config.callback) {
    config.callback(store);
  }

  sagaMiddleware.run(rootSaga);

  return store;
};

export default initStore;