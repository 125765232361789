import { LineChartOutlined } from '@ant-design/icons';
import { Actions, Resources } from '../../../rbac';

import { MenuConfig, Route } from '../../routes.types';
import TestSessionDetails from './TestSessionDetails';
import TestSessionList from './TestSessionList';

export const questionnaireRoutePaths = {
  root: '/questionnaires/sessions',
  sessionResults: '/questionnaires/sessions/:id',
};

export const routes: Route[] = [
  {
    path: questionnaireRoutePaths.sessionResults,
    component: TestSessionDetails,
    permissions: { [Resources.TEST_SESSION]: [Actions.READ] },
  },
  {
    path: questionnaireRoutePaths.root,
    component: TestSessionList,
    exact: true
  },
];

export const menus: MenuConfig = [
  {
    route: routes[routes.length - 1],
    label: 'testsession.menuTitle',
    icon: LineChartOutlined,
    order: 5,
  },
];
