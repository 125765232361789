import { ProfileOutlined } from '@ant-design/icons';
import { Actions, Resources } from '../../../rbac';

import { MenuConfig, Route } from '../../routes.types';
import QuestionnaireModels from './QuestionnaireModelsList';

export const questionnaireRoutePaths = {
  root: '/questionnaireModels',
};

export const routes: Route[] = [
  {
    path: questionnaireRoutePaths.root,
    component: QuestionnaireModels,
    permissions: { [Resources.QUESTIONNAIRE_MODEL]: [Actions.CREATE] },
    exact: true,
  },
];

export const menus: MenuConfig = [
  {
    route: routes[routes.length - 1],
    label: 'questionnaireModel.menuTitle',
    icon: ProfileOutlined,
    order: 4,
  },
];
