import React, { useEffect, useState, useCallback } from 'react';

import './CookieManager.css';

type CookieManagerProps = {
	onPolicyAcceptance: () => void;
};

const cookiePolicyKey = 'cookie-policy';
let initialScroll = window.scrollY;
let isIgnoringScroll = false;
let isPolicyAccepted = false;

export const CookieManager: React.FC<CookieManagerProps> = ({onPolicyAcceptance}) => {
	const [isOpened, setOpened] = useState(false);
	const [isPolicyOpen, setPolicyOpen] = useState(false);

	const togglePolicy = useCallback(() => {
		isIgnoringScroll = !isPolicyOpen;

		setPolicyOpen(!isPolicyOpen);
	}, [isPolicyOpen, setPolicyOpen]);

	const acceptAndAknoweldge = useCallback(() => {
		if (isPolicyAccepted) {
			return;
		}

		isPolicyAccepted = true;
		
		window.localStorage.setItem(cookiePolicyKey, (new Date()).toUTCString());
		
		onPolicyAcceptance();

		setOpened(false);
	}, [setOpened, onPolicyAcceptance]);

	useEffect(() => {
		if (!window.localStorage.getItem(cookiePolicyKey)) {
			setOpened(true);
		} else {
			onPolicyAcceptance();
		}

		window.addEventListener('scroll', (e) => {
			if (isIgnoringScroll) {
				return;
			}

			if (initialScroll === 0) {
				initialScroll = window.scrollY;
			} else if (Math.abs(initialScroll - window.scrollY) > 250) {
				acceptAndAknoweldge();
			}
		});
	}, [isPolicyOpen, acceptAndAknoweldge, onPolicyAcceptance]);

	if(!isOpened)
		return null;

	return (
		<div className="cookie-manager" onClick={() => acceptAndAknoweldge()}>
      <div className={`cookie-agreement-modal ${isPolicyOpen ? 'policy' : ''}`} onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
				<div className="cookie-scroller">
					<div className="cookie-agreement">
						<h2>Notice</h2>
						<p>This website or its third-party tools process personal data (e.g. browsing data or IP addresses) and use cookies or other identifiers, which are necessary for its functioning and required to achieve the purposes illustrated in the cookie policy.</p>
						<p>You accept the use of cookies or other identifiers by closing or dismissing this notice, by clicking a link or button or by continuing to browse otherwise.</p>
					</div>

					{ isPolicyOpen &&
					<div className="cookie-policy-container">
						<h2>Cookie Policy of tezos.boutique</h2>
						<p>Cookies and other Identifiers consist of portions of code placed on the User’s device that assist the Owner in providing the Service according to the purposes described.</p>
						<p>Some of the purposes for which Identifiers are used may also require the User's consent. Whenever consent is given, it can be freely withdrawn at any time following the instructions provided in this document.</p>

						<h3>Analytics</h3>
						<p>This Application uses Cookies or other Identifiers to measure traffic and analyze User behavior with the goal of improving the Service.</p>
						<p>The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.</p>

						<h4>User ID extension for Google Analytics</h4>
						<p>Google Analytics on this Application makes use of a feature called User ID. This setup allows for a more accurate tracking of Users by associating that User with the same ID over various sessions and devices. It is set up in a way that doesn’t allow Google to personally identify an individual or permanently identify a particular device.</p>
						<p>The User ID extension might make it possible to connect Data from Google Analytics with other Data about the User collected by this Application.</p>
						<p>The opt-out link below will only opt you out for the device you are on, but not from tracking performed independently by the Owner. Contact the Owner via the email address provided in this privacy policy to object to the aforementioned tracking as well.</p>

						<p>Personal Data collected: Cookies.</p>
						<p>Place of processing:
							<div className="pop">United States &ndash; <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Opt Out</a>;</div>
							<div className="pop">Ireland &ndash; <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Opt Out</a>.</div>
						</p>
						<p>Privacy Shield participant.</p>

						<h4>Google Analytics Demographics and Interests reports</h4>
						<p>Google Analytics Demographics and Interests reports is a Google Advertising Reporting feature that makes available demographic and interests Data inside Google Analytics for this Application (demographics means age and gender Data).</p>
						<p>Users can opt out of Google's use of cookies by visiting Google's <a href="https://adssettings.google.com/authenticated">Ads Settings</a>.</p>

						<p>Personal Data collected: Cookies and unique device identifiers for advertising (Google Advertiser ID or IDFA, for example).</p>
						<p>Place of processing:
							<div className="pop">United States &ndash; <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Opt Out</a>;</div>
							<div className="pop">Ireland &ndash; <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Opt Out</a>.</div>
						</p>
						<p>Privacy Shield participant.</p>

						<h3>Anonymized analytics services</h3>
						<p>The services contained in this section are third-party services that enable the Owner, through the use of Cookies and other Identifiers, to manage third-party analytics in an anonymized form. Depending on the applicable law these services may not require Users to consent. In such cases Users can disable the Identifiers by modifying their browser or device settings as described in more detail within this document.</p>

			      <h4>Google Analytics with anonymized IP</h4>
						<p>Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.</p>
						<p>Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>
						<p>This integration of Google Analytics anonymizes your IP address. It works by shortening Users' IP addresses within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the complete IP address be sent to a Google server and shortened within the US.</p>

						<p>Personal Data collected: Cookies and Usage Data.</p>
						<p>Place of processing:
							<div className="pop">United States &ndash; <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Opt Out</a>;</div>
							<div className="pop">Ireland &ndash; <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Opt Out</a>.</div>
						</p>
						<p>Privacy Shield participant.</p>

						<h3>How to provide or withdraw consent</h3>
						<p>Users can provide or withdraw consent to the use of Cookies and other Identifiers by setting their preferences within the cookie notice or by updating such preferences via the relevant consent-preferences widget accordingly, if available.</p>
						<p>Additionally, Users can manage preferences regarding Identifiers directly from within their own device settings and prevent – for example – the storing of third-party Identifiers. It is also possible, via relevant browser or device features, to delete previously stored Identifiers, including those used to remember the User’s initial consent. 
						Users can, for example, find information about how to manage Identifiers in the most commonly used browsers at the following addresses: <a rel="noopener noreferrer nofollow" target="_blank" href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies">Google Chrome</a>, <a rel="noopener noreferrer nofollow" target="_blank" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a>, <a rel="noopener noreferrer nofollow" target="_blank" href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/">Apple Safari</a> and <a rel="noopener noreferrer nofollow" target="_blank" href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies">Microsoft Internet Explorer</a>.</p>
						<p>With regard to any Identifiers stored by third parties, Users can manage their preferences and withdraw their consent by clicking the related opt-out link (where provided), by using the means indicated in the third party's privacy policy, or by contacting the third party.</p>
						<p>Notwithstanding the above, Users are hereby informed that they may follow the instructions provided by <a target="_blank" rel="noopener noreferrer nofollow" href="http://www.youronlinechoices.eu/">YourOnlineChoices</a> (EU), the <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.networkadvertising.org/understanding-digital-advertising">Network Advertising Initiative</a> (US) and the <a target="_blank" rel="noopener noreferrer nofollow"  href="https://www.aboutads.info/consumers/">Digital Advertising Alliance</a> (US), <a target="_blank" rel="noopener noreferrer nofollow" href="https://youradchoices.ca/understanding-online-advertising/">DAAC</a> (Canada), <a target="_blank" rel="noopener noreferrer nofollow" href="http://www.ddai.info/optout">DDAI</a> (Japan) or other similar services. Such initiatives allow Users to select their tracking preferences for most of the advertising tools. The Owner thus recommends that Users make use of these resources in addition to the information provided in this document.</p>
						<p>Since the storing of third-party Cookies and other Identifiers through the services used within this Application cannot be technically controlled by the Owner, any specific references to Identifiers stored by third parties are to be considered indicative. In order to obtain complete information, the User is kindly requested to consult the privacy policies of the respective third-party services listed in this document.</p>

						<h3>Definitions and legal references</h3>
						<strong>Personal Data (or Data)</strong>
						<p>Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</p>

						<strong>Usage Data</strong>
						<p>Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</p>

						<strong>User</strong>
						<p>The individual using this Application who, unless otherwise specified, coincides with the Data Subject.</p>

						<strong>Data Subject</strong>
						<p>The natural person to whom the Personal Data refers.</p>

						<strong>Data Processor (or Data Supervisor)</strong>
						<p>The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.</p>

						<strong>Data Controller (or Owner)</strong>
						<p>The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.</p>

						<strong>This Application</strong>
						<p>The means by which the Personal Data of the User is collected and processed.</p>

						<strong>Service</strong>
						<p>The service provided by this Application as described in the relative terms (if available) and on this site/application.</p>

						<strong>European Union (or EU)</strong>
						<p>Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.</p>

						<strong>Cookie</strong>
						<p>Cookies are Identifiers consisting of small sets of data stored in the User&#x27;s browser.</p>

						<strong>Identifier</strong>
						<p>Any technology - including Cookies - that allows for the storing of information, or the accessing of already-stored information, on the User’s device.</p>

						<strong>Email</strong>
						<p>Provides access to the User's primary email address.</p>

						<h3>Legal information</h3>
						<p>This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).</p>
						<p>This privacy policy relates solely to this Application, if not stated otherwise within this document.</p>

						<p>Latest update: February 12, 2020</p>
					</div>
					}
				</div>

				<div className="cookie-management-actions">
					<button onClick={() => togglePolicy()} className="secondary">{ !isPolicyOpen ? 'Cookie policy' : 'Close cookie policy' }</button>
					<button onClick={() => acceptAndAknoweldge()}>Accept and aknoweldge</button>
				</div>
      </div>
		</div>
	);
};
