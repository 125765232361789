import './PostList.less';

import { Divider, Input } from 'antd';
import * as React from 'react';
import { useCallback } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { PaginationVariables } from '../../../api';
import { GET_POSTS, PaginatedPosts } from '../../../api/post';
import { useAuth } from '../../../auth0';
import GuidedTour from '../../../shared/GuidedTour';
import useDeviceDetect from '../../../shared/useDeviceDetect';
import PostBanner from './PostBanner';
import PostCard from './PostCard';
import PostHeader from './PostHeader';
import PostSidebar from './PostSidebar';

const { Search } = Input;

const filters = {
  header: false,
  published: true
};

const initialItemsCount = 20;

const PostList: React.FC = ({ ...props }) => {
  const { user } = useAuth();
  const [fetchPosts, postQuery] = useLazyQuery<PaginatedPosts, PaginationVariables>(GET_POSTS, { fetchPolicy: 'cache-and-network', errorPolicy: 'all' });
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchPosts({
      variables: {
        filters,
      }
    });
  }, [user]);

  const handleLoadMore = React.useCallback(() => {
    if (!postQuery.called || !postQuery.data) {
      return;
    }

    const pageInfo = postQuery.data['posts'].pageInfo;

    if (pageInfo?.hasNextPage) {
      postQuery.fetchMore({
        variables: {
          cursor: pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return;
          }

          const newEdges = fetchMoreResult.posts.edges;
          const pageInfo = fetchMoreResult.posts.pageInfo;

          return (newEdges && newEdges.length)
            ? {
              posts: {
                __typename: previousResult.posts.__typename,
                edges: [...previousResult.posts.edges, ...newEdges],
                pageInfo
              }
            }
            : previousResult;
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postQuery]);

  const handleSearch = useCallback((term: string, tags?: string[]) => {
    if (!postQuery.called) {
      return;
    }

    fetchPosts({
      variables: {
        first: initialItemsCount || 20,
        filters: {
          ...filters,
          tags
        },
        search: term
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postQuery, initialItemsCount]);

  const renderSkeleton = () => {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
      <PostCard key={i} imagePosition='after' className="post-list-item" />
    ));
  };

  const handleRefetch = useCallback(() => {
    postQuery.refetch();
  }, [postQuery.refetch]);

  return (
    <>
      <PostBanner handleSearch={handleSearch} loading={postQuery.loading} />
      <PostHeader onUpdate={handleRefetch} />
      <Divider className="post-divider" />
      <div className="post-list-container">
        <div className='post-content'>
          <div className='post-list-content'>
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={postQuery.data ? postQuery.data.posts.pageInfo.hasNextPage : false}
              useWindow={false}
            >
              {postQuery.data && postQuery.data.posts.edges.map((post, i) => (
                <PostCard
                  id={i}
                  key={`post-${post.node.id}`}
                  post={post.node}
                  bordered
                  direction={isMobile ? 'vertical' : 'horizontal'}
                  imagePosition='after'
                  className="post-list-item" />
              ))}
              {postQuery.loading && renderSkeleton()}
            </InfiniteScroll>
          </div>
          <div className="post-list-sidebar">
            <PostSidebar />
          </div>
        </div>
      </div>
      { user &&
      <GuidedTour
        id='platform-overview'
        title={t('posts.tour.title')}
        message={t('posts.tour.message')}
        steps={[
          {
            selector: '.main-menu',
            content: 'This is the main menu. From here you can access the various sections of the platform, and also edit your profile',
            position: 'bottom'
          },
          {
            selector: '.post-list-container',
            content: 'Articles and Shared documents live here. You can browse them of perform a search if you already know what you\'re looking for.',
            position: 'top'
          },
          {
            selector: '.post-list-sidebar',
            content: 'Post/Articles are grouped togther with a tag(s). Click on any of the tags to see all the posts on the same topic.',
            position: 'top'
          },
        ]}
      />
      }
    </>
  );
};

export default PostList;
