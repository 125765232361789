import React from 'react';

import { LoginOutlined, MehTwoTone, UnlockTwoTone, } from '@ant-design/icons';
import { Button, Empty, Layout } from 'antd';
import { useUser } from '../../../../shared/useUser';

import './NoPosts.css';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../../auth0';

export type RoomError = {
  errorTitle: string;
  errorDescription: string;
  onBackCallback?: () => void;
}


export const NoPosts: React.FC = () => {
  const { user, isLoading } = useUser();
  const { login, isAuthenticating } = useAuth();

  if (isLoading || isAuthenticating) {
    return null;
  }

  return (
    <Layout className="no-posts-container">
      <Empty
        image={user ? <MehTwoTone /> : <UnlockTwoTone />}
        className="no-posts"
        description={user ? "No posts with this tag." : "You need to be logged in to see this tag's posts."}
      />
      <p>{ user ? "There are no Posts with this tag" : "Please login to view this page." }</p>
      <Button
        type='primary'
        className="no-posts-action" 
        onClick={login}
        icon={<LoginOutlined />}
      >
        Login
      </Button>
    </Layout>
  );
};

export default NoPosts;