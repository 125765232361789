import backoffice from './backoffice';
import common from './common.json';

const translation = {
  ...backoffice,
  common
};

// Leave exports as they are here
const _translation = {
  translation,
};

export default _translation;
