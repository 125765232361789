import { useContext } from 'react';

import { FirebaseContext } from '../components/FirebaseContext';

export const useFirebase = () => {
  const ctx = useContext(FirebaseContext);

  return {
    storage: ctx.storage,
    storageRef: ctx.storageRef,
  };
};