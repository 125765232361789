import * as React from 'react';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';

type BreadCrumbProps = {
  exclude?: string | string[]
}
const BreadCrumb: React.FC<BreadCrumbProps> = ({ exclude }) => {
  const history = useHistory();

  const items = () => {
    const path = history.location.pathname;
    const items = path.split('/');
    const pathItems = items.filter((path, i) => {
      if (typeof exclude === 'object') {
        return path && !exclude.includes(path)
      }
      return path && (path !== exclude);
    });

    return pathItems.map((path, i) => (
      <Breadcrumb.Item
        key={i}>
        { Math.floor(i + 1) !== pathItems.length ?
          <a
            onClick={() => history.push(`/${path}`)}>
            {path}
          </a>
          : path
        }
      </Breadcrumb.Item>
    ));
  }

  return (
    <Breadcrumb separator=">">
      <Breadcrumb.Item>
        <a href={`/`}>Home</a>
      </Breadcrumb.Item>
      {items()}
    </Breadcrumb>
  );
}

export default BreadCrumb;
