import gql from 'graphql-tag';

export const CREATE_QUESTIONNAIRE = gql`
  mutation CreateQuestionnaire($input: QuestionnaireCreateInput!) {
    createQuestionnaire(input: $input) {
      id
      items {
        questionId
        answer
      }
      completed
      createdAt
      updatedAt
    }
  }
`;

export const ANSWER_QUESTIONNAIRE_QUESTION = gql`
  mutation AnswerQuestionnaireQuestion($input: QuestionnaireAnswerQuestionInput!) {
    answerQuestionnaireQuestion(input: $input) {
      id
      questionnaireModelId
      testSessionId
      answers {
        questionId
        answer
      }
      completed
      createdAt
      updatedAt
    }
  }
`;

export const COMPLETE_QUESTIONNAIRE = gql`
  mutation CompleteQuestionnaire($id: ObjectId!) {
    completeQuestionnaire(id: $id) {
      id
      completed
    }
  }
`;
