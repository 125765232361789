import React from 'react';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { clientEnv } from '../../config';

type State = {
  error: any;
  errorInfo: any;
}

export const initSentry = (history) => {
  if(process.env.NODE_ENV === 'production' ) {
    console.log('SENTRY initialized', process.env.NODE_ENV);
    Sentry.init({
      dsn: clientEnv.SENTRY_DNS,
      release: clientEnv.TENANT_ID,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      tracesSampleRate: 0.5,
    });
  }
}

// export class ErrorBoundary extends React.Component<any, State> {
//   constructor(props) {
//     super(props);
//     console.log('props', props);
//     this.state  = { error: null, errorInfo: null };
//   }
  
//   componentDidCatch(error, errorInfo) {
//     // Catch errors in any components below and re-render with error message
//     console.log('componentDidCatch', error, errorInfo)
//     Sentry.captureMessage(errorInfo);
//     Sentry.captureException(error);

//     this.setState({
//       error: error,
//       errorInfo: errorInfo
//     })
//     // You can also log error messages to an error reporting service here
//   }

//   render() {
//     console.log('this.state', this.state)
//     if (this.state.errorInfo) {
//       // Error path
//       return (
//         <div>
//           <h2>Something went wrong.</h2>
//           <details style={{ whiteSpace: 'pre-wrap' }}>
//             {this.state.error && this.state.error.toString()}
//             <br />
//             {this.state.errorInfo.componentStack}
//           </details>
//         </div>
//       );
//     }
//     // Normally, just render children
//     return this.props.children;
//   }  
// }

type ErrorBoundaryProps = {
  children: any; 
  error?: any;
  errorInfo?: any;
}
export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ ...props }) => {
  const { ...rest } = props;
  const [errormsg, setError] = React.useState<any>()
  const [errorInfoMsg, setErrorInfo] = React.useState<any>()

  React.useEffect(() => {
    if(props.error || props.errorInfo) {
        if(props.errorInfo) {
          Sentry.captureMessage(props.errorInfo);
          setErrorInfo(props.errorInfo)
        }
        Sentry.captureException(props.error);
        setError(props.error);
    }
  }, [props.error, props.errorInfo])
  
  if (errorInfoMsg) {
    // Error path
    return (
      <div>
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {errormsg && errormsg.toString()}
          <br />
          {errorInfoMsg.componentStack}
        </details>
      </div>
    );
  }

  return props.children;
}  