import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import * as React from 'react';
import './loader.css';

type LoaderProps = {
  children?: React.ReactNode;
  isLoading?: boolean;
  style?: any
  fontSize?: number
}

const Loader: React.FC<LoaderProps> = ({ children, isLoading, style, fontSize }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: fontSize ? fontSize : 24 }} spin />;

  return (
    <div className='loader-container' style={{ ...style }}>
      <Spin spinning={isLoading} indicator={antIcon} >
        {children}
      </Spin>
    </div>
  )
};

export default Loader