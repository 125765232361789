import { Actions, Resources } from '../../rbac';
import { MenuConfig, Route } from '../routes.types';
import Admin from './AdminContainer';
import * as group from './Group';
import * as adminPost from './AdminPost';
import * as questionnaire from './QuestionnaireModel';
import * as reports from './Reports';
import * as room from './Room';
import * as teams from './Teams';
import * as testSessions from './TestSessions';
import * as user from './User';
import * as usageReports from './UsageReports';

export const adminRoutePaths = {
  root: '/admin',
};

export const innerRoutes = [
  ...user.routes,
  ...group.routes,
  ...room.routes,
  ...questionnaire.routes,
  ...teams.routes,
  ...testSessions.routes,
  ...adminPost.routes,
  // ...reports.routes,
  ...usageReports.routes
];

export const innerMenus: MenuConfig = [
  ...user.menus,
  ...group.menus,
  ...room.menus,
  ...adminPost.menus,
  ...questionnaire.menus,
  ...teams.menus,
  ...testSessions.menus,
  // ...reports.menus,
  ...usageReports.menus
];

export const routes: Route[] = [
  {
    path: adminRoutePaths.root,
    component: Admin,
    permissions: { [Resources.BACKOFFICE]: [Actions.LAUNCH] }
  }
];

export const menus: MenuConfig = [
  {
    route: routes[0],
    label: 'admin.menuTitle',
    order: 3,
  }
];

export const defaultPage = user.routes[0].path;
